import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "./LoadingSpinner";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function BreadCrumb({ links, className }) {
  const { id } = useParams();
  const [content, setContent] = useState(<LoadingSpinner />);
  const { t } = useTranslation();

  useEffect(() => {
    let breadLinks = [];
    if (links) {
      for (const element of links) {
        let to = element.to;
        if (element.to instanceof Function) {
          to = element.to(id);
        }
        breadLinks.push(
          <Breadcrumb.Item
            key={to}
            active={element.active}
            linkAs={Link}
            linkProps={{ to: to }}
          >
            {t(element.text)}
          </Breadcrumb.Item>
        );
      }
    }
    setContent(breadLinks);
  }, [links, id, t]);

  return <Breadcrumb className={className}>{content}</Breadcrumb>;
}

export default BreadCrumb;
