export const FormInputs = [
  {
    type: "number",
    name: "payments",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
];
