import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { Image } from "components/Image";
import { useTranslation } from "react-i18next";
import { Col, Nav, Navbar, Row } from "react-bootstrap";

const Header = ({ handleClick, sections, activeSection, home, className }) => {
  const { t } = useTranslation();
  const handleHomeClick = () => {
    if (handleClick) {
      handleClick(home);
    }
  };
  return (
    <header
      className={`${styles.blueBackground} ${styles.borderBottom} ${className} shadow-sm py-0 my-0 fixed-top`}
    >
      <Navbar variant="dark" className="py-2 h-100" expand={"md"}>
        <Row className="m-md-0  p-md-0 ">
          <Col xs={9} className="text-start mt-4 mt-md-0">
            <Link
              className="ms-2 navbar-brand d-flex align-items-center"
              to=""
              onClick={handleHomeClick}
            >
              <span className="fw-bold h2">STORE</span>{" "}
              <Image
                src="images/logo.png"
                alt="Logo"
                style={{ width: "48px" }}
              />
              <span className="fw-bold d-md-block d-none h5">
                WebComprehensive
              </span>
              <span className="fw-bold d-md-none d-block small">
                WebComprehensive
              </span>
            </Link>
          </Col>
          <Col xs={3} className="text-center">
            <Navbar.Toggle
              className="mx-md-3"
              aria-controls="basic-navbar-nav"
            />
          </Col>
        </Row>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100">
            <Row className="ms-md-auto">
              <Col className="text-end d-flex align-items-center">
                <div className="ms-auto">
                  {sections.map((section) => {
                    return (
                      <Link
                        key={section.name}
                        to=""
                        onClick={() => handleClick(section)}
                        className={`nav-link me-3 d-inline ${
                          activeSection === section.name ? "active" : ""
                        }`}
                      >
                        {t(section.name)}
                      </Link>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
