import { Dropdown } from 'react-bootstrap';
import TreeSelectNode from './TreeSelectNode';
import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from "../LoadingSpinner";
import { apiRequestUri } from "../../helpers/helper";

const TreeSelect = ({ uri, value, className, onChange, formState }) => {
  const [data, setData] = useState([{}]);
  const [fetched, setFetched] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (!fetched) {
      const loadValue = (nodes) => {
        if (!nodes || !nodes.length) {
          return;
        }
        for (const key in nodes) {
          if (nodes[key].id === value) {
            setSelected(nodes[key]);
          }
          loadValue(nodes[key].children);
        }
      };

      const filterData = (data) => {
        const newData = data.filter((element) => {
          if (element.id === formState.id) {
            return false;
          }
          return true;
        });
        for (const key in newData) {
          let element = newData[key];
          if (Array.isArray(element.children) && element.children.length > 0) {
            const childrenData = filterData(element.children);
            if (childrenData && childrenData.length) {
              element.children = childrenData;
            } else {
              element.children = [];
            }
          }
        }
        return newData;
      }
      
      apiRequestUri(uri, "GET", {}, false).then((response) => {
        response.data.unshift({
          id: null,
          name: '',
          parent_id: null,
          children: [],
        })
        loadValue(response.data);
        setFetched(true);
        setData(filterData(response.data));
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [data, selected, fetched, uri, formState, value]);

  const handleNodeClick = (node) => {
    setSelected(node);
    onChange({
      target: {
        name: 'parent_id',
        value: node.id
      }
    });
  };

  return (
    <Dropdown data-tag="allowRowEvents">
      <Dropdown.Toggle className={className + ' text-end border'} variant="light">
        { !fetched ? (
          <LoadingSpinner size='sm'/>
        ) : (
          selected && selected.name 
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu variant="light" className={className + ' my-0 py-0'}>
        { data && data.map(node => (
          <TreeSelectNode
            action
            key={'select-'+node.id}
            node={node}
            selected={(selected && selected.id === node.id) ? true : false}
            onClick={handleNodeClick}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TreeSelect;