import {
  CDBSidebar,
  CDBSidebarMenu,
  CDBSidebarHeader,
  CDBSidebarFooter,
  CDBSidebarContent,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import SidebarCollapse from "./sidebarCollapse/SidebarCollapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sidebar = ({
  links,
  textColor,
  backgroundColor,
  titleTo,
  title,
  footer,
}) => {
  const [sidebarLinks, setSidebarLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const loadSidebar = useCallback(() => {
    setLoading(false);
    let sidebarLinks = [];
    for (const link of links) {
      if (Array.isArray(link.to)) {
        sidebarLinks.push(
          <SidebarCollapse key={link.text} data={link} eventKey={link.text} />
        );
      } else {
        sidebarLinks.push(
          <NavLink key={link.to} end to={link.to} target={link.target}>
            <CDBSidebarMenuItem>
              <FontAwesomeIcon icon={link.icon} className="me-2" />
              {t(link.text)}
            </CDBSidebarMenuItem>
          </NavLink>
        );
      }
    }
    setSidebarLinks(sidebarLinks);
  }, [setLoading, links, setSidebarLinks, t]);

  useEffect(() => {
    if (loading) {
      loadSidebar();
    }
  }, [loading, loadSidebar]);

  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        overflow: "scroll initial",
      }}
    >
      <CDBSidebar
        className="w-100"
        textColor={textColor}
        backgroundColor={backgroundColor}
      >
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <NavLink
            end
            to={titleTo}
            className="text-decoration-none me-3"
            style={{ color: "inherit" }}
          >
            {title}
          </NavLink>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>{sidebarLinks}</CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div style={{ padding: "20px 5px" }}>{footer}</div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
