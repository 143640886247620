import Swal from "sweetalert2";
import { appLogout } from "helpers/helper";
import { useNavigate } from "react-router-dom";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { AddressForm } from "components/address/AddressForm";
import { useTranslation } from "react-i18next";

const AdminAddressForm = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const { refetch, remove } = UserData();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();

  const errorCallback = () => {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
  };

  const successCallback = () => {
    Swal.fire({
      icon: "success",
      title: t("Address added successfully."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    });
    refetch().then(() => {
      setGoto("/admin/config/address");
    });
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Container fluid className="mt-5 text-center">
      <Row className="justify-content-center">
        <Col md="6">
          <Card>
            <Card.Body className="m-3">
              <AddressForm
                successCallback={successCallback}
                errorCallback={errorCallback}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default AdminAddressForm;
