export const FormInputs = [
  {
    type: "text",
    name: "code",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "title",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "price",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "previous_price",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "price_unit",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "weight",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "weight_unit",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "width",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "height",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "large",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "dimension_unit",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "material",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "color",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "package_units",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "text",
    name: "stock",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "brand",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "category",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "empty",
    name: "empty",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "checkbox",
    name: "featured",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "checkbox",
    name: "published",
    value: "",
    additionalAttrs: {
      required: false,
    },
  },
  {
    type: "editor",
    name: "description",
    value: "",
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
  {
    type: "editor",
    name: "right_text",
    value: "",
    additionalAttrs: {
      required: false,
    },
    fullWidth: true,
  },
];
