import { appLogout } from "helpers/helper";
import { Container } from "react-bootstrap";
import { AdminData } from "./data/AdminData";
import TopBar from "components/admin/TopBar";
import Sidebar from "components/admin/Sidebar";
import BreadCrumb from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useLocation, useNavigate, matchPath } from "react-router-dom";

export const AdminView = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: customer, isFetched, refetch, remove } = UserData();
  const [breadCrumb, setBreadCrumb] = useState(<LoadingSpinner />);
  const [loaded, setLoaded] = useState(false);
  const [sidebar, setSidebar] = useState(<></>);

  useEffect(() => {
    const loadSidebar = () => {
      if (isFetched && customer) {
        const links =
          customer.data.role_name === "admin"
            ? AdminData.adminSidebarLinks
            : AdminData.sidebarLinks;
        setSidebar(
          <Sidebar
            backgroundColor="#1cc88a"
            textColor="#5a5c69"
            title={process.env.REACT_APP_NAME}
            titleTo="/"
            links={links}
          />
        );
      } else {
        setSidebar(
          <div
            style={{
              backgroundColor: "#1cc88a",
              textColor: "#5a5c69",
              color: "rgb(90, 92, 105)",
              fontFamily:
                'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              fontSize: "17px",
              fontWeight: 700,
              letterSpacing: "1px",
              lineHeight: "25.5px",
              textAlign: "left",
              textDecorationColor: "rgb(90, 92, 105)",
              textDecorationLine: "none",
              textDecorationStyle: "solid",
              textDecorationThickness: "auto",
              textWrapMode: "nowrap",
              whiteSpaceCollapse: "collapse",
            }}
          >
            <div className="mx-4 px-1 mt-4">
              <span className="me-3">{process.env.REACT_APP_NAME}</span>
              <i className="fa fa-bars fa-large"></i>
              <div className="text-center">
                <LoadingSpinner className="m-5" />
              </div>
            </div>
          </div>
        );
      }
    };
    setSidebar(<></>);
    if (!loaded) {
      setLoaded(true);
      if (!customer || !customer.data || customer.data.role_name === "client") {
        refetch().then((response) => {
          if (!response.data) {
            remove();
            appLogout();
            navigate("/login");
          }
        });
      }
    }
    setBreadCrumb(<LoadingSpinner />);
    for (const key in AdminData.breadLinks) {
      const element = AdminData.breadLinks[key];
      const matchObject = matchPath(key, location.pathname);
      if (matchObject && matchObject.params.id !== "create") {
        setBreadCrumb(<BreadCrumb className="mt-5 ms-5" links={element} />);
        break;
      }
    }
    if (loaded) {
      loadSidebar();
    }
  }, [
    loaded,
    customer,
    children,
    location.pathname,
    navigate,
    refetch,
    remove,
    isFetched,
  ]);

  return (
    <Container
      className="d-flex p-0 m-0 mw-100"
      style={{
        minHeight: "100vh",
        height: "100%",
      }}
    >
      {sidebar}
      <Container className="p-0 m-0 mw-100 overflow-x-auto">
        <div
          style={{
            minHeight: "90vh",
          }}
        >
          <TopBar
            customer={customer?.data || null}
            iconColor="#1cc88a"
            textColor="#fff"
            backgroundColor="#5a5c69"
          />
          <Container className="p-0 m-0 mw-100">
            {breadCrumb}
            {children}
          </Container>
        </div>
        <footer
          className="bg-secondary px-3 py-3"
          style={{
            minHeight: "10vh",
          }}
        >
          <small className="text-light">
            {t(
              "For the best browsing experience in your store, we recommend adjusting your images' height, width, and format whenever possible. Larger images will take longer to load and lighter image formats will take less time."
            )}
          </small>
        </footer>
      </Container>
    </Container>
  );
};
