import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Products } from "helpers/ClientHelper";
import { ClientView } from "views/client/Client";
import OrderSelect from "components/client/OrderSelect";
import { LoadingSpinner } from "components/LoadingSpinner";
import { CatalogeList } from "components/client/CatalogeList";
import CatalogeCarousel from "components/carousel/CatalogeCarousel";
import CatalogePaginator from "components/client/CatalogePaginator";

export const Cataloge = ({ customer, customerPath }) => {
  const perPage = 9;

  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  const [images, setImages] = useState([]);
  const [orderBy, setOrderBy] = useState("featured");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [search, setSearch] = useState();

  const {
    data: products,
    isFetching,
    isLoading,
    isRefetching,
  } = Products(customer.id, page, perPage, orderBy, orderDirection, search);

  useEffect(() => {
    if (products) {
      setLastPage(products.meta.last_page);
    }
    if (customer && customer.data && customer.data.carousel) {
      setImages(customer.data.carousel);
    }
  }, [products, customer, customerPath]);

  const handlePage = (page) => {
    setPage(page);
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearch(search);
  };

  const handleOrder = (e) => {
    setPage(1);
    switch (e.target.value) {
      case "featured":
        setOrderBy("featured");
        setOrderDirection("desc");
        break;
      case "lowerPrice":
        setOrderBy("price");
        setOrderDirection("asc");
        break;
      case "higherPrice":
        setOrderBy("price");
        setOrderDirection("desc");
        break;

      default:
        setOrderBy("featured");
        setOrderDirection("desc");
        break;
    }
  };

  return (
    <ClientView
      customer={customer}
      customerPath={customerPath}
      handleSearch={handleSearch}
    >
      <CatalogeCarousel images={images} />
      <Container className="text-center mt-1 mb-1">
        <OrderSelect handleOrder={handleOrder} />
        {isFetching || isLoading || isRefetching ? (
          <LoadingSpinner />
        ) : (
          <CatalogeList
            products={products?.data}
            customer={customer}
            customerPath={customerPath}
          />
        )}
        <CatalogePaginator
          page={page}
          lastPage={lastPage}
          handlePage={handlePage}
          customer={customer}
        />
      </Container>
    </ClientView>
  );
};
