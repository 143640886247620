import { useState } from "react";
import { Image } from "components/Image";
import ImageUploader from "./ImageUploader";

const ImageInput = ({ id, name, className, onChange, value }) => {
  const [imageUrl, setImageUrl] = useState(value);

  const selectedImageCallback = (url) => {
    setImageUrl(url);
  };

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <ImageUploader
        id={id}
        name={name}
        selectedImageCallback={selectedImageCallback}
        onChange={handleChange}
      />
      {imageUrl && <Image src={imageUrl} alt="Selected" width="100%" />}
    </>
  );
};

export default ImageInput;
