export const FormInputs = [
  {
    type: "textarea",
    name: "answer",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
];
