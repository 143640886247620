export const TableMapping = {
// column: data
  Position: {
    property: "position",
    sortable: true,
    transform: false,
  },
  OriginalName: {
    property: "original_name",
    sortable: true,
    transform: false,
  },
  Url: {
    property: "url",
    sortable: true,
    transform: "link",
  },
  Updated: {
    property: "updated_at",
    sortable: true,
    transform: "date",
  },
};
