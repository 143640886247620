import { inputs } from "./addressInputs";
import { Form } from "components/form/Form";
import { useTranslation } from "react-i18next";

export const AddressForm = ({ successCallback, errorCallback, className }) => {
  const labelPrefix = "";
  const buttonText = "Add";
  const values = {};
  const { t } = useTranslation();

  const target = {
    uri: "address",
    method: "POST",
    successCallback: successCallback,
  };

  return (
    <div className={"text-dark " + className}>
      <h5 className="my-2">{t("New Address")}</h5>
      <hr />
      <Form
        inputs={inputs}
        labelPrefix={labelPrefix}
        buttonText={buttonText}
        initialForm={values}
        target={target}
        errorCallback={() => errorCallback()}
      />
    </div>
  );
};
