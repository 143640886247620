import "./SidebarCollapseItem.css";
import { useEffect, useState } from "react";
import { CDBSidebarMenuItem } from "cdbreact";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink, useAccordionButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarCollapseItem = ({ data, eventKey, clickCallback }) => {
  const [goto, setGoto] = useState();
  const navigate = useNavigate();
  const decoratedOnClick = useAccordionButton(eventKey);
  const { t } = useTranslation();

  const handleClick = () => {
    clickCallback();
    setGoto(data.to);
    decoratedOnClick();
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <NavLink
      key={data.to}
      onClick={handleClick}
      className="sidebar-collapse-item"
    >
      <div className="ms-2">
        <CDBSidebarMenuItem>
          <FontAwesomeIcon icon={data.icon} className="me-2" />
          {t(data.text)}
        </CDBSidebarMenuItem>
      </div>
    </NavLink>
  );
};

export default SidebarCollapseItem;
