import Tesseract from "tesseract.js";

export const filterRecognizedText = (text) => {
  const nameMatch = text.match(/Nombre\s*\/\s*Name\s*[:\s]*(.*)/i);
  const surnameMatch = text.match(/Apellido\s*\/\s*Surname\s*[:\s]*(.*)/i);
  const idNumberMatch = text.match(/\b(\d{2}\.\d{3}\.\d{3})\b/);

  let nameResult;
  let surnameResult;
  let idNumberResult;

  if (nameMatch) {
    nameResult = nameMatch[1].replace(/4B\s*/, '').trim();
  }

  if (surnameMatch) {
    surnameResult = surnameMatch[1].replace(/NOT———\s*/, '').trim();
  }

  if (idNumberMatch) {
    idNumberResult = idNumberMatch[1];
  }

  return { nameResult, surnameResult, idNumberResult };
};

export const getDocumentNumber = (imageUrl) => {
  return Tesseract.recognize(imageUrl, 'eng').then((result) => {
      const { idNumberResult } = filterRecognizedText(result.data.text);
      return idNumberResult;
  }).catch((error) => {
    throw error;
  });
}