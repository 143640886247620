import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Form } from "components/form/Form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Container, Row, Col } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { FormInputs } from "./data/FormOpenGraphInputs";
import { FormMapping } from "./data/FormOpenGraphMapping";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

const AdminOpenGraphForm = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const { data: customer, refetch: refetchCustomer, remove } = UserData();

  let formAction = t("Edit - OpenGraph Configuration");
  let labelPrefix = "";
  let buttonText = t("Update");

  let target = useMemo(
    () => ({
      method: "PUT",
      uri: "user/" + (customer ? customer.data.id : ""),
      redirectUrl: "/admin/config/opengraph",
    }),
    [customer]
  );

  const navigate = useNavigate();
  const [content, setContent] = useState(<LoadingSpinner />);
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  const handleShow = useCallback(() => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then((result) => {
      remove();
      appLogout().then(() => {
        setGoto("/login");
      });
    });
  }, [remove, closeTime, t]);

  const getValues = (data) => {
    let values = data ? data.openGraph : {};
    for (const key in FormMapping) {
      const element = FormMapping[key];
      values[key] = data.openGraph[element];
    }
    if (!values) {
      values = {};
    }
    // values["image"] = "";
    return values;
  };

  const loadContent = useCallback(
    (values, onSubmit) => {
      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{formAction}</h4>
                <Form
                  inputs={FormInputs}
                  labelPrefix={labelPrefix}
                  buttonText={buttonText}
                  values={values}
                  target={target}
                  submitCallback={onSubmit}
                  errorCallback={handleShow}
                  update={true}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    },
    [buttonText, formAction, handleShow, labelPrefix, target]
  );

  const onSubmit = useCallback(
    (formState, event) => {
      setContent(<LoadingSpinner />);
      refetchCustomer().then((response) => {
        if (!response || !response.data) {
          handleShow();
          return;
        }
        let newData = response.data.data.data;
        if (!newData) {
          newData = {};
        }
        if (!Object.hasOwnProperty.call(newData, "openGraph")) {
          newData["openGraph"] = [];
        }
        const file = event.target.image.files[0];
        if (file) {
          let uri = "file";
          let formData = new FormData();
          formData.append("file", file);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
              Accept: "application/json",
            },
          };
          apiRequestUri(uri, "POST", formData, config)
            .then((response) => {
              formState["image"] = response.url;
              newData.openGraph = formState;
              apiRequestUri(target.uri, target.method, { data: newData }, false)
                .then((response) => {
                  refetchCustomer().then((response) => {
                    Swal.fire({
                      icon: "success",
                      title: t("OpenGraph Configuration updated successfully."),
                      showConfirmButton: false,
                      timer: closeTime,
                      timerProgressBar: true,
                    }).then((result) => {
                      const values = getValues(response.data.data.data);
                      loadContent(values, onSubmit);
                    });
                  });
                })
                .catch((error) => {
                  handleShow();
                });
            })
            .catch((error) => {
              handleShow();
            });
        } else {
          newData.openGraph = formState;
          refetchCustomer().then((response) => {
            newData.openGraph["image"] =
              response.data.data.data && response.data.data.data.openGraph
                ? response.data.data.data.openGraph["image"]
                : "";
            apiRequestUri(target.uri, target.method, { data: newData }, false)
              .then((response) => {
                refetchCustomer().then((response) => {
                  Swal.fire({
                    icon: "success",
                    title: t("OpenGraph Configuration updated successfully."),
                    showConfirmButton: false,
                    timer: closeTime,
                    timerProgressBar: true,
                  }).then((result) => {
                    const values = getValues(response.data.data.data);
                    loadContent(values, onSubmit);
                  });
                });
              })
              .catch((error) => {
                handleShow();
              });
          });
        }
      });
    },
    [
      closeTime,
      handleShow,
      loadContent,
      refetchCustomer,
      t,
      target.method,
      target.uri,
    ]
  );

  useEffect(() => {
    if (!loaded && customer) {
      setLoaded(true);
      const values = getValues(customer.data.data);
      refetchCustomer();
      loadContent(values, onSubmit);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    loaded,
    customer,
    loadContent,
    refetchCustomer,
    goto,
    navigate,
    onSubmit,
  ]);

  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
export default AdminOpenGraphForm;
