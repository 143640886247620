export const FormInputs = [
  {
    type: "select",
    name: "status",
    value: "",
    options: [
      {
        text: "Waiting",
        value: "waiting",
      },
      {
        text: "Done",
        value: "done",
      }
    ],
    additionalAttrs: {
      required: true,
    },
  },
];
