import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Form } from "components/form/Form";
import { FormInputs } from "./data/FormInputs";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { FormMapping } from "./data/FormMapping";
import { Container, Row, Col } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

const CategoryForm = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { data: customer, remove } = UserData();
  const navigate = useNavigate();
  let { id } = useParams();
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  let formAction = t("Create Category");
  let labelPrefix = "";
  let buttonText = t("Store");

  const target = useMemo(
    () => ({
      method: id ? "PUT" : "POST",
      uri: "category" + (id ? "/" + id : ""),
      redirectUrl: "/admin/category",
    }),
    [id]
  );

  if (id) {
    formAction = t("Edit Category ");
    buttonText = t("Update");
  }

  const loadContent = useCallback(
    (values) => {
      const handleShow = () => {
        Swal.fire({
          icon: "error",
          title: t("An error has occurred."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        }).then((result) => {
          remove();
          appLogout().then(() => {
            setGoto("/login");
          });
        });
      };

      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{formAction}</h4>
                <Form
                  inputs={FormInputs}
                  labelPrefix={labelPrefix}
                  buttonText={buttonText}
                  values={values}
                  target={target}
                  errorCallback={handleShow}
                  user={customer.data}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    },
    [
      buttonText,
      customer,
      formAction,
      labelPrefix,
      target,
      closeTime,
      remove,
      t,
    ]
  );

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      if (id) {
        let uri = "category/" + id;
        apiRequestUri(uri, "GET", {}, false)
          .then((response) => {
            let values = response;
            for (const key in FormMapping) {
              const element = FormMapping[key];
              values[key] = response[element];
            }
            loadContent(values);
          })
          .catch((error) => {
            loadContent({});
          });
      } else {
        loadContent({});
      }
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [id, loadContent, loaded, goto, navigate]);
  const [content, setContent] = useState(<LoadingSpinner />);
  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
export default CategoryForm;
