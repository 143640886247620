import { Rating } from "@mui/material";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const transformData = (data, mapping, t = null) => {
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  let responseData = data;
  for (const key in mapping) {
    const element = mapping[key];
    for (const index in responseData) {
      if (element.transform === "index") {
        responseData[index][element.property] = index;
      }
      if (element.transform === "date") {
        let date = responseData[index][element.property];
        responseData[index][element.property] =
          date && date.split("T").join(" ").replace(".000000Z", "");
      }
      if (element.transform === "link") {
        responseData[index][element.property] = (
          <a
            href={responseData[index][element.property]}
            target="_blank"
            rel="noreferrer"
            title={t ? t("File") : "File"}
          >
            {responseData[index][element.property]}
          </a>
        );
      }
      if (element.transform === "price") {
        if (responseData[index][element.property]) {
          const formattedPrice =
            responseData[index][element.property] &&
            responseData[index][element.property].toLocaleString(undefined, {
              maximumFractionDigits: 2,
            });
          responseData[index][element.property] = unitPrice + formattedPrice;
        }
      }
      if (element.transform === "stars") {
        if (responseData[index][element.property]) {
          responseData[index][element.property] = (
            <Rating
              readOnly
              defaultValue={responseData[index][element.property]}
              size="small"
            />
          );
        }
      }
      if (element.transform === "x") {
        if (
          responseData[index][element.property] &&
          responseData[index][element.property] === 1
        ) {
          responseData[index][element.property] = (
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="mb-2 text-danger"
            />
          );
        }
      }
      if (element.transform === "check") {
        if (
          responseData[index][element.property] &&
          responseData[index][element.property] === 1
        ) {
          responseData[index][element.property] = (
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="mb-2 text-success"
            />
          );
        } else {
          responseData[index][element.property] = (
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="mb-2 text-danger"
            />
          );
        }
      }
      if (element.transform && typeof element.transform === "object") {
        if (!responseData[index][element.property]) {
          responseData[index][element.property] = element.transform.onNull;
        }
      }
      if (element.transform && typeof element.transform === "function") {
        responseData[index][element.property] = element.transform(
          responseData[index][element.property],
          responseData[index],
          responseData,
          t
        );
      }
    }
  }
  return responseData;
};
