import {
  Col,
  Nav,
  Row,
  Navbar,
  Tooltip,
  NavDropdown,
  OverlayTrigger,
} from "react-bootstrap";
import SearchBar from "./SearchBar";
import styles from "./header.module.css";
import { Image } from "components/Image";
import {
  faUser,
  faUserCog,
  faSignOutAlt,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { appLogout } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Link, useNavigate } from "react-router-dom";
import CartIcon from "components/client/cart/CartIcon";
import { isLightColor } from "helpers/functionsHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomNavLink from "../CustomNavLink";

const Header = ({
  backgroundColor,
  links,
  customer,
  customerPath,
  textColor,
  handleSearch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const { data: currentUser, remove } = UserData();
  const [searchValue, setSearchValue] = useState();
  const [topbarLinks, setTopbarLinks] = useState([]);
  const [dropDownTitle, setDropDownTitle] = useState(null);

  const brand = customerPath
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  let brandContent = brand || t("Brand");
  if (
    customer.data &&
    customer.data.style &&
    customer.data.style.logo &&
    customer.data.style.logo !== ""
  ) {
    brandContent = (
      <span>
        <Image
          src={customer.data.style.logo}
          alt={brandContent}
          className={`${styles.brandImage}`}
        />
        {customer.data.style.showbrand ? brandContent : ""}
      </span>
    );
  }

  const dropdownStyle = `
    .navbar-nav .dropdown-menu {
      background-color: ${
        customer.data && customer.data.style
          ? customer.data.style.backgroundcolor
          : "#ffffff"
      }
    }
  `;

  const isTextColorLight = isLightColor(textColor);

  const iconBackgroundColor = isTextColorLight ? "#000000" : "#ffffff";

  const textShadowStyle = useMemo(() => {
    return {
      textShadow: isTextColorLight
        ? "1px 1px 1.5px #000000"
        : "1px 1px 1.5px #ffffff",
    };
  }, [isTextColorLight]);

  const iconWrapperStyle = useMemo(() => {
    return {
      backgroundColor: iconBackgroundColor,
      padding: "5px",
      borderRadius: "50%",
      display: "inline-block",
      textShadow: isTextColorLight
        ? "1px 1px 1.5px #000000"
        : "1px 1px 1.5px #ffffff",
    };
  }, [iconBackgroundColor, isTextColorLight]);

  const iconStyle = useMemo(() => {
    return {
      fontSize: "1.2em",
      color: textColor,
    };
  }, [textColor]);

  const logOut = (event) => {
    event.preventDefault();
    remove();
    appLogout().then(() => {
      setGoto("/" + customerPath + "/login");
    });
  };

  const goToProfile = (event) => {
    event.preventDefault();
    setGoto("/" + customerPath + "/profile");
  };

  const loadTopbarLinks = useCallback(() => {
    const linkStyle = {
      color: textColor,
      ...textShadowStyle,
    };

    setTopbarLinks(
      links.map((link) => {
        return (
          <CustomNavLink
            key={"headerCustomNavLink-" + link.text}
            link={link}
            linkStyle={linkStyle}
            textColor={textColor}
            textShadowStyle={textShadowStyle}
          />
        );
      })
    );
  }, [links, textColor, textShadowStyle]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClick = (e) => {
    if (handleSearch) {
      handleSearch(searchValue);
    }
  };

  useEffect(() => {
    loadTopbarLinks();
    if (currentUser && currentUser.data && currentUser.data.name) {
      setLoggedIn(true);
      setDropDownTitle(
        <span style={{ color: textColor, ...textShadowStyle }}>
          <OverlayTrigger
            placement="top"
            delay={{ hide: 100 }}
            overlay={
              <Tooltip>
                {currentUser.data.validated === 1
                  ? t("verified")
                  : t("not verified")}
              </Tooltip>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <span
                style={{ color: textColor, ...textShadowStyle }}
                {...triggerHandler}
              >
                <span className="h5">{currentUser.data.name}&nbsp;</span>
                <span
                  style={iconWrapperStyle}
                  className="d-inline-flex align-items-center justify-content-center"
                >
                  <FontAwesomeIcon icon={faUser} style={iconStyle} />
                </span>
                <small
                  className={`h6 text-${
                    currentUser.data.validated === 1 ? "primary" : "danger"
                  } me-2`}
                  ref={ref}
                >
                  <FontAwesomeIcon
                    icon={
                      currentUser.data.validated === 1
                        ? faCircleCheck
                        : faCircleXmark
                    }
                    className="mb-2"
                  />
                </small>
              </span>
            )}
          </OverlayTrigger>
        </span>
      );
    } else {
      setLoggedIn(false);
      setDropDownTitle(null);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    goto,
    currentUser,
    textColor,
    iconStyle,
    iconWrapperStyle,
    loadTopbarLinks,
    textShadowStyle,
    navigate,
    t,
  ]);

  return (
    <header
      className="border-bottom shadow-sm py-0 my-0"
      style={{
        height: "10vh",
        minHeight: "50px",
      }}
    >
      <style>{dropdownStyle}</style>
      <Navbar
        className="h-100"
        expand={"md"}
        style={{ backgroundColor: backgroundColor }}
      >
        <Col md={2} xs={2}>
          <Link
            className="font-weight-bold mx-1 ms-md-3 navbar-brand"
            to={`/${customerPath}`}
            style={{ color: textColor, ...textShadowStyle }}
          >
            <small className="fs-6">{brandContent}</small>
          </Link>
        </Col>
        <Col md={3} xs={6}>
          <SearchBar
            btnColor={textColor}
            className={`${styles.searchBar}`}
            handleChange={handleChange}
            handleClick={handleClick}
          />
        </Col>
        <CartIcon
          customer={customer}
          customerPath={customerPath}
          iconStyle={iconStyle}
          iconWrapperStyle={iconWrapperStyle}
          style={{ color: textColor, ...textShadowStyle }}
          className="nav-link d-flex align-items-center h-100 px-md-3 mt-2"
        />
        <Navbar.Toggle
          style={{ backgroundColor: "white" }}
          className="ms-2 me-1 my-0 py-0"
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ backgroundColor: backgroundColor }}
          className="z-3"
        >
          <Nav className="w-100">
            <Row className="w-100 me-2 p-0">
              <Col xs={1} md={4} className="d-flex mx-1 p-0">
                <span className="mt-2 z-2">{topbarLinks}</span>
              </Col>
              <Col className="d-flex mx-1 p-0">
                {customer.data?.about && (
                  <Link
                    to={`/${customerPath}/about`}
                    className="nav-link ms-auto me-1 mt-2 z-2"
                    style={{ color: textColor, ...textShadowStyle }}
                  >
                    {t("About")}
                  </Link>
                )}
              </Col>
              <Col className="d-flex mx-1 p-0">
                {loggedIn ? (
                  <NavDropdown
                    title={dropDownTitle}
                    className="ms-auto"
                    id="collapsible-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={goToProfile}>
                      {t("Profile")} <FontAwesomeIcon icon={faUserCog} />
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/logout" onClick={logOut}>
                      {t("Logout")} <FontAwesomeIcon icon={faSignOutAlt} />
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <Link
                    to={`/${customerPath}/login`}
                    className="nav-link ms-auto me-3"
                    style={{ color: textColor, ...textShadowStyle }}
                  >
                    {t("Login")}
                  </Link>
                )}
              </Col>
            </Row>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
