import { useState } from "react";
import DOMPurify from "dompurify";
import { Image } from "components/Image";
import { ProductPrice } from "./ProductPrice";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ShareButtons from "components/ShareButtons";
import { ProductFeatured } from "./ProductFeatured";
import { ProductRightText } from "./ProductRightText";
import { ProductStockText } from "./ProductStockText";
import { Carousel } from "components/client/Carousel";
import { Card, CarouselItem, Col, Modal, Row } from "react-bootstrap";

export const CardDetail = ({ product, className, rightData, customerPath }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const hostUrl = window.location.href.replace(location.pathname, "");

  const textShadowStyle = {
    textShadow: "1px 1px 2px white",
  };

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const safeDescription = DOMPurify.sanitize(product.description);

  return (
    <div className={className}>
      <Row className="justify-content-center">
        <Card style={{ width: "40rem" }}>
          <ProductFeatured product={product} top={0} />
          <ProductRightText product={product} top={0} />
          <ProductStockText product={product} top={350} />
          <ShareButtons
            position="absolute"
            top={120}
            left={0}
            cardTitle={t("Share this Product")}
            title={product.title}
            url={`${hostUrl}/${customerPath}/products/${product.id}`}
          />
          {!product.images.length ? (
            <Image
              src="/images/no-image-available.webp"
              alt="No Image Available"
              style={{ height: "25rem" }}
            />
          ) : (
            <Carousel>
              {product.images.map((img, index) => (
                <CarouselItem key={index} style={{ height: "25rem" }}>
                  <Image
                    style={{
                      maxWidth: "100%",
                      maxHeight: "400px",
                      objectFit: "contain",
                    }}
                    className="d-block h-100 mx-auto"
                    src={img.url}
                    alt={`Slide ${index + 1}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                </CarouselItem>
              ))}
            </Carousel>
          )}
          <Card.Body>
            <Row>
              <Col>
                <Card.Title
                  className="fw-bold fs-4 mb-3 text-center"
                  style={textShadowStyle}
                >
                  {product.title}
                </Card.Title>
              </Col>
              {rightData && <Col className="text-center">{rightData}</Col>}
            </Row>
            <Row>
              <Col>
                <Card.Text className="text-center">
                  <ProductPrice product={product} />
                </Card.Text>
              </Col>
              <Col>
                <Card.Text className="text-center mt-3">
                  <small>
                    <span className="mb-1 fw-bold" style={textShadowStyle}>
                      {t("Stock")}:{" "}
                    </span>
                    <span className="fw-light">{product.stock}</span>
                  </small>
                </Card.Text>
              </Col>
            </Row>

            <Card.Text
              className="border rounded overflow-auto"
              style={{ maxHeight: 500 }}
            >
              <div className="fw-bold fs-5 mb-1" style={textShadowStyle}>
                {t("Description")}:
              </div>
              <span
                className="fw-light"
                dangerouslySetInnerHTML={{ __html: safeDescription }}
              />
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>

      {/* LARGE-DEVICE-MODAL */}
      <Modal
        show={isHovered}
        centered={true}
        size="xl"
        className="d-sm-block d-none"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Modal.Body className="text-center w-100">
          <Carousel>
            {product.images.map((img, index) => (
              <CarouselItem
                key={index}
                style={{ height: "85vh", maxHeight: "85vh" }}
              >
                <Image
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                  className="d-block h-100 mx-auto"
                  src={img.url}
                  alt={`Slide ${index + 1}`}
                />
              </CarouselItem>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
};
