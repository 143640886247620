export const FormInputs = [
  {
    type: "text",
    name: "name",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "treeSelect",
    name: "parent_id",
    label: "Parent",
    value: "",
    additionalAttrs: {
      required: true,
    },
    uri: "category/tree",
    addUserId: true,
  },
];
