import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Image } from "components/Image";
import ImageCropper from "./ImageCropper";
import ImageUploader from "./ImageUploader";

const CropInput = ({ id, name, className, onChange, aspect, value }) => {
  const [imageUrl, setImageUrl] = useState(false);
  const [selected, setSelected] = useState(value);

  const selectedImageCallback = (url) => {
    setImageUrl(url);
  };

  const handleChange = (event) => {
    setSelected(false);
  };

  const handleCut = (croppedImage) => {
    setSelected(croppedImage.url);
    const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
      type: "image/png",
    });
    const event = {
      target: {
        name: name,
        value: imageFile,
        files: {
          0: imageFile,
        },
      },
    };
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <ImageUploader
        id={id}
        name={name}
        selectedImageCallback={selectedImageCallback}
        onChange={handleChange}
      />
      {imageUrl && (
        <Modal show={imageUrl && !selected} centered={true}>
          <Modal.Body
            className={"bg- text-white text-center"}
            style={{ height: 500 }}
          >
            <ImageCropper
              aspect={aspect}
              imageUrl={imageUrl}
              onChange={handleCut}
            />
          </Modal.Body>
        </Modal>
      )}
      {selected && <Image src={selected} alt="Selected" width="100%" />}
    </>
  );
};

export default CropInput;
