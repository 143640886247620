import { getEnhancedText } from "helpers/functionsHelper";

export const ProductRightText = ({ product, top }) => {
  const safeHTML = getEnhancedText(product.right_text);

  return (
    <>
      {product.right_text && (
        <div
          className="m-1 z-2 p-1 rounded-3 fw-bold text-light"
          style={{
            textShadow: "0 0 10px #2c3677, 0 0 15px #2c3677 , 0 0 20px #2c3677",
            position: "absolute",
            top: top,
            right: 0,
            border: "1px solid white",
            lineHeight: 1,
            background: "linear-gradient(0.125turn, #0053a4, #54b5f9)",
          }}
          dangerouslySetInnerHTML={{ __html: safeHTML }}
        />
      )}
    </>
  );
};
