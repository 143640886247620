import { Carousel as CarouselBase } from "react-bootstrap";

export const Carousel = ({ children, className, style }) => {
  return (
    <CarouselBase
      className={className}
      style={style}
      nextIcon={
        <span
          aria-hidden="true"
          style={{ filter: "drop-shadow(1px 1px 2px black)" }}
          className="carousel-control-next-icon"
        />
      }
      prevIcon={
        <span
          aria-hidden="true"
          style={{ filter: "drop-shadow(1px 1px 2px black)" }}
          className="carousel-control-prev-icon"
        />
      }
    >
      {children}
    </CarouselBase>
  );
};
