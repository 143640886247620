import Swal from "sweetalert2";
import { Table } from "components/Table";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Container, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { TableMapping } from "./data/TableMapping";
import { Link, useNavigate } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

export const ProductTable = () => {
  const importExport = true;
  const progressRefresh = 250;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const { data: customer, remove, isFetched } = UserData();
  let uri = "product?user_id=" + (customer?.data?.id || "");
  const [loading, setLoading] = useState(false);
  const [progess, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [modalConent, setModalConent] = useState();

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const showImportError = (message) => {
    setShowProgress(false);
    Swal.fire({
      icon: "error",
      title: message ? t(message) : t("Cannot import file."),
      showConfirmButton: true,
    });
  };

  const showImportSuccess = () => {
    setShowProgress(false);
    setLoading(true);
    Swal.fire({
      icon: "success",
      title: t("File imported successfully."),
      showConfirmButton: true,
    }).then(() => {
      setLoading(false);
    });
  };

  const showExportError = (message) => {
    setShowProgress(false);
    Swal.fire({
      icon: "error",
      title: message ? t(message) : t("Cannot Export file."),
      showConfirmButton: true,
    });
  };

  const updateImportProgress = (jobId) => {
    let uri = "product/import/status?job_id=" + jobId;
    apiRequestUri(uri, "GET")
      .then((response) => {
        if (response.percentage) {
          setProgress(parseInt(response.percentage));
        } else {
          setProgress("Prease wait.");
        }
        if (response.status === "done") {
          showImportSuccess();
        } else {
          setTimeout(function () {
            updateImportProgress(jobId);
          }, progressRefresh);
        }
      })
      .catch((error) => {
        const shortMessage = error.response.data.message.match(/.{1,150}/g);
        showImportError(
          shortMessage[1]
            ? shortMessage[0] + " ..."
            : error.response.data.message
        );
      });
  };

  const updateExportProgress = (jobId) => {
    let uri = "product/export/status?job_id=" + jobId;
    apiRequestUri(uri, "GET")
      .then((response) => {
        if (response.percentage) {
          setProgress(parseInt(response.percentage));
        } else {
          setProgress("Prease wait.");
        }
        if (response.status === "done") {
          setShowProgress(false);
          handleDownload(response.url, "Products.xlsx");
        } else {
          setTimeout(function () {
            updateExportProgress(jobId);
          }, progressRefresh);
        }
      })
      .catch((error) => {
        const shortMessage = error.response.data.message.match(/.{1,150}/g);
        showExportError(
          shortMessage[1]
            ? shortMessage[0] + " ..."
            : error.response.data.message
        );
      });
  };

  const handleImport = (event) => {
    let uri = "product/import?user_id=" + customer?.data?.id;
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("document", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      },
    };
    setProgress("Prease wait.");
    setModalConent(<h4>Importing Products ...</h4>);
    setShowProgress(true);
    apiRequestUri(uri, "POST", formData, config)
      .then((response) => {
        if (response.job_id) {
          updateImportProgress(response.job_id);
        } else {
          showImportError();
        }
      })
      .catch((error) => {
        showImportError();
      });
  };

  const handleExport = () => {
    let uri = "product/export?user_id=" + customer?.data?.id;
    setProgress("Prease wait.");
    setModalConent(<h4>Exporting Products ...</h4>);
    setShowProgress(true);
    apiRequestUri(uri, "GET")
      .then((response) => {
        if (response.job_id) {
          updateExportProgress(response.job_id);
        } else {
          showImportError();
        }
      })
      .catch((error) => {
        showExportError();
      });
  };

  const titleElement = (
    <>
      {importExport && (
        <>
          <form className="d-inline ms-5 me-3">
            <label htmlFor="files" className="btn btn-warning">
              {t("Import")}
            </label>
            <input
              id="files"
              className="d-none"
              type="file"
              accept=".xlsx"
              onChange={handleImport}
            />
          </form>
          <Link
            key="/admin/export"
            onClick={handleExport}
            className="btn btn-success me-5"
          >
            {t("Export")}
          </Link>
        </>
      )}
      <Link
        key="/admin/product/create"
        to="/admin/product/create"
        className="btn btn-primary"
      >
        {t("Create")}
      </Link>
    </>
  );

  const deleteItem = (id, product) => {
    Swal.fire({
      icon: "warning",
      title: t("Warning!"),
      text:
        t("Do you want to delete the product: ") +
        '"' +
        product.title +
        '"' +
        t(" with code: ") +
        '"' +
        product.code +
        '"' +
        "?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("No"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let uri = "product/" + id;
        apiRequestUri(uri, "DELETE", {}, false)
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            remove();
            appLogout().then(() => {
              setGoto("/login");
            });
          });
      }
    });
  };

  let action = {
    Images: {
      url: "/admin/product/image",
      addId: true,
    },
    Documents: {
      url: "/admin/product/document",
      addId: true,
    },
    Show: {
      url: "/admin/product",
      addId: true,
    },
    Edit: {
      url: "/admin/product/edit",
      addId: true,
    },
    Delete: {
      callback: deleteItem,
    },
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  if (isFetched && (!customer || !customer.data)) {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
    return;
  }

  return (
    <Container className="mt-0 mb-5 text-center mx-0 mw-100">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Table
          title={t("Products")}
          mapping={TableMapping}
          titleElement={titleElement}
          action={action}
          uri={uri}
        />
      )}
      <Modal show={showProgress} centered={true}>
        <Modal.Body className={"text-center"}>
          {modalConent}
          <ProgressBar animated now={progess} label={`${progess}%`} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ProductTable;
