import React from "react";
import {
  Html,
  Head,
  Body,
  Container,
  Text,
  Button,
  Link,
} from "@react-email/components";
import { Image } from "components/Image";

const TemplateEmailForgot = ({
  customer,
  customerPath,
  email,
  token,
  hostUrl,
  t,
}) => {
  const link = `${hostUrl}${
    customerPath ? "/" + customerPath : ""
  }/password/reset?token=${token}&email=${email}`;
  const logo = customer
    ? customer.data?.style?.logo
    : "https://webcomprehensive.com/logo.png";
  const brand = customer ? customer.name : "STORE WEBCOMPREHENSIVE";
  const backgroundColor = customer
    ? customer.data?.style?.backgroundcolor
    : "#000080";
  return (
    <Html>
      <Head />
      <Body>
        <Container>
          <table
            align="center"
            style={{
              height: 424,
              margin: "16px 0",
              borderRadius: 12,
              backgroundColor: backgroundColor ? backgroundColor : "#000080",
            }}
            width="100%"
          >
            <tbody>
              <tr>
                <td
                  align="center"
                  style={{ padding: 40, textAlign: "center" }}
                  valign="top"
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: 5,
                      padding: 5,
                    }}
                  >
                    {logo && (
                      <Text
                        style={{
                          marginTop: 5,
                          marginBottom: 0,
                          padding: 2,
                          textAlign: "center",
                        }}
                      >
                        <Image src={logo} alt="logo" height="50px" />
                      </Text>
                    )}
                    <Text
                      style={{
                        margin: "20px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: 45,
                        color: "black",
                        textAlign: "center",
                        marginTop: 5,
                        lineHeight: 1,
                        padding: 2,
                      }}
                    >
                      {brand}
                    </Text>
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: 20,
                      borderRadius: 5,
                      marginLeft: 5,
                      marginRight: 5,
                      padding: 40,
                    }}
                  >
                    <Text
                      style={{
                        marginTop: 10,
                        fontFamily: "Montserrat",
                        fontSize: 25,
                        lineHeight: "24px",
                        color: "black",
                        textAlign: "center",
                        backgroundColor: "white",
                      }}
                    >
                      {t(
                        "Hello! You are receiving this email because we received a password reset request for your account."
                      )}
                    </Text>
                    <Button
                      href={link}
                      style={{
                        boxShadow:
                          "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
                        borderRadius: 20,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "rgb(229,231,235)",
                        backgroundColor: "#ffa500",
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingTop: 12,
                        paddingBottom: 12,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        color: "white",
                        marginTop: 20,
                        marginBottom: 30,
                        fontSize: 20,
                      }}
                    >
                      {t("RESET PASSWORD")}
                    </Button>
                    <Text
                      style={{
                        margin: "25px",
                        marginTop: 10,
                        fontFamily: "Montserrat",
                        fontSize: 25,
                        lineHeight: "24px",
                        color: "black",
                        textAlign: "center",
                        backgroundColor: "white",
                        marginBottom: "50px",
                      }}
                    >
                      {t(
                        "This password reset link will expire in 60 minutes. If you did not request a password reset, no further action is required. Regards, WebComprehensive."
                      )}
                    </Text>
                    <hr />
                    <Text
                      style={{
                        margin: "16px 16px",
                        fontFamily: "Montserrat",
                        fontSize: 15,
                        textAlign: "start",
                        color: "black",
                      }}
                    >
                      {t(
                        "If you're having trouble clicking the “Reset Password“ button, copy and paste the URL below into your web browser:"
                      )}
                      <br />
                    </Text>
                    <Link style={{ fontFamily: "Montserrat" }} href={link}>
                      {link}
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Container>
      </Body>
    </Html>
  );
};

export default TemplateEmailForgot;
