import {
  EmailShare,
  FacebookShare,
  TwitterShare,
  WhatsappShare,
} from "react-share-kit";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const ShareButtons = ({
  url,
  title,
  cardTitle,
  top,
  left,
  right,
  position,
}) => {
  const socialClass = "mx-3";
  const round = true;
  const size = "48px";
  const [showLinks, setShowLinks] = useState(false);

  const handleShow = () => {
    setShowLinks(true);
  };

  const handleHide = () => {
    setShowLinks(false);
  };

  return (
    <div
      className="z-2"
      style={{
        position: position,
        top: top,
        right: right,
        left: left,
        textShadow: "0 0 10px white, 0 0 15px white , 0 0 20px white",
      }}
    >
      <Link
        onClick={handleShow}
        className="btn btn-secondary rounded-pill px-1 py-1"
      >
        <svg
          className="text-light"
          fill="currentColor"
          height="28px"
          width="28px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path d="M12,10c-.6012,0-1.13403,.27069-1.50067,.69055l-4.54468-2.27234c.02881-.13507,.04535-.2746,.04535-.41821,0-.14368-.01654-.28314-.04535-.41821l4.54468-2.2724c.36664,.4198,.89954,.69061,1.50067,.69061,1.10455,0,2-.89545,2-2,0-1.10461-.89545-2-2-2s-2,.89539-2,2c0,.14362,.01654,.28314,.04535,.41821l-4.54468,2.27234c-.36664-.41986-.89948-.69055-1.50067-.69055-1.10455,0-2,.89539-2,2,0,1.10455,.89545,2,2,2,.60114,0,1.13403-.27081,1.50067-.69061l4.54468,2.2724c-.02881,.13507-.04535,.27454-.04535,.41821,0,1.10455,.89545,2,2,2s2-.89545,2-2c0-1.10461-.89545-2-2-2Z"></path>{" "}
          </g>
        </svg>
      </Link>
      <Modal show={showLinks} centered={true} onHide={handleHide}>
        <Modal.Body className={"text-start w-100"}>
          <h5 className="mt-0 mb-3">{cardTitle}</h5>
          <div className="text-center">
            <span className={socialClass}>
              <EmailShare
                url={url}
                subject={"Don't miss this! - " + title}
                round={round}
                size={size}
              />
            </span>
            <span className={socialClass}>
              <TwitterShare url={url} title={title} round={round} size={size} />
            </span>
            <span className={socialClass}>
              <FacebookShare
                url={url}
                quote={title}
                round={round}
                size={size}
              />
            </span>
            <span className={socialClass}>
              <WhatsappShare
                url={url}
                title={title}
                separator=":: "
                round={round}
                size={size}
              />
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShareButtons;
