import { Image } from "components/Image";
import { useTranslation } from "react-i18next";
import styles from "./CarouselHome.module.css";
import Carousel from "react-bootstrap/Carousel";
import { CarouselCaption, CarouselItem } from "react-bootstrap";

function CarouselHome() {
  const { t } = useTranslation();
  return (
    <Carousel className="pt-4">
      <CarouselItem className={styles.carouselImage}>
        <Image
          height={500}
          className="d-block w-100 object-fit-cover opacity-100"
          src="/images/fotocarousel-1.jpeg"
          alt="First slide"
        />
        <CarouselCaption
          className="mb-md-5 mb-4 pb-3 pb-5 mx-md-5"
          style={{ left: 0, right: 0 }}
        >
          <h3
            className="display-3 fw-bold text-dark text-center mx-md-5"
            style={{
              textShadow:
                "0 0 10px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff",
            }}
          >
            {t(
              "We use Artificial Intelligence to obtain optimal results and lead in the development of our products"
            )}
          </h3>
          <p></p>
        </CarouselCaption>
      </CarouselItem>
      <CarouselItem className={styles.carouselImage}>
        <Image
          height={500}
          className="d-block w-100 object-fit-cover opacity-100"
          src="/images/fotocarousel-4.webp"
          alt="Second slide"
        />
        <CarouselCaption
          className="mb-md-5 mb-4 pb-3 pb-5 mx-md-5"
          style={{ left: 0, right: 0 }}
        >
          <h3
            className="display-3 fw-bold text-dark text-center mx-md-5"
            style={{
              textShadow:
                "0 0 10px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff",
            }}
          >
            {t(
              "Transform your vision and adapt your business to new trends; success is waiting for you"
            )}
          </h3>
          <p></p>
        </CarouselCaption>
      </CarouselItem>
      <CarouselItem className={styles.carouselImage}>
        <Image
          height={500}
          className="d-block w-100 object-fit-cover opacity-100"
          src="/images/fotocarousel-5.webp"
          alt="Third slide"
        />
        <CarouselCaption
          className="mb-md-5 mb-4 pb-3 pb-5 mx-md-5"
          style={{ left: 0, right: 0 }}
        >
          <h3
            className="display-3 fw-bold text-dark text-center mx-md-5"
            style={{
              textShadow:
                "0 0 10px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff",
            }}
          >
            {t(
              "Expand your reach and strengthen your business with our E-Commerce, created to achieve your goals"
            )}
          </h3>
          <p></p>
        </CarouselCaption>
      </CarouselItem>
    </Carousel>
  );
}

export default CarouselHome;
