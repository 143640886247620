export const FormInputs = [
  {
    type: "text",
    name: "instagram",
    value: "",
  },
  {
    type: "text",
    name: "twitter",
    value: "",
  },
  {
    type: "text",
    name: "facebook",
    value: "",
  },
  {
    type: "text",
    name: "whatsapp",
    value: "",
  },
  {
    type: "text",
    name: "tiktok",
    value: "",
  },
  {
    type: "email",
    name: "email",
    value: "",
  },
  {
    type: "textarea",
    name: "footer",
    value: "",
  },
];
  