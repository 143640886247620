import {
  Col,
  Row,
  Navbar,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Swal from "sweetalert2";
import {
  faUser,
  faSignOutAlt,
  faUserCog,
  faCopy,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { appLogout } from "helpers/helper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function TopBar(props) {
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const { data: customer, remove } = UserData();
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;

  let backgroundColor = "#5a5c69";
  let textColor = "#fff";

  if (props.backgroundColor) {
    backgroundColor = props.backgroundColor;
  }
  if (props.textColor) {
    textColor = props.textColor;
  }

  const baseUrl = `${window.location.origin}`;
  const fullUrl = `${baseUrl}/${
    customer?.data?.name?.toLowerCase()?.replace(/ /g, "_") || ""
  }`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(fullUrl).then(() => {
      Swal.fire({
        icon: "success",
        title: t("Store link successfully copied!"),
        showConfirmButton: false,
        timer: closeTime,
        background: "#e6ffe6",
        iconColor: "#1cc88a",
      });
    });
  };

  const logOut = (event) => {
    event.preventDefault();
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
  };

  const goToProfile = (event) => {
    event.preventDefault();
    setGoto("/admin/profile");
  };

  const dropDownTitle = (
    <span style={{ color: textColor }}>
      <OverlayTrigger
        placement="top"
        delay={{ hide: 100 }}
        overlay={
          <Tooltip>
            {customer && customer.data && customer.data.validated === 1
              ? t("verified")
              : t("not verified")}
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <span {...triggerHandler}>
            <FontAwesomeIcon icon={faUser} className="h5 my-0" />
            <small
              className={`h6 text-${
                customer && customer.data && customer.data.validated === 1
                  ? "primary"
                  : "danger"
              } me-2`}
              ref={ref}
            >
              <FontAwesomeIcon
                icon={
                  customer && customer.data && customer.data.validated === 1
                    ? faCircleCheck
                    : faCircleXmark
                }
                className="mb-2"
              />
            </small>
            <span className="h5">{customer?.data?.name}</span>
          </span>
        )}
      </OverlayTrigger>
    </span>
  );

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ backgroundColor: backgroundColor }}
    >
      <Nav className="w-100 d-flex justify-content-between align-items-center flex-wrap">
        <Row className="align-items-center bg-warning p-2 rounded">
          <Col className="p-0 m-0">
            <span className="text-dark mx-2 text-truncate">{fullUrl}</span>
          </Col>
          <Col className="p-0 m-0">
            <button
              onClick={copyToClipboard}
              className="btn btn-success text-white"
            >
              <FontAwesomeIcon icon={faCopy} /> {t("Store link")}
            </button>
          </Col>
        </Row>
        <NavDropdown
          align="end"
          title={dropDownTitle}
          id="collapsible-nav-dropdown"
          className="me-1"
          style={{ textDecoration: "none" }}
        >
          <NavDropdown.Item onClick={goToProfile}>
            {t("Profile")} <FontAwesomeIcon icon={faUserCog} />
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/logout" onClick={logOut}>
            {t("Logout")} <FontAwesomeIcon icon={faSignOutAlt} />
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
}

export default TopBar;
