import Cart from "components/client/cart/Cart";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CartData } from "helpers/ClientHelper";
import { ClientView } from "views/client/Client";
import { Container, Row } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";

const ViewCart = ({ customer, customerPath }) => {
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const { data, refetch } = CartData(customer.id);

  const updateCart = useCallback(() => {
    if (customer) refetch();
  }, [customer, refetch]);

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Container className=" text-center">
        <Row className="my-3">
          <Cart
            title={<h4 className="text-start">{t("Your shopping cart")}</h4>}
            cart={data?.data}
            unitPrice={unitPrice}
            refetch={updateCart}
            customer={customer}
            customerPath={customerPath}
          />
        </Row>
      </Container>
    </ClientView>
  );
};

export default ViewCart;
