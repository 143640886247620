export const TableMapping = {
  // column: data
  ID: {
    property: "id",
    transform: "index",
    sortable: true,
  },
  Title: {
    property: "title",
    transform: false,
    sortable: true,
  },
  Description: {
    property: "description",
    transform: false,
    sortable: true,
  },
  Image: {
    property: "image",
    transform: "link",
    sortable: true,
  },
};
