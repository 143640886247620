import styled from "styled-components";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getPaddingLeft = (level, type) => {
  let paddingLeft = level * 20;
  if (type === "file") paddingLeft += 20;
  return paddingLeft;
};

const StyledTreeNode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  padding-left: ${(props) => getPaddingLeft(props.level, props.type)}px;

  &:hover {
    background: lightgray;
  }
`;

const NodeIcon = styled.div`
  font-size: 12px;
  margin-right: ${(props) => (props.marginright ? props.marginright : 5)}px;
`;

const TreeTableNode = (props) => {
  const { t } = useTranslation();
  const { node, level = 0, className, action } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [goto, setGoto] = useState();
  const navigate = useNavigate();

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const actionButton = (object, button) => {
    if (button.callback) {
      button.callback(object.id, object);
    } else {
      if (!button || !button.url) {
        return;
      }
      let url = button.url;
      if (button.addId) {
        url += "/" + object.id;
      }
      setGoto(url);
    }
    return;
  };

  const getActionButtons = (node, action) => {
    const actionKeys = Object.keys(action);
    if (actionKeys.length === 1) {
      let x = Math.floor(Math.random() * 10000 + 1);
      return (
        <Button
          key={x}
          onClick={(e) => actionButton(node, action[actionKeys[0]])}
        >
          {" "}
          {t(actionKeys[0])}{" "}
        </Button>
      );
    } else {
      let actionButtons = [];
      for (const key in action) {
        const element = action[key];
        let x = Math.floor(Math.random() * 10000 + 1);
        actionButtons.push(
          <Dropdown.Item
            key={key + x}
            onClick={(e) => actionButton(node, element)}
          >
            {" "}
            {t(key)}{" "}
          </Dropdown.Item>
        );
      }
      return (
        <Dropdown data-tag="allowRowEvents" className="d-inline ms-3">
          <Dropdown.Toggle variant="warning" size="sm">
            {" "}
            {t("Action")}{" "}
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark" className="position-fixed">
            {actionButtons}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <div
      className={
        (className ? className : "") +
        " border-top border-bottom" +
        (node.selected ? "bg-primary" : "")
      }
    >
      <StyledTreeNode level={level} type={node.type}>
        <Row className="w-100 mx-0 px-0">
          <Col className="text-start mx-0 px-0 d-inline">
            <div
              className="d-inline my-0 py-2"
              role="button"
              onClick={() => onToggle(node)}
            >
              <NodeIcon className="d-inline">
                {isOpen ? (
                  <FontAwesomeIcon icon={faChevronDown} />
                ) : (
                  <FontAwesomeIcon icon={faChevronRight} />
                )}
              </NodeIcon>
              {node.name}
            </div>
          </Col>
          <Col className="text-end">
            {action && getActionButtons(node, action)}
          </Col>
        </Row>
      </StyledTreeNode>

      {isOpen &&
        node.children.map((childNode) => (
          <TreeTableNode
            key={childNode.id}
            {...props}
            node={childNode}
            level={level + 1}
          />
        ))}
    </div>
  );
};

export default TreeTableNode;
