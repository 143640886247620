import { isLightColor } from "helpers/functionsHelper";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl, InputGroup } from "react-bootstrap";

const SearchBar = ({
  className,
  handleClick,
  handleChange,
  style,
  btnColor,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleClick();
  };
  const isBtnColorLight = isLightColor(btnColor);

  return (
    <div className={className} style={style}>
      <InputGroup className="ms-0">
        <FormControl
          placeholder="Search"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="ms-0"
        />
        <Button
          style={{ backgroundColor: btnColor, borderColor: btnColor }}
          className="ms-0"
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={faSearch}
            style={{ color: isBtnColorLight ? "black" : "white" }}
          />
        </Button>
      </InputGroup>
    </div>
  );
};

export default SearchBar;
