import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Form } from "components/form/Form";
import { FormInputs } from "./data/FormInputs";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { FormMapping } from "./data/FormMapping";
import { Container, Row, Col } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

const ProductForm = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  let { id } = useParams();
  const { remove } = UserData();
  const [loaded, setLoaded] = useState(false);

  let formAction = t("Create Product");
  let labelPrefix = "";
  let buttonText = t("Store");

  let target = useMemo(
    () => ({
      method: id ? "PUT" : "POST",
      uri: "product" + (id ? "/" + id : ""),
      redirectUrl: "/admin/product",
    }),
    [id]
  );

  if (id) {
    formAction = t("Edit Product ");
    buttonText = t("Update");
  }

  const loadContent = useCallback(
    (values) => {
      const handleShow = (error) => {
        const message = error?.response?.data?.message;
        if (message && message.includes("products_code_user_id_unique")) {
          Swal.fire({
            icon: "error",
            title: t("Product code allready exists."),
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("An error has occurred."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then((result) => {
            remove();
            appLogout().then(() => {
              setGoto("/login");
            });
          });
        }
      };

      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{formAction}</h4>
                <Form
                  inputs={FormInputs}
                  labelPrefix={labelPrefix}
                  buttonText={buttonText}
                  values={values}
                  target={target}
                  errorCallback={handleShow}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    },
    [buttonText, formAction, labelPrefix, target, closeTime, remove, t]
  );

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      if (id) {
        let uri = "product/" + id;
        apiRequestUri(uri, "GET", {}, false)
          .then((response) => {
            let values = response;
            for (const key in FormMapping) {
              const element = FormMapping[key];
              values[key] = response[element];
            }
            loadContent(values);
          })
          .catch((error) => {
            loadContent({});
          });
      } else {
        loadContent({});
      }
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [loaded, id, loadContent, goto, navigate]);
  const [content, setContent] = useState(<LoadingSpinner />);
  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
export default ProductForm;
