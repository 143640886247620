import React from "react";
import {
  Html,
  Head,
  Body,
  Container,
  Heading,
  Text,
  Section,
  Button,
} from "@react-email/components";
import { Image } from "components/Image";

const TemplateEmailQuestions = ({
  productTitle,
  question,
  name,
  email,
  customer,
  t,
}) => {
  return (
    <Html>
      <Head />
      <Body style={main}>
        <Container style={container}>
          <Section style={headerSection}>
            <Image
              src="https://webcomprehensive.com/logo.png"
              alt="Logo"
              style={logo}
            />
            <Heading style={heading}>
              {t("You have a question in your store")}
            </Heading>
          </Section>
          <Text style={paragraph}>
            {t("Hello")}, {customer.name} 👋
          </Text>
          <Text style={paragraph}>
            {t(
              "You have received a new question in your store. The question details are as follows"
            )}
            :
          </Text>
          <Section style={section}>
            <Text style={paragraph}>
              <strong>{t("User name")}:</strong> {name}
            </Text>
            <Text style={paragraph}>
              <strong>{t("User email")}:</strong> {email}
            </Text>
            <Text style={paragraph}>
              <strong>{t("Product")}:</strong> {productTitle}
            </Text>
            <Text style={paragraph}>
              <strong>{t("Question")}:</strong> {question}
            </Text>
          </Section>
          <Button style={button} href="http://localhost:2000/admin/question">
            {t("See Question")}
          </Button>
        </Container>
      </Body>
    </Html>
  );
};

export default TemplateEmailQuestions;

const main = {
  fontFamily: "Arial, sans-serif",
  backgroundColor: "#003366", // Azul oscuro
  padding: "20px",
};

const container = {
  maxWidth: "600px",
  margin: "0 auto",
  backgroundColor: "#4b0082", // Violeta oscuro
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  position: "relative",
};

const headerSection = {
  position: "relative",
  marginBottom: "20px",
  backgroundColor: "#030303", // Negro
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)", // Sombra blanca
};

const heading = {
  fontSize: "24px",
  margin: "0",
  textAlign: "center",
  color: "#ffffff", // Blanco
  fontWeight: "bold", // Negrita
  textShadow:
    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000", // Contorno negro
};

const logo = {
  position: "absolute",
  top: "10px",
  right: "10px",
  height: "80px",
};

const paragraph = {
  fontSize: "16px",
  lineHeight: "1.5",
  marginBottom: "20px",
  color: "#ffffff", // Blanco
  fontWeight: "bold", // Negrita
  textShadow:
    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000", // Contorno negro
};

const section = {
  borderBottom: "1px solid #eeeeee",
  paddingBottom: "10px",
  marginBottom: "20px",
  backgroundColor: "#030303", // Negro
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)", // Sombra blanca
};

const button = {
  display: "block",
  width: "100%",
  padding: "15px 0",
  backgroundColor: "#007bff",
  color: "#ffffff",
  textDecoration: "none",
  textAlign: "center",
  borderRadius: "5px",
  fontWeight: "bold", // Negrita
};
