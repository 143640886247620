import { Rating } from "@mui/material";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { apiRequestUri } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingSpinner } from "components/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PaymentShow = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: customer } = UserData();
  let { id } = useParams();
  const [content, setContent] = useState(<LoadingSpinner />);
  const cardTitle = t("Payment for ");
  useEffect(() => {
    let uri = "platform_payment/" + id;
    if (customer && customer.data && customer.role_name !== "admin") {
      navigate("/admin");
      return;
    }
    apiRequestUri(uri, "GET", {}, false)
      .then((response) => {
        setContent(
          <Row className="justify-content-center">
            <Col md="6">
              <div className="card">
                <div className="card-body m-3 text-start">
                  <h4 className="text-center m-3 mb-4">
                    {cardTitle + response.user_name}
                  </h4>
                  <div>
                    <strong>{t("User Name")}: </strong>
                    {response.user_name}
                  </div>
                  <div>
                    <strong>{t("User Email")}: </strong>
                    {response.user_email}
                  </div>
                  <div>
                    <strong>{t("User Role")}: </strong>
                    {response.user.role_name}
                  </div>
                  <div>
                    <strong>{t("User Rating")}:&nbsp;</strong>
                    <span>
                      {Number(response.user.rating)}/5&nbsp;
                      <Rating
                        readOnly
                        defaultValue={response.user.rating}
                        size="large"
                      />
                    </span>
                  </div>
                  <div>
                    <strong>{t("User Status")}:&nbsp;</strong>
                    {!response.user.deleted ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="text-success"
                        />
                        &nbsp;{t("ACTIVE")}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          className="text-danger"
                        />
                        &nbsp;{t("DELETED")}
                      </>
                    )}
                  </div>
                  <div>
                    <strong>{t("Payment Status")}: </strong>
                    {response.status === "payed" ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="text-success"
                        />
                        &nbsp;{t("PAYED")}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          className="text-danger"
                        />
                        &nbsp;{t("NEED PAY")}
                      </>
                    )}
                  </div>
                  <div>
                    <strong>{t("Subscribed Days")}: </strong>
                    {response.subscribed_days}
                  </div>
                  <div>
                    <strong>{t("Subscribed Months")}: </strong>
                    {response.subscribed_months}
                  </div>
                  <div>
                    <strong>{t("Payments")}: </strong>
                    {response.payments}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      })
      .catch((error) => {});
  }, [customer, id, navigate, cardTitle, t]);
  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
