import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { AddressItem } from "components/address/AddressItem";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

export const AdminAddress = ({ className }) => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const { data: user, refetch, remove } = UserData();
  const navigate = useNavigate();
  const [content, setContent] = useState(<LoadingSpinner />);
  const [deleteButton, setDeleteButton] = useState(t("Delete"));
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  const loadContent = useCallback(() => {
    const handleDeleteAddress = (id) => {
      let uri = "address/" + id;
      setDeleteButton(<LoadingSpinner />);
      apiRequestUri(uri, "DELETE", {}, false)
        .then((response) => {
          refetch().then(() => {
            Swal.fire({
              icon: "warning",
              title: t("Address deleted successfully."),
              showConfirmButton: false,
              timer: closeTime,
              timerProgressBar: true,
            });
            loadContent();
            setDeleteButton(t("Delete"));
          });
        })
        .catch((error) => {
          remove();
          appLogout().then(() => {
            setGoto("/login");
          });
        });
    };

    let addresses = [];
    for (const item of user.data.addresses) {
      addresses.push(
        <div key={item.id}>
          <hr />
          <Row>
            <Col>
              <Card.Text>
                <AddressItem item={item} />
              </Card.Text>
            </Col>
            <Col className="text-end">
              <Button
                variant="danger"
                onClick={() => handleDeleteAddress(item.id)}
              >
                {deleteButton}
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
    setContent(
      <Container className="my-5 text-center">
        <Row>
          <Col className="mb-3">
            <h4>{t("Addresses")}</h4>
          </Col>
          <Col className="text-end">
            <Link className="btn btn-primary" to="/admin/config/address/create">
              {t("Add")}
            </Link>
          </Col>
        </Row>
        <Row className="text-start">
          <Container>{addresses}</Container>
        </Row>
      </Container>
    );
  }, [deleteButton, user, closeTime, refetch, remove, t]);

  useEffect(() => {
    if (!loaded && user && user.data && user.data.name) {
      setLoaded(true);
      loadContent();
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [loaded, user, loadContent, goto, navigate]);

  return <div className={className}>{content}</div>;
};
