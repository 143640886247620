import { Image } from "components/Image";
import { ClientView } from "views/client/Client";
import { Col, Container, Row } from "react-bootstrap";
import { getEnhancedText } from "helpers/functionsHelper";

export const ClientAbout = ({ customerPath, customer }) => {
  const safeHTML = getEnhancedText(customer.data.about.text);
  return (
    <ClientView customer={customer} customerPath={customerPath}>
      <Container
        style={{ minHeight: "75vh" }}
        className="d-flex align-items-center"
      >
        <Row className="w-100 my-md-5 mt-0">
          <Col xs={12} md={6} className="my-md-auto mt-0">
            <Image
              className="position-sm-absolute z-1"
              src={customer.data.about.image}
              alt="about"
              width={"100%"}
            />
          </Col>
          <Col xs={12} md={6} className="my-auto">
            <div className="" dangerouslySetInnerHTML={{ __html: safeHTML }} />
          </Col>
        </Row>
      </Container>
    </ClientView>
  );
};
