import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Form } from "components/form/Form";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Container, Row, Col } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { FormInputs } from "./data/FormCarouselInputs";
import { FormMapping } from "./data/FormCarouselMapping";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

const AdminCarouselForm = () => {
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  let { id } = useParams();
  const { data: customer, refetch: refetchCustomer, remove } = UserData();
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  let formAction = t("Create Item");
  let labelPrefix = "";
  let buttonText = t("Store");

  let target = useMemo(
    () => ({
      method: "PUT",
      uri: "user/",
      redirectUrl: "/admin/config/carousel",
    }),
    []
  );

  if (id) {
    formAction = t("Edit Item");
    buttonText = t("Update");
  }
  const navigate = useNavigate();
  const [content, setContent] = useState(<LoadingSpinner />);
  const handleShow = useCallback(() => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then((result) => {
      remove();
      appLogout().then(() => {
        setGoto("/login");
      });
    });
  }, [remove, closeTime, t]);

  const loadContent = useCallback(
    (values, onSubmit) => {
      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{formAction}</h4>
                <Form
                  inputs={FormInputs}
                  labelPrefix={labelPrefix}
                  buttonText={buttonText}
                  values={values}
                  target={target}
                  submitCallback={onSubmit}
                  errorCallback={handleShow}
                  update={id ? true : false}
                  footer={t(
                    "When you resize the screen, the image will adapt and be cropped to maintain the aesthetics of the page."
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    },
    [buttonText, formAction, handleShow, id, labelPrefix, target, t]
  );

  const onSubmit = useCallback(
    (formState, event) => {
      refetchCustomer().then((response) => {
        if (!response || !response.data) {
          handleShow();
          return;
        }
        let newData = response.data.data.data;
        if (!newData) {
          newData = {};
        }
        if (!Object.hasOwnProperty.call(newData, "carousel")) {
          newData["carousel"] = [];
        }
        const file = formState.image
          ? formState.image
          : event.target.image.files[0];
        if (file) {
          let uri = "file";
          let formData = new FormData();
          formData.append("file", file);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
              Accept: "application/json",
            },
          };
          apiRequestUri(uri, "POST", formData, config)
            .then((response) => {
              formState["image"] = response.url;
              if (id) {
                newData.carousel[id] = formState;
              } else {
                newData.carousel.push(formState);
              }
              apiRequestUri(target.uri, target.method, { data: newData }, false)
                .then((response) => {
                  refetchCustomer().then(() => {
                    setGoto(target.redirectUrl);
                    return;
                  });
                })
                .catch((error) => {
                  handleShow();
                });
            })
            .catch((error) => {
              handleShow();
            });
        } else {
          if (id) {
            newData.carousel[id] = formState;
          } else {
            newData.carousel.push(formState);
          }
          refetchCustomer().then((response) => {
            newData.carousel[id]["image"] =
              response.data.data.data.carousel[id]["image"];
            apiRequestUri(target.uri, target.method, { data: newData }, false)
              .then((response) => {
                refetchCustomer().then(() => {
                  setGoto(target.redirectUrl);
                  return;
                });
              })
              .catch((error) => {
                handleShow();
              });
          });
        }
      });
    },
    [
      handleShow,
      refetchCustomer,
      target.method,
      target.uri,
      id,
      target.redirectUrl,
    ]
  );

  useEffect(() => {
    if (!loaded && customer) {
      setLoaded(true);
      if (id) {
        let values = customer.data.data.carousel[id];
        for (const key in FormMapping) {
          const element = FormMapping[key];
          values[key] = customer.data.data.carousel[id][element];
        }
        // values["image"] = "";
        refetchCustomer();
        loadContent(values, onSubmit);
      } else {
        refetchCustomer();
        loadContent({}, onSubmit);
      }
      target.uri = "user/" + customer.data.id;
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    loaded,
    customer,
    id,
    loadContent,
    refetchCustomer,
    target,
    goto,
    navigate,
    onSubmit,
  ]);

  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
export default AdminCarouselForm;
