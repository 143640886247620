import { useState } from "react";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function CustomNavLink({
  link,
  linkStyle,
  textColor,
  textShadowStyle,
  dropdown,
}) {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    setHover(!hover);
  };

  const topbarLinksTitle = (
    <span
      style={{ color: textColor, ...textShadowStyle }}
      className={dropdown ? "ms-2" : ""}
      onClick={handleClick}
    >
      {t(link.text)}
    </span>
  );

  const styles = `
    .navbar-nav .dropdown-menu{
      position: absolute !important;
    }
  `;

  return (
    <>
      {link.dropdown ? (
        <>
          <style>{styles}</style>
          <NavDropdown
            key={crypto.randomUUID() + link.text}
            title={topbarLinksTitle}
            className="ms-md-auto ms-3"
            style={linkStyle}
            show={hover}
            drop={dropdown ? "end" : "down"}
          >
            {link.dropdown.map((dropdownLink) => (
              <CustomNavLink
                key={crypto.randomUUID() + link.text}
                link={dropdownLink}
                linkStyle={linkStyle}
                textColor={textColor}
                textShadowStyle={textShadowStyle}
                dropdown
              />
            ))}
          </NavDropdown>
        </>
      ) : (
        <Link
          key={link.to}
          to={link.to}
          className={dropdown ? "dropdown-item" : "nav-link"}
          style={linkStyle}
        >
          {t(link.text)}
        </Link>
      )}
    </>
  );
}
