import Swal from "sweetalert2";
import { Table } from "components/Table";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { TableMapping } from "./data/TableMapping";
import React, { useEffect, useState } from "react";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

export const OrderTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [goto, setGoto] = useState();
  const [loading, setLoading] = useState(false);
  const { data: customer, remove, refetch } = UserData();
  let uri = "order?customer_id=" + (customer?.data?.id || "");

  const deleteItem = (id) => {
    Swal.fire({
      icon: "warning",
      title: t("Warning!"),
      text: t("Are you sure you want to delete the order ") + id,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("No"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        let uri = "order/" + id;
        apiRequestUri(uri, "DELETE", {}, false)
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            remove();
            appLogout().then(() => {
              setGoto("/login");
            });
          });
      }
    });
  };

  let action = {
    Show: {
      url: "/admin/order",
      addId: true,
    },
    Edit: {
      url: "/admin/order/edit",
      addId: true,
    },
    Delete: {
      callback: deleteItem,
    },
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  if (!customer || !customer.data) {
    refetch().then((response) => {
      if (!response.data) {
        remove();
        appLogout().then(() => {
          setGoto("/login");
        });
        return;
      }
    });
  }

  return (
    <Container className="mt-0 mb-5 text-center mx-0 mw-100">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Table
          title={t("Orders")}
          mapping={TableMapping}
          action={action}
          uri={uri}
        />
      )}
    </Container>
  );
};

export default OrderTable;
