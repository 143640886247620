export const TableMapping = {
  // column: data
  ID: {
    property: "id",
    sortable: true,
    transform: false,
  },
  Code: {
    property: "code",
    sortable: true,
    transform: false,
  },
  Title: {
    property: "title",
    sortable: true,
    transform: false,
  },
  Brand: {
    property: "brand_name",
    sortable: true,
    transform: false,
  },
  Category: {
    property: "category_name",
    sortable: true,
    transform: false,
  },
  Featured: {
    property: "featured",
    sortable: true,
    transform: "check",
  },
  Published: {
    property: "published",
    sortable: true,
    transform: "check",
  },
  Price: {
    property: "price",
    sortable: true,
    transform: "price",
  },
  PreviousPrice: {
    property: "previous_price",
    sortable: true,
    transform: "price",
  },
  Color: {
    property: "color",
    sortable: true,
    transform: false,
  },
  Stock: {
    property: "stock",
    sortable: true,
    transform: false,
  },
  Updated: {
    property: "updated_at",
    sortable: true,
    transform: "date",
  },
};
