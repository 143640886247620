import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ClientView } from "views/client/Client";
import { Col, Container, Row } from "react-bootstrap";

export const ClientRefund = ({ customer, customerPath }) => {
  const { t } = useTranslation();
  let contact;
  if (customer.data && customer.data.contact) {
    contact = customer.data.contact;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ClientView
      customer={customer}
      customerPath={customerPath}
      className={"bg-light"}
    >
      <Row className="text-center pt-md-5 mx-md-5 px-md-5 mb-5 pb-5">
        <h1 className="display-4 fw-bold mt-5 pt-5 mb-1">
          {customer.name}
          <br />
          {t("Refund Policy")}
        </h1>
        <p className="mb-5">
          {t("Last revised: ")}
          {customer.created_at.split("T")[0]}
        </p>
        <Container>
          <Col className="text-start">
            <p className="text-center">
              {t("Welcome to *")}
              {customer.name}
              {t(
                "'s Refund Policy! Thank you for choosing to use our services."
              )}
            </p>
            <h3 className="fw-bold pb-2 mt-5 pt-5">{t("RETURNS")}</h3>
            <hr />
            <p>
              {t(
                "Our policy lasts 30 days. If 30 days have passed since your purchase, unfortunately we cannot offer you a refund or exchange."
              )}
              <br />
              {t(
                "To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging."
              )}
              <br />
              <br />
              {t("Additional items that cannot be returned:")}
              <ul>
                <li>{t("Gift Cards")}</li>
                <li>{t("Downloadable software products")}</li>
                <li>{t("Some health and personal care items")}</li>
              </ul>
              {t(
                "To complete your return, we require a receipt or proof of purchase."
              )}
              <br />
              {t("Do not return your purchase to the manufacturer.")}
              <br />
              <br />
              {t(
                "There are certain situations where only partial refunds are granted:"
              )}
              <br />
              <ul>
                <li>{t("Book with obvious signs of use")}</li>
                <li>
                  {t(
                    "CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that has been opened."
                  )}
                </li>
                <li>
                  {t("Any item returned more than 30 days after delivery.")}
                </li>
              </ul>
            </p>
            <h3 className="fw-bold pb-2 mt-5 pt-5">{t("Refunds")}</h3>
            <hr />
            <p>
              {t(
                "Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund."
              )}
              <br />
              {t(
                "If approved, your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days."
              )}
            </p>
            <h3 className="fw-bold pb-2 mt-5 pt-5">
              {t("Late or missing refunds")}
            </h3>
            <hr />
            <p>
              {t(
                "If you haven't received a refund yet, first check your bank account again."
              )}
              <br />
              {t(
                "Next, contact your credit card company. It may take some time before your refund is officially posted."
              )}
              <br />
              {t(
                "Next, contact your bank. There is often a processing time required before a refund can be posted."
              )}
              <br />
              {contact.email && (
                <>
                  {t(
                    "If you have done all of the above and you still have not received your refund, you can contact us at "
                  )}
                  {contact.email}.
                </>
              )}
            </p>
            <h3 className="fw-bold pb-2 mt-5 pt-5">{t("Items on sale")}</h3>
            <hr />
            <p>
              {t(
                "Only regular priced items may be refunded. Unfortunately, sale items cannot be refunded."
              )}
            </p>
            {contact.email && (
              <>
                <h3 className="fw-bold pb-2 mt-5 pt-5">{t("Changes")}</h3>
                <hr />
                <p>
                  {t(
                    "We only replace items if they are defective or damaged. If you need to exchange it for the same item, please email us at "
                  )}
                  {contact.email}.
                </p>
              </>
            )}
            <h3 className="fw-bold pb-2 mt-5 pt-5">{t("Gifts")}</h3>
            <hr />
            <p>
              {t(
                "If the item was marked as a gift when purchased and we shipped it directly to you, you'll receive a gift credit for the value of your return. Once we receive the returned item, a gift certificate will be mailed to you."
              )}
              <br />
              <br />
              {t(
                "If the item wasn't marked as a gift when purchased, or the gift giver chose to have the order shipped to themselves and then give it to you, we will send a refund to the gift giver and he or she will find out about your return."
              )}
            </p>
            {contact.email && (
              <>
                <h3 className="fw-bold pb-2 mt-5 pt-5">{t("Shipment")}</h3>
                <hr />
                <p>
                  {t(
                    "To return your product, you can contact us by writing to "
                  )}
                  {contact.email}.
                  <br />
                  <br />
                  {t(
                    "Shipping costs for returning your item are your responsibility. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund."
                  )}
                  <br />
                  <br />
                  {t(
                    "Depending on where you live, the time it may take to receive your exchanged product may vary."
                  )}
                  <br />
                  <br />
                  {t(
                    "It is recommended to use a trackable shipping service or purchase shipping insurance. We do not guarantee that we will receive your returned item."
                  )}
                </p>
              </>
            )}
          </Col>
        </Container>
      </Row>
    </ClientView>
  );
};
