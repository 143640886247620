import {
  Col,
  Row,
  Dropdown,
  Container,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { transformData } from "../helpers/transformData";

export const JsonTable = ({
  mapping,
  title,
  action,
  perPage,
  titleElement,
  reorderRows,
  initialData,
}) => {
  if (!titleElement) titleElement = [];

  const [data, setData] = useState(initialData ? initialData : undefined);
  const [oldData, setOldData] = useState([]);
  const [goto, setGoto] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const transformedData = transformData(data, mapping, t);
      setData(transformedData);
      setOldData(transformedData);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [data, mapping, goto, t, navigate]);

  const actionButton = (object, button) => {
    if (button.callback) {
      button.callback(object.id);
    } else {
      if (!button || !button.url) {
        return;
      }
      let url = button.url;
      if (button.addId) {
        url += "/" + object.id;
      }
      setGoto(url);
    }
    return;
  };

  let columns = [];

  if (mapping) {
    for (const key in mapping) {
      columns.push({
        name: t(key),
        selector: (row) => row[mapping[key].property],
        sortField: mapping[key].property,
        sortable: mapping[key].sortable,
        minWidth: "160px",
      });
    }
    if (action) {
      columns.push({
        cell: (object) => {
          let actionButtons = [];
          for (const key in action) {
            const element = action[key];
            let x = Math.floor(Math.random() * 10000 + 1);
            actionButtons.push(
              <Dropdown.Item
                key={key + x}
                onClick={(e) => actionButton(object, element)}
              >
                {" "}
                {t(key)}{" "}
              </Dropdown.Item>
            );
          }
          return (
            <Dropdown data-tag="allowRowEvents">
              <Dropdown.Toggle variant="warning">
                {" "}
                {t("Action")}{" "}
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark" className="position-fixed">
                {actionButtons}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      });
    }
  }

  if (reorderRows) {
    columns.push({
      cell: (object) => {
        return (
          <ButtonGroup>
            <Button
              size="sm"
              variant="outline-info"
              onClick={(e) => reorderRows(object, "up", data, setData)}
            >
              ▲
            </Button>
            <Button
              size="sm"
              variant="outline-info"
              onClick={(e) => reorderRows(object, "down", data, setData)}
            >
              ▼
            </Button>
          </ButtonGroup>
        );
      },
    });
  }

  const onRowClicked = (row, event) => {
    actionButton(event, row.id);
  };

  const handleSearch = (e) => {
    let searchValues = {};
    const newRows = oldData.filter((row) => {
      for (const key in mapping) {
        const element = mapping[key].property;
        searchValues[element] = row[element]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      }
      for (const key in mapping) {
        const element = mapping[key].property;
        if (searchValues[element]) {
          return searchValues[element];
        }
      }
      return false;
    });
    setData(newRows);
  };

  return (
    <Container className="mw-100">
      <Row>
        <Col>
          <h4>{title}</h4>
        </Col>
        <Col className="text-end">
          <input
            id="searchInput"
            type="search"
            className="form-control-sm border ps-3 m-3"
            placeholder="Search"
            onChange={handleSearch}
            onLoad={handleSearch}
          />
          {titleElement}
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={data}
        onRowClicked={onRowClicked}
        // progressPending={loading}
        // progressComponent={<LoadingSpinner />}
        dense
        pagination
        // paginationServer
        // paginationTotalRows={totalRows}
        // onChangeRowsPerPage={handlePerRowsChange}
        // onChangePage={handlePageChange}
        // onSort={handleSort}
        // sortServer
        // selectableRows
      />
    </Container>
  );
};

export default JsonTable;
