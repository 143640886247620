import {
  Col,
  Row,
  Dropdown,
  Container,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiRequestUri } from "helpers/helper";
import { LoadingSpinner } from "./LoadingSpinner";
import { transformData } from "helpers/transformData";
import React, { useCallback, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";

export const Table = (props) => {
  let mapping = props.mapping;
  let title = props.title;
  let action = props.action;
  const className = props.className;
  const backgroundColor = props.backgroundColor;
  let uri = props.uri;
  let initialPerPage = 10;
  let titleElement = [];
  const reorderRows = props.reorderRows;
  if (props.perPage) initialPerPage = props.perPage;
  if (props.titleElement) titleElement = props.titleElement;

  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [initialFetch, setInitialFetch] = useState(true);
  const [sortField, setSortField] = useState("id");
  const [direction, setDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [goto, setGoto] = useState();
  const { t } = useTranslation();

  createTheme(
    "customized",
    {
      text: {
        primary: "black",
        secondary: "black",
      },
      button: {
        default: "black",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(0, 0, 0, .34)",
      },
      background: {
        default: backgroundColor,
      },
    },
    "dark"
  );

  const actionButton = (object, button) => {
    if (button.callback) {
      button.callback(object.id, object);
    } else {
      if (!button || !button.url) {
        return;
      }
      let url = button.url;
      if (button.addId) {
        url += "/" + object.id;
      }
      setGoto(url);
    }
    return;
  };

  let columns = [];
  if (mapping) {
    for (const key in mapping) {
      columns.push({
        name: t(key),
        selector: (row) => row[mapping[key].property],
        sortField: mapping[key].property,
        sortable: mapping[key].sortable,
        minWidth: "160px",
      });
    }
    if (action) {
      const actionKeys = Object.keys(action);
      if (actionKeys.length === 1) {
        columns.push({
          cell: (object) => {
            let x = Math.floor(Math.random() * 10000 + 1);
            return (
              <Button
                key={x}
                onClick={(e) => actionButton(object, action[actionKeys[0]])}
              >
                {" "}
                {t(actionKeys[0])}{" "}
              </Button>
            );
          },
        });
      } else {
        columns.push({
          cell: (object) => {
            let actionButtons = [];
            for (const key in action) {
              const element = action[key];
              let x = Math.floor(Math.random() * 10000 + 1);
              actionButtons.push(
                <Dropdown.Item
                  key={key + x}
                  onClick={(e) => actionButton(object, element)}
                >
                  {" "}
                  {t(key)}{" "}
                </Dropdown.Item>
              );
            }
            return (
              <Dropdown data-tag="allowRowEvents">
                <Dropdown.Toggle variant="warning">
                  {" "}
                  {t("Action")}{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className="position-fixed">
                  {actionButtons}
                </Dropdown.Menu>
              </Dropdown>
            );
          },
        });
      }
    }
  }

  if (reorderRows) {
    columns.push({
      cell: (object) => {
        return (
          <ButtonGroup>
            <Button
              size="sm"
              variant="outline-info"
              onClick={(e) => reorderRows(object, "up", data, setData)}
            >
              ▲
            </Button>
            <Button
              size="sm"
              variant="outline-info"
              onClick={(e) => reorderRows(object, "down", data, setData)}
            >
              ▼
            </Button>
          </ButtonGroup>
        );
      },
    });
  }

  const onRowClicked = (row, event) => {
    actionButton(event, row.id);
  };

  const handleSearch = (e) => {
    let searchValues = {};
    const newRows = oldData.filter((row) => {
      for (const key in mapping) {
        const element = mapping[key].property;
        if (row[element]) {
          searchValues[element] = row[element]
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        } else {
          searchValues[element] = null;
        }
      }
      for (const key in mapping) {
        const element = mapping[key].property;
        if (searchValues[element]) {
          return searchValues[element];
        }
      }
      return false;
    });
    setData(newRows);
  };

  const fetchData = useCallback(
    async (page) => {
      let toAdd = "?";
      if (uri.includes(toAdd)) {
        toAdd = "&";
      }
      setLoading(true);
      return await apiRequestUri(
        uri +
          toAdd +
          `page=${page}&per_page=${perPage}&order_by=${sortField}&order_direction=${direction}`
      )
        .then((response) => {
          let responseData = transformData(response.data, mapping, t);
          setData(responseData);
          setOldData(responseData);
          setTotalRows(response.meta.total);
          setCurrentPage(page);
          setLoading(false);
          setInitialFetch(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    [uri, direction, mapping, perPage, sortField, t]
  );

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    if (initialFetch) return;
    let toAdd = "?";
    if (uri.includes(toAdd)) {
      toAdd = "&";
    }
    setLoading(true);
    const response = await apiRequestUri(
      uri +
        toAdd +
        `page=${page}&per_page=${newPerPage}&order_by=${sortField}&order_direction=${direction}`
    );
    if (!response) {
      setGoto("/login");
      return;
    }
    let responseData = transformData(response.data, mapping, t);
    setData(responseData);
    setOldData(responseData);
    setPerPage(newPerPage);
    setCurrentPage(page);
    setLoading(false);
  };

  const handleSort = async (column, sortDirection) => {
    if (!column.sortField) {
      return;
    }
    let toAdd = "?";
    if (uri.includes(toAdd)) {
      toAdd = "&";
    }
    setLoading(true);
    return await apiRequestUri(
      uri +
        toAdd +
        `page=${currentPage}&per_page=${perPage}&order_by=${column.sortField}&order_direction=${sortDirection}`
    )
      .then((response) => {
        let responseData = transformData(response.data, mapping, t);
        setData(responseData);
        setOldData(responseData);
        setTotalRows(response.meta.total);
        setSortField(column.sortField);
        setDirection(sortDirection);
        setLoading(false);
        setInitialFetch(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (initialFetch) {
      fetchData(1);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [data, fetchData, initialFetch, goto, navigate]);

  return (
    <Container
      className={"mw-100 " + className}
      style={{ backgroundColor: backgroundColor }}
    >
      <Row>
        <Col>
          <h4>{title}</h4>
        </Col>
        <Col className="text-end">
          <input
            id="searchInput"
            type="search"
            className="form-control-sm border ps-3 m-3"
            placeholder={t("Search")}
            onChange={handleSearch}
            onLoad={handleSearch}
          />
          {titleElement}
        </Col>
      </Row>
      <DataTable
        theme={backgroundColor ? "customized" : ""}
        columns={columns}
        data={data}
        onRowClicked={onRowClicked}
        progressPending={loading}
        progressComponent={<LoadingSpinner />}
        dense
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        sortServer
        // selectableRows
      />
    </Container>
  );
};

export default Table;
