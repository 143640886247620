import i18n from "i18next";
import { en } from "./languages/en";
import { es } from "./languages/es";
import { pt } from "./languages/pt";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: { translation: en },
  es: { translation: es },
  pt: { translation: pt },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["navigator"] },
    resources,
    fallbackLng: "en",
  });

export default i18n;
