import React from "react";
import { Link } from "react-router-dom";
import { Image } from "components/Image";
import { useTranslation } from "react-i18next";
import styles from "./DescriptionShop.module.css";
import { Container, Row, Col } from "react-bootstrap";
import FadeInSection from "../fadeInSection/FadeInSection";

const DescriptionShop = ({ reference }) => {
  const { t } = useTranslation();

  return (
    <section className={`${styles.section} pt-5 pb-5`} ref={reference}>
      <Container fluid>
        <Row className="mx- md-5 px-md-5">
          <h2 className={`ms mb-5 display-4 fw-bold`}>{t("Online Store!")}</h2>
          <Col className={`fs-4  mb-4 mb-md-0`}>
            <FadeInSection>
              <p>
                {t("At WebComprehensive, we offer you an ")}
                <strong>{t("online store")}</strong>
                {t(
                  " that will allow you to take your business to the next level without complications. Why choose us? Here we explain:"
                )}
              </p>
              <ul>
                <li>
                  <strong>{t("First 30 days FREE")}</strong>:{" "}
                  {t(
                    "Try our virtual store without any commitment. Start selling online and experience the potential of your business at no cost!"
                  )}
                </li>
                <li>
                  <strong>{t("Quick Creation")}</strong>:{" "}
                  {t(
                    "Launch your store in a matter of hours! No programming knowledge required. We take care of everything so you can focus on what you do best: selling."
                  )}
                </li>
                <li>
                  <strong>{t("Easy to Use")}</strong>:{" "}
                  {t(
                    "We design your virtual store with an intuitive and easy-to-use interface, so you can manage products, orders and customers without stress."
                  )}
                </li>
                {/* <li>
                <strong>{t("Cost of Service")}</strong>:{" "}
                {t(
                  "After the 30-day trial, the service will cost $3,500 per month. A competitive price for all the tools and support that we offer you."
                )}
              </li> */}
                <li>
                  <strong>{t("Technical Support")}</strong>:{" "}
                  {t(
                    "We will always be at your side to resolve any questions or problems that may arise, ensuring that your store is always operational."
                  )}
                </li>
              </ul>
              <p>
                {t(
                  "Don't wait any longer, start today and discover how a virtual store can transform your business. Register at"
                )}{" "}
                <Link className="fw-bold" to="/register">
                  store.webcomprehensive.com
                </Link>{" "}
                {t("and start selling online without complications.")}
              </p>
            </FadeInSection>
          </Col>
          <Col md={5} className="text-end">
            <FadeInSection>
              <Image
                src="images/resp.webp"
                alt="equipo responsable"
                className="img-fluid mb-3 w-100 "
              />
            </FadeInSection>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DescriptionShop;
