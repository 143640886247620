export const FormInputs = [
  {
    type: "select",
    name: "provider_id",
    value: "",
    options: [
      {
        text: "Manual",
        value: "1",
      },
      {
        text: "Mercado Pago",
        value: "2",
      },
    ],
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
  {
    type: "text",
    name: "access_token",
    value: "",
    additionalAttrs: {
      required: false,
    },
    fullWidth: true,
  },
];
