export const TableMapping = {
// column: data
  ID: {
    property: "id",
    sortable: true,
    transform: false,
  },
  Name: {
    property: "name",
    sortable: true,
    transform: false,
  },
};
