export const TableMapping = {
// column: data
  ID: {
    property: "id",
    sortable: true,
    transform: false,
  },
  UserName: {
    property: "user_name",
    sortable: true,
    transform: false,
  },
  UserEmail: {
    property: "user_email",
    sortable: true,
    transform: false,
  },
  Payments: {
    property: "payments",
    sortable: true,
    transform: false,
  },
  SubscribedDays: {
    property: "subscribed_days",
    sortable: true,
    transform: false,
  },
  SubscribedMonths: {
    property: "subscribed_months",
    sortable: true,
    transform: false,
  },
  Status: {
    property: "status",
    sortable: true,
    transform: false,
  },
  Created: {
    property: "created_at",
    sortable: true,
    transform: "date",
  },
  Updated: {
    property: "updated_at",
    sortable: true,
    transform: "date",
  },
};
