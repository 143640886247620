import CropInput from "./CropInput";
import ImageInput from "./ImageInput";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import TreeSelect from "components/tree/TreeSelect";

const FormInput = ({
  input,
  labelPrefix,
  onInputChange,
  formState,
  update,
  user,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(formState);

  useEffect(() => {
    if (!values) {
      setValues(formState);
    }
  }, [formState, values, setValues]);

  let label = input.name.charAt(0).toUpperCase() + input.name.slice(1);
  label = input.label ? input.label : label.split("_").join(" ");
  label = t(label);
  const editorRef = useRef(null);
  const editorHandleChange = (value, editor) => {
    onInputChange({
      target: {
        name: editor.id,
        value: value,
      },
    });
  };

  if (input.type === "select") {
    let options = [];
    options.push(
      <option key={"first-option" + input.name} value="">
        {t("Empty")}
      </option>
    );
    for (const item of input.options) {
      options.push(
        <option
          key={input.name + item.value}
          value={item.value}
          selected={formState[input.name] === item.value}
        >
          {item.text}
        </option>
      );
    }
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <select
        key={input.name}
        className="form-select"
        name={input.name}
        onChange={onInputChange}
        {...input.additionalAttrs}
      >
        {options}
      </select>,
    ];
  } else if (input.type === "textarea") {
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <textarea
        rows={5}
        key={input.name}
        className="form-control"
        name={input.name}
        onChange={onInputChange}
        {...input.additionalAttrs}
        value={formState[input.name] ? formState[input.name] : ""}
      ></textarea>,
    ];
  } else if (input.type === "editor") {
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <Editor
        key={input.name}
        name={input.name}
        id={input.name}
        apiKey="v6ex6pz0mjbj7hnl74yh1cx95jjv67z809htrkh6rmf5sqc3"
        onInit={(_evt, editor) => (editorRef.current = editor)}
        onEditorChange={editorHandleChange}
        initialValue={values[input.name] ? values[input.name] : ""}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />,
      <div className="py-2"></div>,
    ];
  } else if (input.type === "file") {
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <input
        key={input.name}
        id={input.name}
        type={input.type}
        className="form-control"
        name={input.name}
        value={formState[input.name] ? formState[input.name] : ""}
        onChange={onInputChange}
        {...(update ? "" : input.additionalAttrs)}
      />,
    ];
  } else if (input.type === "checkbox") {
    return [
      <div className="form-check mt-4 mb-3">
        <label
          key={"label" + input.name}
          htmlFor={input.name}
          className="form-check-label"
        >
          {labelPrefix + label}
        </label>
        <input
          key={input.name}
          id={input.name}
          type={input.type}
          className="form-check-input"
          name={input.name}
          checked={
            formState[input.name] &&
            (formState[input.name] === "on" || formState[input.name] === 1)
              ? true
              : false
          }
          onChange={(e) => {
            let element = e;
            element.target.value = e.target.checked ? "on" : "";
            onInputChange(element);
          }}
          {...input.additionalAttrs}
        />
      </div>,
    ];
  } else if (input.type === "color") {
    return [
      <Row className="mt-4 mb-3">
        <Col>
          <label key={"label" + input.name} htmlFor={input.name}>
            {labelPrefix + label}
          </label>
        </Col>
        <Col md={3}>
          <input
            key={input.name}
            id={input.name}
            type={input.type}
            className="form-control"
            name={input.name}
            value={formState[input.name] ? formState[input.name] : ""}
            onChange={onInputChange}
            {...input.additionalAttrs}
          />
        </Col>
      </Row>,
    ];
  } else if (input.type === "treeSelect") {
    let uri = input.uri;
    let toAdd = "?user_id=";
    if (uri.includes(toAdd)) {
      toAdd = "&user_id=";
    }
    uri = input.uri + (input.addUserId ? toAdd + user.id : "");
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <TreeSelect
        uri={uri}
        key={input.name}
        formState={formState}
        className="form-control"
        value={formState[input.name] ? formState[input.name] : ""}
        onChange={onInputChange}
        {...input.additionalAttrs}
      />,
    ];
  } else if (input.type === "image") {
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <ImageInput
        key={input.name}
        className="form-control"
        id={input.name}
        name={input.name}
        value={formState[input.name] ? formState[input.name] : ""}
        onChange={onInputChange}
        {...input.additionalAttrs}
      />,
    ];
  } else if (input.type === "crop") {
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <CropInput
        key={input.name}
        className="form-control"
        id={input.name}
        name={input.name}
        value={formState[input.name] ? formState[input.name] : ""}
        onChange={onInputChange}
        aspect={input.aspect}
        {...input.additionalAttrs}
      />,
    ];
  } else if (input.type === "empty") {
    return [<div style={{ height: 60 }}></div>];
  } else {
    return [
      <label key={"label" + input.name} htmlFor={input.name}>
        {labelPrefix + label}
      </label>,
      <input
        key={input.name}
        id={input.name}
        type={input.type}
        className="form-control"
        name={input.name}
        value={formState[input.name] ? formState[input.name] : ""}
        onChange={onInputChange}
        {...input.additionalAttrs}
      />,
    ];
  }
};

export default FormInput;
