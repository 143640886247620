import { useQuery } from "react-query";
import { apiRequestUri } from "./helper";

export const getUri = async ({ queryKey }) => {
  let response;
  try {
    response = await apiRequestUri(queryKey);
  } catch (error) {}
  return response;
};

export const Categories = (customerId) => {
  const uri = "category?user_id=" + customerId + "&page=1&per_page=0";
  const { data } = useQuery(uri, getUri);
  return data;
};

export const CategoryNames = (customerId) => {
  const uri = "category?user_id=" + customerId + "&page=1&per_page=10";
  const { data } = useQuery(uri, getUri);
  if (data) {
    const categories = data.data.map((category) => category.name);
    return [...new Set(categories)];
  }
  return data;
};

export const CartData = (customerId) => {
  const uri = "cart?customer_id=" + customerId;
  return useQuery(uri, getUri);
};

export const CartCount = (customerId) => {
  const uri = "cart?customer_id=" + customerId;
  const { data } = useQuery(uri, getUri);
  if (data) {
    return data.data.cart.reduce((total, item) => total + item.quantity, 0);
  }
  return data;
};

export const Products = (
  customerId,
  page,
  perPage,
  orderBy,
  orderDirection,
  search
) => {
  let uri =
    "product?user_id=" +
    customerId +
    "&published=1&order_by=" +
    orderBy +
    "&order_direction=" +
    orderDirection +
    "&page=" +
    page +
    "&per_page=" +
    perPage;
  if (search) uri += "&title=" + search + "&like=%%";
  return useQuery(uri, getUri);
};

export const ProductsCategory = (
  category,
  customerId,
  page,
  perPage,
  orderBy,
  orderDirection
) => {
  const uri =
    "product?user_id=" +
    customerId +
    "&category_id=" +
    category +
    "&published=1&order_by=" +
    orderBy +
    "&order_direction=" +
    orderDirection +
    "&page=" +
    page +
    "&per_page=" +
    perPage;
  return useQuery(uri, getUri);
};

export const Product = (id) => {
  const uri = "product/" + id;
  const { data } = useQuery(uri, getUri);
  return data;
};

export const Questions = (id) => {
  const uri = "product/" + id + "/question";
  return useQuery(uri, getUri);
};

export const Orders = (customerId, userId) => {
  const uri = "order?user_id=" + userId + "&customer_id=" + customerId;
  const { data } = useQuery(uri, getUri);
  return data;
};

export const Purchase = (id) => {
  const uri = "order/" + id;
  return useQuery(uri, getUri);
};

export const UserData = () => {
  const uri = "user?info=all";
  return useQuery(uri, getUri);
};
