import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiRequestUri } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingSpinner } from "components/LoadingSpinner";

export const QuestionShow = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [content, setContent] = useState(<LoadingSpinner />);
  const [cardTitle, setCardTitle] = useState(t("Question "));
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      let uri = "product/question/" + id;
      apiRequestUri(uri, "GET", {}, false)
        .then((response) => {
          setCardTitle(cardTitle + response.product_title);
          setContent(
            <Row className="justify-content-center">
              <Col md="6">
                <div className="card">
                  <div className="card-body m-3 text-start">
                    <h4 className="text-center m-3 mb-4">{cardTitle}</h4>
                    <div>
                      {t("Question")}: {response.question}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })
        .catch((error) => {});
    }
  }, [cardTitle, id, t, loaded]);
  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
