import FormInput from "./FormInput";
import { useForm } from "hooks/useForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequestUri } from "helpers/helper";
import { Col, Row, Button } from "react-bootstrap";
import { LoadingSpinner } from "components/LoadingSpinner";

export const Form = ({
  inputs,
  labelPrefix,
  buttonText,
  target,
  errorCallback,
  submitCallback,
  values,
  update,
  user,
  footer,
}) => {
  const navigate = useNavigate();
  const { formState, onInputChange } = useForm(values);
  const [goto, setGoto] = useState();
  const defaultButton = <Button as="input" type="submit" value={buttonText} />;
  const [currentButton, setButton] = useState(defaultButton);

  const onSubmit = (event) => {
    event.preventDefault();
    setButton(<LoadingSpinner />);
    if (submitCallback) {
      submitCallback(formState, event);
      return;
    }
    apiRequestUri(target.uri, target.method, formState, false)
      .then((response) => {
        if (!target.redirectUrl) {
          target.successCallback();
        } else {
          setGoto(target.redirectUrl);
        }
        setButton(defaultButton);
      })
      .catch((error) => {
        errorCallback(error);
        setButton(defaultButton);
      });
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <form onSubmit={onSubmit}>
      {inputs.length === 1 ? (
        <Row>
          <Col>
            <FormInput
              input={inputs[0]}
              labelPrefix={labelPrefix}
              onInputChange={onInputChange}
              formState={formState}
              update={update}
              user={user}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col key={"formcol1"}>
            {inputs.map((input, idx) => {
              if (input.fullWidth) return false;
              if (idx % 2 === 0) {
                return (
                  <FormInput
                    key={"FormInput" + input.name}
                    input={input}
                    labelPrefix={labelPrefix}
                    onInputChange={onInputChange}
                    formState={formState}
                    update={update}
                    user={user}
                  />
                );
              }
              return false;
            })}
          </Col>
          <Col key={"formcol2"}>
            {inputs.map((input, idx) => {
              if (input.fullWidth) return false;
              if (idx % 2 !== 0) {
                return (
                  <FormInput
                    key={"FormInput" + input.name}
                    input={input}
                    labelPrefix={labelPrefix}
                    onInputChange={onInputChange}
                    formState={formState}
                    update={update}
                    user={user}
                  />
                );
              }
              return false;
            })}
          </Col>
        </Row>
      )}
      {inputs.map((input, idx) => {
        if (input.fullWidth) {
          return (
            <FormInput
              key={"FormInput" + input.name}
              input={input}
              labelPrefix={labelPrefix}
              onInputChange={onInputChange}
              formState={formState}
              update={update}
              user={user}
            />
          );
        }
        return false;
      })}
      <div className="text-center m-3">{currentButton}</div>
      {footer && (
        <p>
          <small className="opacity-75">{footer}</small>
        </p>
      )}
    </form>
  );
};
