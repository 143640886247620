import { useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "components/Image";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ShareButtons from "components/ShareButtons";
import { ProductFeatured } from "./ProductFeatured";
import { CarouselItem, Modal } from "react-bootstrap";
import { Carousel } from "components/client/Carousel";
import { ProductRightText } from "./ProductRightText";
import { ProductStockText } from "./ProductStockText";
import { isLightColor } from "helpers/functionsHelper";

export const ProductModal = ({
  product,
  customer,
  customerPath,
  target,
  handleHide,
  show,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const hostUrl = window.location.href.replace(location.pathname, "");
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const handleButtonMouseEnter = () => {
    setIsButtonHovered(true);
  };

  const handleButtonMouseLeave = () => {
    setIsButtonHovered(false);
  };

  const textColor =
    customer && customer.data && customer.data.style
      ? customer.data.style.textcolor
      : "black";
  const isTextColorLight = isLightColor(textColor);

  const buttonStyle = {
    color: "white",
    backgroundColor: isButtonHovered
      ? "green"
      : customer && customer.data && customer.data.style
      ? customer.data.style.showbuttoncolor
      : "blue",
    borderColor: isButtonHovered
      ? "green"
      : customer && customer.data && customer.data.style
      ? customer.data.style.showbuttoncolor
      : "blue",
    textShadow: isTextColorLight ? "1px 1px 2px #000000" : "none",
  };

  return (
    <>
      {/* LARGE-DEVICE-MODAL */}
      <Modal
        show={show}
        centered={true}
        size="xl"
        onHide={handleHide}
        className="d-sm-block d-none"
      >
        <Modal.Body className="text-center w-100">
          <Modal.Header closeButton>
            <h3 className="fw-bold mx-auto">{product.title}</h3>
          </Modal.Header>
          <ProductFeatured product={product} top="15%" />
          <ProductRightText product={product} top="15%" />
          <ProductStockText product={product} top="85%" />
          <ShareButtons
            position="absolute"
            top="50%"
            left="4px"
            cardTitle={t("Share this Product")}
            title={product.title}
            url={`${hostUrl}/${customerPath}/products/${product.id}`}
          />
          {!product.images.length ? (
            <div className="w-100">
              <Image
                src="/images/no-image-available.webp"
                alt="No Image Available"
                style={{ height: "25rem", maxWidth: "100%" }}
              />
            </div>
          ) : (
            <Carousel className="w-100">
              {product.images.map((img, index) => (
                <CarouselItem key={index} style={{ height: "100%" }}>
                  <Image
                    className="d-block mx-auto"
                    style={{ height: "35rem" }}
                    src={img.url}
                    alt={`Slide XL ${index + 1}`}
                  />
                </CarouselItem>
              ))}
            </Carousel>
          )}
          <Link
            style={buttonStyle}
            className="btn fw-bold mx-auto fs-5 mt-2"
            to={`/${customerPath}/products/${product.id}`}
            onMouseEnter={handleButtonMouseEnter}
            onMouseLeave={handleButtonMouseLeave}
            target={target}
          >
            {t("Show")}
          </Link>
        </Modal.Body>
      </Modal>

      {/* SMALL-DEVICE-MODAL */}
      <Modal
        show={show}
        centered={true}
        size="sm"
        onHide={handleHide}
        className="d-block d-sm-none"
      >
        <Modal.Body className="text-center w-100">
          <Modal.Header closeButton>
            <h5 className="fw-bold mx-auto">{product.title}</h5>
          </Modal.Header>
          <ProductFeatured product={product} top="23%" />
          <ProductRightText product={product} top="23%" />
          <ProductStockText product={product} top="85%" />
          <ShareButtons
            position="absolute"
            top="40%"
            left="4px"
            cardTitle={t("Share this Product")}
            title={product.title}
            url={`${hostUrl}/${customerPath}/products/${product.id}`}
          />
          {!product.images.length ? (
            <Image
              src="/images/no-image-available.webp"
              alt="No Image Available"
              style={{ height: "25rem", maxWidth: "100%" }}
            />
          ) : (
            <Carousel className="w-100">
              {product.images.map((img, index) => (
                <CarouselItem
                  key={index}
                  style={{ height: "25rem", maxWidth: "90%" }}
                >
                  <Image
                    className="d-block h-100 mx-auto"
                    src={img.url}
                    alt={`Slide SM ${index + 1}`}
                  />
                </CarouselItem>
              ))}
            </Carousel>
          )}
          <Link
            style={buttonStyle}
            className="btn fw-bold w-100 mx-auto fs-5 mt-2"
            to={`/${customerPath}/products/${product.id}`}
            onMouseEnter={handleButtonMouseEnter}
            onMouseLeave={handleButtonMouseLeave}
            target={target}
          >
            {t("Show")}
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
};
