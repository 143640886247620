import DOMPurify from "dompurify";

export const isLightColor = (color) => {
  if (!color) {
    return false;
  }
  const rgb = parseInt(color.replace("#", ""), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};

export const getEnhancedText = (text) => {
  let enhancedText = DOMPurify.sanitize(text);
  enhancedText = enhancedText.replace(
    new RegExp("<p>", "g"),
    '<p class="m-0 small">'
  );
  enhancedText = enhancedText.replace(
    new RegExp("<h1>", "g"),
    '<h1 class="m-0 fw-bold">'
  );
  enhancedText = enhancedText.replace(
    new RegExp("<h2>", "g"),
    '<h2 class="m-0 fw-bold">'
  );
  enhancedText = enhancedText.replace(
    new RegExp("<h3>", "g"),
    '<h3 class="m-0 fw-bold">'
  );
  enhancedText = enhancedText.replace(
    new RegExp("<h4>", "g"),
    '<h4 class="m-0 fw-bold">'
  );
  enhancedText = enhancedText.replace(
    new RegExp("<h5>", "g"),
    '<h5 class="m-0 fw-bold">'
  );
  enhancedText = enhancedText.replace(
    new RegExp("<h6>", "g"),
    '<h6 class="m-0 fw-bold">'
  );
  return enhancedText;
};
