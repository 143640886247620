export const FormInputs = [
  {
    type: "file",
    name: "logo",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "checkbox",
    name: "emptylogo",
    value: "",
  },
  {
    type: "checkbox",
    name: "showbrand",
    value: "",
  },
  {
    type: "color",
    name: "topbarcolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "topbartextcolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "footercolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "footertextcolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "backgroundcolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "textcolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "cardcolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "checkbox",
    name: "boxShadowCards",
    value: "",
  },
  {
    type: "color",
    name: "colorBoxShadowCards",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "showbuttoncolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "paginationcolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "color",
    name: "paginationbuttoncolor",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
];