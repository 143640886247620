import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { QuestionItem } from "./QuestionItem";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "components/LoadingSpinner";

export const QuestionAsked = ({ productId, data }) => {
  const { t } = useTranslation();
  const [content, setContent] = useState(<LoadingSpinner />);

  useEffect(() => {
    if (data) {
      let items = [];
      for (const item of data.data) {
        items.push(<QuestionItem key={item.id} item={item} />);
      }
      if (data.data[0]) {
        setContent(
          <Accordion defaultActiveKey={data.data[0].id}>{items}</Accordion>
        );
      } else {
        setContent(<p>{t("There is no questions.")}</p>);
      }
    }
  }, [data, t]);

  return <>{content}</>;
};
