import { useTranslation } from "react-i18next";
import { Questions } from "helpers/ClientHelper";
import { useEffect, useMemo, useState } from "react";
import { QuestionForm } from "components/client/question/QuestionForm";
import { QuestionAsked } from "components/client/question/QuestionsAsked";

export const ClientQuestions = ({
  productTitle,
  customer,
  productId,
  customerPath,
  className,
}) => {
  const { t } = useTranslation();

  const textShadowStyle = useMemo(
    () => ({
      textShadow: "1px 1px 2px white",
    }),
    []
  );
  const { data, refetch } = Questions(productId);
  const [content, setContent] = useState();

  useEffect(() => {
    setContent(
      <>
        <h4 className="mt-3 mb-3" style={textShadowStyle}>
          {t("Questions and answers")}
        </h4>
        <QuestionForm
          productTitle={productTitle}
          productId={productId}
          customerPath={customerPath}
          customer={customer}
          refetch={refetch}
        />
        <h4 style={textShadowStyle}>{t("Questions asked")}</h4>
        <QuestionAsked productId={productId} data={data} />
      </>
    );
  }, [
    data,
    customer,
    customerPath,
    productId,
    productTitle,
    refetch,
    textShadowStyle,
    t,
  ]);

  return <div className={className}>{content}</div>;
};
