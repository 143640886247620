export const TableMapping = {
// column: data
  ID: {
    property: "id",
    transform: false,
  },
  Question: {
    property: "question",
    transform: false,
  },
  Answer: {
    property: "answer",
    transform: false,
  },
  Product: {
    property: "product_title",
    transform: false,
  },
  Code: {
    property: "product_code",
    transform: false,
  },
};
