import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Form } from "components/form/Form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserData } from "helpers/ClientHelper";
import { Container, Row, Col } from "react-bootstrap";
import { useCallback, useMemo, useState } from "react";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { FormInputs } from "./data/FormShipmentMethodsInputs";
import { FormMapping } from "./data/FormShipmentMethodsMapping";

const AdminShipmentMethodForm = () => {
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { data: customer, refetch: refetchCustomer, remove } = UserData();
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  const formAction = t("Edit Item");
  let labelPrefix = "";
  const buttonText = t("Update");

  let target = useMemo(
    () => ({
      method: "PUT",
      uri: "user/",
      redirectUrl: "/admin",
    }),
    []
  );

  const navigate = useNavigate();
  const [content, setContent] = useState(<LoadingSpinner />);
  const handleShow = useCallback(() => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then((result) => {
      remove();
      appLogout().then(() => {
        setGoto("/login");
      });
    });
  }, [remove, closeTime, t]);

  const loadContent = useCallback(
    (values, onSubmit) => {
      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{formAction}</h4>
                <Form
                  inputs={FormInputs}
                  labelPrefix={labelPrefix}
                  buttonText={buttonText}
                  values={values}
                  target={target}
                  submitCallback={onSubmit}
                  errorCallback={handleShow}
                  update={true}
                  footer={t(
                    "When you resize the screen, the image will adapt and be cropped to maintain the aesthetics of the page."
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    },
    [buttonText, formAction, handleShow, labelPrefix, target, t]
  );

  const onSubmit = useCallback(
    (formState, event) => {
      refetchCustomer().then((response) => {
        if (!response || !response.data) {
          handleShow();
          return;
        }
        let newData = response.data.data.data;
        if (!newData) {
          newData = {};
        }
        if (
          !Object.hasOwnProperty.call(newData, "shipment_methods") ||
          Array.isArray(newData.shipment_methods)
        ) {
          newData["shipment_methods"] = [];
        }
        let data = formState;
        data.name = formState.provider_id === "1" ? "Manual" : "Zippin";
        newData.shipment_methods[0] = data;
        apiRequestUri(target.uri, target.method, { data: newData }, false)
          .then((response) => {
            refetchCustomer().then(() => {
              setGoto(target.redirectUrl);
              return;
            });
          })
          .catch((error) => {
            handleShow();
          });
      });
    },
    [handleShow, refetchCustomer, target.method, target.uri, target.redirectUrl]
  );

  useEffect(() => {
    if (!loaded && customer) {
      setLoaded(true);
      if (customer?.data?.data?.shipment_methods?.length) {
        let values = customer.data.data.shipment_methods[0];
        for (const key in FormMapping) {
          const element = FormMapping[key];
          values[key] = customer.data.data.shipment_methods[0][element];
        }
        refetchCustomer();
        loadContent(values, onSubmit);
      } else {
        refetchCustomer();
        loadContent({}, onSubmit);
      }
      target.uri = "user/" + customer.data.id;
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    loaded,
    customer,
    loadContent,
    refetchCustomer,
    target,
    goto,
    navigate,
    onSubmit,
  ]);

  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
export default AdminShipmentMethodForm;
