import Swal from "sweetalert2";
import { Table } from "components/Table";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import React, { useEffect, useState } from "react";
import { TableMapping } from "./data/TableMapping";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

export const UserTable = () => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: customer, remove } = UserData();
  const [goto, setGoto] = useState();
  let uri = "user?ignore=" + (customer?.data?.id || "");

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  const deleteItem = (id, user) => {
    Swal.fire({
      icon: "error",
      title: t("Are you sure you want to delete the user ") + user.name + "?",
      showConfirmButton: true,
      confirmButtonText: t("Yes, delete it!"),
      showDenyButton: true,
      denyButtonText: t("No"),
    }).then((result) => {
      if (result.isConfirmed) {
        setContent(<LoadingSpinner />);
        let uri = "user/" + id;
        const formData = {
          deleted: 1,
        };
        apiRequestUri(uri, "PUT", formData)
          .then((response) => {
            setContent(tableContent);
          })
          .catch((error) => {
            remove();
            appLogout().then(() => {
              setGoto("/login");
            });
          });
        Swal.fire({
          icon: "warning",
          title: t("User deleted successfully."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
      }
    });
  };
  const undeleteItem = (id, user) => {
    Swal.fire({
      icon: "error",
      title: t("Are you sure you want to undelete the user ") + user.name + "?",
      showConfirmButton: true,
      confirmButtonText: t("Yes, undelete it!"),
      showDenyButton: true,
      denyButtonText: t("No"),
    }).then((result) => {
      if (result.isConfirmed) {
        setContent(<LoadingSpinner />);
        let uri = "user/" + id;
        const formData = {
          deleted: null,
        };
        apiRequestUri(uri, "PUT", formData)
          .then((response) => {
            setContent(tableContent);
          })
          .catch((error) => {
            remove();
            appLogout().then(() => {
              setGoto("/login");
            });
          });
        Swal.fire({
          icon: "warning",
          title: t("User undeleted successfully."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
      }
    });
  };
  let action = {
    Show: {
      url: "/admin/user",
      addId: true,
    },
    Complains: {
      url: "/admin/user/complain",
      addId: true,
    },
    Edit: {
      url: "/admin/user/edit",
      addId: true,
    },
    Delete: {
      callback: deleteItem,
    },
    Undelete: {
      callback: undeleteItem,
    },
  };
  const tableContent = (
    <Table
      title={t("Users")}
      mapping={TableMapping}
      action={action}
      uri={uri}
    />
  );
  const [content, setContent] = useState(tableContent);
  if (customer && customer.data && customer.data.role_name !== "admin") {
    setGoto("/admin");
    return;
  }
  if (!customer || !customer.data) {
    remove();
    appLogout().then(() => {
      setGoto("/login");
    });
    return;
  }

  return (
    <Container className="mt-0 mb-5 text-center mx-0 mw-100">
      {content}
    </Container>
  );
};

export default UserTable;
