import "./SidebarCollapse.css";
import { CDBSidebarMenuItem } from "cdbreact";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import SidebarCollapseItem from "./SidebarCollapseItem";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OutsideClickHandler from "components/OutsideClickHandler";

const SidebarCollapse = ({ data, eventKey, key }) => {
  const [links, setLinks] = useState([]);
  const [btnClass, setBtnClass] = useState();
  const [collapseClass, setCollapseClass] = useState();
  const [activeKey, setActiveKey] = useState();
  const { t } = useTranslation();

  const handleClick = () => {
    if (activeKey) {
      setBtnClass();
      setCollapseClass("d-none");
    } else {
      setBtnClass("opacity-100 mb-0 pb-0 sidebar-collapse");
      setCollapseClass("");
    }
  };

  const handleItemClick = () => {
    setBtnClass();
    setCollapseClass("d-none");
    setActiveKey("");
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, handleClick);
    return (
      <CDBSidebarMenuItem onClick={decoratedOnClick}>
        {children}
      </CDBSidebarMenuItem>
    );
  };

  useEffect(() => {
    let links = [];
    for (const link of data.to) {
      links.push(
        <SidebarCollapseItem
          key={link.to}
          data={link}
          eventKey={eventKey}
          clickCallback={handleItemClick}
        />
      );
    }
    setLinks(links);
  }, [data.to, eventKey]);

  return (
    <OutsideClickHandler key={key} onOutsideClick={handleItemClick}>
      <Accordion
        activeKey={activeKey}
        onSelect={(e) => setActiveKey(e)}
        className={btnClass}
      >
        <CustomToggle eventKey={eventKey}>
          <div className="dropdown-toggle">
            <FontAwesomeIcon icon={data.icon} className="me-2" />
            {t(data.text)}
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={eventKey} className={collapseClass}>
          <>{links}</>
        </Accordion.Collapse>
      </Accordion>
    </OutsideClickHandler>
  );
};

export default SidebarCollapse;
