export const FormInputs = [
  {
    type: "image",
    name: "image",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "checkbox",
    name: "emptyimage",
    value: "",
  },
  {
    type: "editor",
    name: "text",
    value: "",
    additionalAttrs: {
      required: true,
    },
    fullWidth: true,
  },
];
