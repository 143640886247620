export const getTopbarLinks = (categories, customerPath, text) => {
  if (!categories) {
    return [];
  }
  return [
    {
      text: text,
      to: null,
      dropdown: categories
        .filter(function (category) {
          if (!category.parent_id) {
            return true;
          }
          return false;
        })
        .map((category) => {
          if (category.children?.length)
            return {
              text: category.name,
              to: `/${customerPath}/category/${category.id}`,
              dropdown: category.children
                .filter(function (category) {
                  if (category.has_products) {
                    return true;
                  }
                  return false;
                })
                .map((category) => {
                  return {
                    to: `/${customerPath}/category/${category.id}`,
                    text: category.name,
                  };
                }),
            };
          return {
            to: `/${customerPath}/category/${category.id}`,
            text: category.name,
          };
        }),
    },
  ];
};
