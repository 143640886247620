import { useTranslation } from "react-i18next";

export const ProductFeatured = ({ product, top }) => {
  const { t } = useTranslation();
  return (
    <>
      {product.featured === 1 && (
        <div
          className="m-1 z-2 px-3 py-2 bg-warning rounded-pill fw-bold"
          style={{
            position: "absolute",
            top: top,
            left: 0,
            textShadow: "0 0 10px white, 0 0 15px white , 0 0 20px white",
          }}
        >
          {t("Deal of the day")}
        </div>
      )}
    </>
  );
};
