import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useForm } from "../hooks/useForm";
import { render } from "@react-email/render";
import { useTranslation } from "react-i18next";
import { apiRequestUri } from "../helpers/helper";
import { UserData } from "../helpers/ClientHelper";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TemplateEmailForgot from "components/email/TemplateEmailForgot";

const ForgotView = ({ customer, customerPath }) => {
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const navigate = useNavigate();
  const { data: user } = UserData();
  const [goto, setGoto] = useState();
  const location = useLocation();
  const hostUrl = window.location.href.replace(location.pathname, "");

  const emailTitle = `${
    customer
      ? customer.name.charAt(0).toUpperCase() + customer.name.slice(1)
      : "STORE WebComprehensive"
  } - ${t("Reset Password Notification")}`;

  const initialForm = useMemo(
    () => ({
      email: "",
    }),
    []
  );

  const { formState, email, onInputChange } = useForm(initialForm);
  const defaultButton = (
    <Button as="input" type="submit" value={t("Reset password")} />
  );
  const [currentButton, setButton] = useState(defaultButton);

  useEffect(() => {
    if (user && user.data) {
      if (user.data.role_name !== "client") {
        navigate("/admin");
      } else {
        navigate("/" + customerPath);
      }
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [customerPath, navigate, goto, user]);

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message ? t(message) : t("There was an error."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    });
    setButton(defaultButton);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setButton(<LoadingSpinner />);
    let uri = "forgot-password";
    apiRequestUri(uri, "POST", formState)
      .then((result) => {
        if (result && result.token) {
          const emailHtml = render(
            <TemplateEmailForgot
              customer={customer}
              customerPath={customerPath}
              email={email}
              token={result.token}
              hostUrl={hostUrl}
              t={t}
            />
          );
          let emailData = {
            email: email,
            title: emailTitle,
            body: emailHtml.replace("&amp;", "&"),
          };
          if (customer) {
            emailData["from_email"] = customer.email;
            emailData["from_name"] =
              customer.name.charAt(0).toUpperCase() + customer.name.slice(1);
          }
          apiRequestUri("mail", "POST", emailData)
            .then((result) => {
              Swal.fire({
                icon: "success",
                title: t("We have emailed your password reset link."),
                showConfirmButton: true,
              }).then(() => {
                if (!customerPath) {
                  setGoto("/login");
                } else {
                  setGoto("/" + customerPath + "/login");
                }
              });
            })
            .catch((error) => {
              showError();
            });
        } else {
          showError(result.email);
        }
      })
      .catch((error) => {
        showError();
      });
  };
  return (
    <div className="d-flex align-items-center min-vh-100 bg-primary">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3">
                <Row>
                  <Col xs={1}>
                    <Link
                      to={customerPath ? "/" + customerPath : "/"}
                      className="link-secondary h4"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="mt-3 ms-3 me-2"
                      />
                    </Link>
                  </Col>
                  <Col>
                    <h4 className="text-center m-3 mb-3">
                      {t("Forgot Password?")}
                    </h4>
                  </Col>
                  <Col md="1"></Col>
                </Row>
                <p className="text-end small mt-0 pt-1 mb-md-4 mx-md-3">
                  {t("Remember your password?")}{" "}
                  <Link
                    to={(customerPath ? "/" + customerPath : "") + "/login"}
                    className="link-danger fw-bold"
                  >
                    {t("Login here")}
                  </Link>
                </p>
                <form onSubmit={onSubmit}>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="text-center m-3">{currentButton}</div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ForgotView;
