import Swal from "sweetalert2";
import { UserData } from "helpers/ClientHelper";
import { ClientView } from "views/client/Client";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { AddressForm } from "components/address/AddressForm";
import { AddressItem } from "components/address/AddressItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export const ClientProfile = ({ customerPath, customer }) => {
  const { data: user, refetch, remove } = UserData();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const [goto, setGoto] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [content, setContent] = useState(<LoadingSpinner />);
  const handleShow = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalColor, setModalColor] = useState("light");
  const [deleteButton, setDeleteButton] = useState("Delete");
  const [loaded, setLoaded] = useState(false);
  const backgroundColor =
    customer && customer.data ? customer.data.style.cardcolor : "white";

  const loadContent = useCallback(
    (currentUser) => {
      const errorCallback = () => {
        remove();
        appLogout().then(() => {
          setGoto("/" + customerPath + "/login");
        });
      };

      const successCallback = () => {
        handleClose();
        Swal.fire({
          icon: "success",
          title: t("Address added successfully."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setLoaded(false);
        refetch().then((response) => {
          loadContent(response.data);
        });
      };

      const handlerAddAddress = () => {
        setModalContent(
          <AddressForm
            successCallback={successCallback}
            errorCallback={errorCallback}
          />
        );
        handleShow();
        setModalColor("light");
      };

      const handleDeleteAddress = (id) => {
        let uri = "address/" + id;
        setDeleteButton(<LoadingSpinner />);
        setLoaded(false);
        apiRequestUri(uri, "DELETE", {}, false)
          .then((response) => {
            refetch().then((response) => {
              Swal.fire({
                icon: "warning",
                title: t("Address deleted successfully."),
                showConfirmButton: false,
                timer: closeTime,
                timerProgressBar: true,
              });
              loadContent(response.data);
              setDeleteButton(t("Delete"));
            });
          })
          .catch((error) => {
            remove();
            appLogout().then(() => {
              setGoto("/" + customerPath + "/login");
            });
          });
      };
      let addresses = [];
      for (const item of currentUser.data.addresses) {
        addresses.push(
          <div key={item.id}>
            <hr />
            <Row>
              <Col>
                <Card.Text style={{ textShadow: "1px 1px 2px white" }}>
                  <AddressItem item={item} />
                </Card.Text>
              </Col>
              <Col>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteAddress(item.id)}
                >
                  {deleteButton}
                </Button>
              </Col>
            </Row>
          </div>
        );
      }
      setContent(
        <Card
          className="my-5 w-50 mx-auto"
          style={{ backgroundColor: backgroundColor }}
        >
          <Card.Body className="text-start">
            <Row>
              <Col>
                <Card.Title style={{ textShadow: "1px 1px 2px white" }}>
                  {t("User Profile")}
                </Card.Title>
              </Col>
              <Col className="text-end">
                {currentUser.data.validated === 1 ? (
                  <>
                    {t("Verified")}{" "}
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="mx-2 text-primary"
                    />
                  </>
                ) : (
                  <>
                    {t("Not Verified")}{" "}
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      className="mx-2 text-danger"
                    />
                    <Link
                      to={"/" + customerPath + "/verify"}
                      className="btn btn-primary"
                    >
                      {t("Verify")}
                    </Link>
                  </>
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Card.Text style={{ textShadow: "1px 1px 2px white" }}>
                  <strong>{t("Name")}:&nbsp;</strong>
                  {currentUser.data.name}
                </Card.Text>
                <Card.Text style={{ textShadow: "1px 1px 2px white" }}>
                  <strong>{t("Email")}:&nbsp;</strong>
                  {currentUser.data.email}
                </Card.Text>
              </Col>
              <Col className="text-end">
                <Link
                  className="btn btn-warning mb-3"
                  to={"/" + customerPath + "/password/change"}
                >
                  {t("Change password")}
                </Link>
                <br />
                <Link
                  className="btn btn-success"
                  to={"/" + customerPath + "/purchase"}
                >
                  {t("My Purchases")}
                </Link>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <strong>{t("Addresses")}:</strong>
              </Col>
              <Col className="text-end">
                <Button onClick={handlerAddAddress}>Add</Button>
              </Col>
            </Row>
            <Container className="ms-3">{addresses}</Container>
          </Card.Body>
        </Card>
      );
    },
    [backgroundColor, customerPath, deleteButton, closeTime, refetch, remove, t]
  );

  useEffect(() => {
    if (!loaded && user && user.data && user.data.name) {
      setLoaded(true);
      loadContent(user);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [loaded, user, deleteButton, loadContent, goto, navigate]);

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Container className="text-center h-100 my-5">{content}</Container>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal show={showModal} onHide={handleClose} centered={true}>
          <Modal.Body
            className={"bg-" + modalColor + " text-white text-center"}
          >
            {modalContent}
          </Modal.Body>
        </Modal>
      </div>
    </ClientView>
  );
};
