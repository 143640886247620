import { useParams } from "react-router-dom";
import { ClientView } from "views/client/Client";
import React, { useState, useEffect } from "react";
import { isLightColor } from "helpers/functionsHelper";
import OrderSelect from "components/client/OrderSelect";
import CategoryList from "components/client/CategoryList";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Categories, ProductsCategory } from "helpers/ClientHelper";
import CatalogePaginator from "components/client/CatalogePaginator";

export const ViewCategory = ({ customer, customerPath }) => {
  const perPage = 9;
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  const { name: category } = useParams();
  const categories = Categories(customer.id);
  const [title, setTitle] = useState();
  const [orderBy, setOrderBy] = useState("featured");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [search, setSearch] = useState();

  const {
    data: products,
    isFetching,
    isLoading,
    isRefetching,
  } = ProductsCategory(
    category,
    customer.id,
    page,
    perPage,
    orderBy,
    orderDirection,
    search
  );

  const textColor =
    customer && customer.data && customer.data.style
      ? customer.data.style.textcolor
      : "black";
  const isTextColorLight = isLightColor(textColor);

  const headerStyle = {
    color: textColor,
    textShadow: isTextColorLight
      ? "2px 2px 4px rgba(0,0,0,0.7)"
      : "2px 2px 4px rgba(255,255,255,0.7)",
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const handleSearch = (search) => {
    setPage(1);
    setSearch(search);
  };

  const handleOrder = (e) => {
    setPage(1);
    switch (e.target.value) {
      case "featured":
        setOrderBy("featured");
        setOrderDirection("desc");
        break;
      case "lowerPrice":
        setOrderBy("price");
        setOrderDirection("asc");
        break;
      case "higherPrice":
        setOrderBy("price");
        setOrderDirection("desc");
        break;

      default:
        setOrderBy("featured");
        setOrderDirection("desc");
        break;
    }
  };

  useEffect(() => {
    if (products) {
      setLastPage(products.meta.last_page);
      if (categories?.data)
        for (const element of categories.data) {
          if (element.id === parseInt(category)) {
            setTitle(element.name);
            break;
          }
        }
    }
  }, [products, page, customerPath, categories, title, category, customer]);

  return (
    <ClientView
      customer={customer}
      customerPath={customerPath}
      handleSearch={handleSearch}
    >
      <div className="container mt-4 text-center">
        <h1 style={headerStyle}>{title}</h1>
        <OrderSelect handleOrder={handleOrder} />
        {isFetching || isLoading || isRefetching ? (
          <LoadingSpinner />
        ) : (
          <>
            <CategoryList
              products={products}
              customer={customer}
              customerPath={customerPath}
            />
            <CatalogePaginator
              page={page}
              lastPage={lastPage}
              handlePage={handlePage}
              customer={customer}
            />
          </>
        )}
      </div>
    </ClientView>
  );
};
