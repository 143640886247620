import { apiRequestUri } from "./helper";
import { render } from "@react-email/render";
import TemplateEmailOrder from "components/email/TemplateEmailOrder";
import TemplateClientEmailOrder from "components/email/TemplateEmailClientOrder";

export const sendOrderEmail = async (user, customer, hostUrl, order, t) => {
  const { email: customerEmail } = customer;
  const firstUrlValue = customer.name.toLowerCase().replace(/ /g, "_");

  const customerEmailHtml = render(
    <TemplateEmailOrder
      user={user.data}
      customer={customer}
      hostUrl={hostUrl}
      order={order}
      t={t}
    />
  );

  const userEmailHtml = render(
    <TemplateClientEmailOrder
      user={user.data}
      customer={customer}
      hostUrl={hostUrl}
      order={order}
      firstUrlValue={firstUrlValue}
      t={t}
    />
  );

  const emailDataCustomer = {
    email: customerEmail,
    title: "STORE WebComprehensive - " + t("New Order Received"),
    body: customerEmailHtml,
  };

  const emailDataUser = {
    email: user.data.email,
    title:
      customer.name.charAt(0).toUpperCase() +
      customer.name.slice(1) +
      " - " +
      t("Your Order Confirmation"),
    body: userEmailHtml,
    from_email: customerEmail,
    from_name: customer.name.charAt(0).toUpperCase() + customer.name.slice(1),
  };

  try {
    await apiRequestUri("mail", "POST", emailDataCustomer);
    await apiRequestUri("mail", "POST", emailDataUser);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};
