import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useForm } from "../hooks/useForm";
import { useTranslation } from "react-i18next";
import { apiRequestUri } from "../helpers/helper";
import { UserData } from "../helpers/ClientHelper";
import { Link, useNavigate } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RegisterView = ({ customerPath }) => {
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const navigate = useNavigate();
  const { data: user } = UserData();
  const [goto, setGoto] = useState();

  const initialForm = useMemo(
    () => ({
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role: customerPath ? "client" : "customer",
    }),
    [customerPath]
  );

  const {
    formState,
    name,
    email,
    password,
    password_confirmation,
    role,
    onInputChange,
  } = useForm(initialForm);
  const defaultButton = (
    <Button as="input" type="submit" value={t("Register")} />
  );
  const [currentButton, setButton] = useState(defaultButton);
  const [title, setTitle] = useState(process.env.REACT_APP_NAME);

  useEffect(() => {
    if (customerPath) {
      setTitle(customerPath.charAt(0).toUpperCase() + customerPath.slice(1));
    }
    if (user && user.data) {
      if (user.data.role_name !== "client") {
        navigate("/admin");
      } else {
        navigate("/" + customerPath);
      }
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [customerPath, navigate, goto, user]);

  const onSubmit = (event) => {
    event.preventDefault();
    setButton(<LoadingSpinner />);
    let uri = "user";
    apiRequestUri(uri, "POST", formState)
      .then((result) => {
        if (result) {
          Swal.fire({
            icon: "success",
            title: t("The user has registered successfully."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then(() => {
            if (!customerPath) {
              setGoto("/login");
            } else {
              setGoto("/" + customerPath + "/login");
            }
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("Invalid password or email."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setButton(defaultButton);
      });
  };
  return (
    <div className="d-flex align-items-center min-vh-100 bg-primary">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3">
                <Row>
                  <Col xs={1}>
                    <Link
                      to={customerPath ? "/" + customerPath : "/"}
                      className="link-secondary h4"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="mt-3 ms-3 me-2"
                      />
                    </Link>
                  </Col>
                  <Col>
                    <h4 className="text-center mx-3 mt-3">
                      {t("Sign up for")}
                    </h4>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
                <Row>
                  <Col>
                    <h4 className="text-center mb-md-4 mb-2">{title}!</h4>
                  </Col>
                </Row>
                <p className="text-end small mt-0 pt-1 mb-4 ms-3">
                  {t("Have already an account?")}{" "}
                  <Link
                    to={(customerPath ? "/" + customerPath : "") + "/login"}
                    className="link-danger fw-bold"
                  >
                    {t("Login here")}
                  </Link>
                </p>
                <form onSubmit={onSubmit}>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      minLength="3"
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder={t("Name")}
                      value={name}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      minLength="6"
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder={t("Password")}
                      value={password}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      minLength="6"
                      type="password"
                      className="form-control"
                      name="password_confirmation"
                      placeholder={t("Confirm Password")}
                      value={password_confirmation}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="d-none">
                    <input
                      type="text"
                      name="role"
                      value={role}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="text-center mx-3">{currentButton}</div>
                </form>
                <p className="small mt-2 pt-1 mb-0 ms-md-3">
                  * {t("By registering, you agree to our")}{" "}
                  <Link
                    to={(customerPath ? "/" + customerPath : "") + "/terms"}
                  >
                    {t("Terms of Use")}
                  </Link>{" "}
                  {t("and acknowledge that you have read our")}{" "}
                  <Link
                    to={(customerPath ? "/" + customerPath : "") + "/policies"}
                  >
                    {t("Privacy Policy")}
                  </Link>
                  {customerPath && (
                    <>
                      {" "}
                      {t("and")}{" "}
                      <Link to={"/" + customerPath + "/refund"}>
                        {t("Refund Policy")}
                      </Link>
                    </>
                  )}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default RegisterView;
