export const ProductPrice = ({ product }) => {
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";

  const previousPrice = product.previous_price
    ? product.previous_price.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })
    : false;

  const formattedPrice = product.price.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });

  return (
    <>
      {previousPrice && product.featured === 1 && (
        <small className="text-decoration-line-through">
          {unitPrice}
          {previousPrice}
          <br />
        </small>
      )}
      {previousPrice && product.featured !== 1 && (
        <div className="text-decoration-line-through">
          {unitPrice}
          {previousPrice}
          <br />
        </div>
      )}
      <div className="h3 fw-bold">
        {unitPrice}
        {formattedPrice}
      </div>
    </>
  );
};
