import React from "react";
import {
  Html,
  Head,
  Body,
  Container,
  Text,
  Button,
  Link,
} from "@react-email/components";
import { Image } from "components/Image";

const TemplateClientEmailOrder = ({
  hostUrl,
  user,
  customer,
  order,
  firstUrlValue,
  t,
}) => {
  const logo = customer
    ? customer.data?.style?.logo
    : "https://webcomprehensive.com/logo.png";
  const brand = customer ? customer.name : "STORE WEBCOMPREHENSIVE";
  const backgroundColor = customer
    ? customer.data?.style?.backgroundcolor
    : "#000080";
  return (
    <Html>
      <Head />
      <Body>
        <Container style={{ maxWidth: "auto" }}>
          <table
            align="center"
            style={{
              height: 424,
              margin: "16px 0",
              borderRadius: 12,
              backgroundColor: backgroundColor ? backgroundColor : "#000080",
            }}
            width="100%"
          >
            <tbody>
              <tr>
                <td
                  align="center"
                  style={{ padding: 40, textAlign: "center" }}
                  valign="top"
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: 5,
                      padding: 5,
                    }}
                  >
                    {logo && (
                      <Text
                        style={{
                          marginTop: 5,
                          marginBottom: 0,
                          padding: 2,
                          textAlign: "center",
                        }}
                      >
                        <Image src={logo} alt="logo" height="50px" />
                      </Text>
                    )}
                    <Text
                      style={{
                        margin: "20px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: 45,
                        color: "black",
                        textAlign: "center",
                        marginTop: 5,
                        lineHeight: 1,
                        padding: 2,
                      }}
                    >
                      {brand}
                    </Text>
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: 20,
                      borderRadius: 5,
                      marginLeft: 5,
                      marginRight: 5,
                      padding: 40,
                    }}
                  >
                    <Text
                      style={{
                        marginTop: 10,
                        fontFamily: "Montserrat",
                        fontSize: 25,
                        lineHeight: "24px",
                        color: "black",
                        textAlign: "center",
                        backgroundColor: "white",
                      }}
                    >
                      {t("Your order has been generated successfully!")}
                    </Text>
                    <Text
                      style={{
                        marginTop: 10,
                        fontFamily: "Montserrat",
                        fontSize: 25,
                        lineHeight: "24px",
                        color: "black",
                        textAlign: "start",
                        backgroundColor: "white",
                      }}
                    >
                      {t("Hello!")}, {user.name}
                    </Text>
                    <Text
                      style={{
                        margin: "25px",
                        marginTop: 10,
                        fontFamily: "Montserrat",
                        fontSize: 25,
                        lineHeight: "24px",
                        color: "black",
                        textAlign: "center",
                        backgroundColor: "white",
                        marginBottom: "50px",
                      }}
                    >
                      {t("Thank you for your purchase!")}{" "}
                      {t("Your order is being processed.")}
                      <br />
                      {t(
                        "We will contact you very soon for any additional details."
                      )}{" "}
                      {t(
                        "We are excited to serve you and make sure your experience is great!"
                      )}{" "}
                      {t(
                        "If you have any questions or need assistance, please do not hesitate to contact us."
                      )}
                    </Text>
                    <Text
                      style={{
                        margin: "16px 16px",
                        fontFamily: "Montserrat",
                        fontSize: 15,
                        textAlign: "start",
                        color: "black",
                      }}
                    >
                      {t("This is our email")}:{" "}
                      <Link href={`mailto:${customer.email}`}>
                        {customer.email}
                      </Link>
                      <br />
                    </Text>
                    <Button
                      href={
                        hostUrl + "/" + firstUrlValue + "/purchase/" + order.id
                      }
                      style={{
                        boxShadow:
                          "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
                        borderRadius: 20,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "rgb(229,231,235)",
                        backgroundColor: "#ffa500",
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingTop: 12,
                        paddingBottom: 12,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        color: "white",
                        marginTop: 20,
                        marginBottom: 30,
                        fontSize: 20,
                      }}
                    >
                      {t("See Purchases")}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Container>
      </Body>
    </Html>
  );
};

export default TemplateClientEmailOrder;
