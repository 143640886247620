export const themes = [
  {
    id: 1,
    title: "Sun",
    imgSrc: "/images/Sun.webp",
    defaultStyle: {
      footercolor: "#ffffff",
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#fbf3f3",
      topbartextcolor: "#fbf9f9",
      backgroundcolor: "#fdf7f7",
      textcolor: "#f4ebeb",
      cardcolor: "#f6f4f4",
      showbuttoncolor: "#f4ecf4",
      paginationcolor: "#454a46",
      paginationbuttoncolor: "#e580d8",
      footertextcolor: "#ffffff",
      boxShadow: null,
      boxShadowCards: null,
      colorBoxShadowCards: "#f8f7f7",
    },
  },
  {
    id: 2,
    title: "Moon",
    imgSrc: "/images/Moon.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#0d0d0d",
      topbartextcolor: "#0d0d0d",
      footercolor: "#121111",
      backgroundcolor: "#0f0f0f",
      textcolor: "#0a0a0a",
      cardcolor: "#0d0c0c",
      showbuttoncolor: "#100f10",
      paginationcolor: "#454a46",
      paginationbuttoncolor: "#484247",
      footertextcolor: "#0d0d0d",
      boxShadow: null,
      boxShadowCards: null,
      colorBoxShadowCards: "#0f0f0f",
    },
  },
  {
    id: 3,
    title: "Strawberry",
    imgSrc: "/images/Strawberry.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#e70404",
      topbartextcolor: "#f07070",
      footercolor: "#e70404",
      backgroundcolor: "#ec6969",
      textcolor: "#bb0202",
      cardcolor: "#da0707",
      showbuttoncolor: "#c11f48",
      paginationcolor: "#a20726",
      paginationbuttoncolor: "#e92525",
      footertextcolor: "#f07070",
      boxShadow: null,
      boxShadowCards: "on",
      colorBoxShadowCards: "#fbdfdf",
    },
  },
  {
    id: 4,
    title: "Lemmon",
    imgSrc: "/images/Lemmon.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#c9e10e",
      topbartextcolor: "#d25019",
      footercolor: "#c9e10e",
      backgroundcolor: "#bb5a25",
      textcolor: "#9b1212",
      cardcolor: "#b6c11a",
      showbuttoncolor: "#d28732",
      paginationcolor: "#8f4b14",
      paginationbuttoncolor: "#890606",
      footertextcolor: "#d25019",
      boxShadow: null,
      boxShadowCards: "on",
      colorBoxShadowCards: "#fbdfdf",
    },
  },
  {
    id: 5,
    title: "Grape",
    imgSrc: "/images/Grape.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#450693",
      topbartextcolor: "#9166f4",
      footercolor: "#450693",
      backgroundcolor: "#5421ca",
      textcolor: "#490183",
      cardcolor: "#030a72",
      showbuttoncolor: "#6c33d7",
      paginationcolor: "#4a1796",
      paginationbuttoncolor: "#190859",
      footertextcolor: "#9166f4",
      boxShadow: null,
      boxShadowCards: "on",
      colorBoxShadowCards: "#fbdfdf",
    },
  },
  {
    id: 6,
    title: "Pink",
    imgSrc: "/images/Pink.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#b406b7",
      topbartextcolor: "#f167f4",
      footercolor: "#b406b7",
      backgroundcolor: "#c30490",
      textcolor: "#e30dbb",
      cardcolor: "#a2061d",
      showbuttoncolor: "#b80f82",
      paginationcolor: "#c3135a",
      paginationbuttoncolor: "#a60808",
      footertextcolor: "#f167f4",
      boxShadow: null,
      boxShadowCards: "on",
      colorBoxShadowCards: "#fbdfdf",
    },
  },
  {
    id: 7,
    title: "Blue",
    imgSrc: "/images/Blue.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#291cd9",
      topbartextcolor: "#8684f5",
      footercolor: "#291cd9",
      backgroundcolor: "#37a0cd",
      textcolor: "#1f0797",
      cardcolor: "#2719e6",
      showbuttoncolor: "#3771be",
      paginationcolor: "#0b0971",
      paginationbuttoncolor: "#202abc",
      footertextcolor: "#8684f5",
      boxShadow: null,
      boxShadowCards: "on",
      colorBoxShadowCards: "#fbdfdf",
    },
  },
  {
    id: 8,
    title: "Apple",
    imgSrc: "/images/Apple.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#0ec411",
      topbartextcolor: "#65e27e",
      footercolor: "#0ec411",
      backgroundcolor: "#0e730c",
      textcolor: "#30ab45",
      cardcolor: "#01b204",
      showbuttoncolor: "#22c516",
      paginationcolor: "#0b6c09",
      paginationbuttoncolor: "#18a00e",
      footertextcolor: "#65e27e",
      boxShadow: null,
      boxShadowCards: "on",
      colorBoxShadowCards: "#fbdfdf",
    },
  },
  {
    id: 9,
    title: "Shop1",
    imgSrc: "/images/Shop1.webp",
    defaultStyle: {
      logo: "https://i.pinimg.com/originals/a6/e4/22/a6e422fef02cd121f81c94cdbaed082a.jpg",
      emptylogo: null,
      topbarcolor: "#0d0d0c",
      topbartextcolor: "#f9efeb",
      footercolor: "#121211",
      backgroundcolor: "#741fdb",
      textcolor: "#9b1212",
      cardcolor: "#3a3fdf",
      showbuttoncolor: "#c9131c",
      paginationcolor: "#d20f3f",
      paginationbuttoncolor: "#cf1717",
      footertextcolor: "#f1e9e9",
      boxShadow: null,
      boxShadowCards: "on",
      colorBoxShadowCards: "#fbdfdf",
    },
  },
];