import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NeedValidation = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center min-vh-100 bg-primary">
      <Container className="text-center my-5 text-white">
        <h1>{t("Sorry, this page isn't available.")}</h1>
        <p className="my-5">{t("The site needs seller verification.")}</p>
      </Container>
    </div>
  );
};

export default NeedValidation;
