import { useEffect, useState } from "react";
import Header from "components/home/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FooterShop from "components/home/FooterShop";
import { Col, Container, Row } from "react-bootstrap";

export const HomeTerms = () => {
  const { t } = useTranslation();
  const [homeClicked, setHomeClicked] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setHomeClicked(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (homeClicked) {
      navigate("/");
    }
  }, [homeClicked, navigate]);
  return (
    <>
      <Container fluid>
        <Header
          home={{ name: "", ref: null }}
          sections={[]}
          activeSection={""}
          handleClick={handleClick}
        />
        <Row className="text-center mt-md-5 pt-md-5 mx-md-5 px-md-5">
          <h1 className="display-4 fw-bold mt-5 pt-5 mb-1">
            Store
            <small className="h2"> WebComprehensive</small>
            <br />
            {t("Terms of Use")}
          </h1>
          <p className="mb-5">{t("Last revised: August 30, 2024")}</p>
          <Col className="mx-4 px-4 text-start">
            <Row>
              <Col md={10}>
                <p>
                  {t(
                    "Welcome to WebComprehensive's Terms of Use! We are really glad you are on board. Thank you for choosing to use our services."
                  )}
                  <br />
                  <br />
                  {t(
                    "Below we have listed important legal terms that apply to anyone who visits our website or uses our services. These terms are necessary for the protection of both you and us and to make our services possible and more enjoyable for everyone. WebComprehensive offers a wide range of services and features and some of the following terms may not be relevant to the specific services you use."
                  )}
                  <br />
                  <br />
                  {t(
                    "We know that reading legal terms can be tiring, so we try to make it a more enjoyable experience. If you have suggestions on how we can improve, we invite you to contact us."
                  )}
                </p>
              </Col>
            </Row>
            {/* 1 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">1. {t("Introduction")}</h3>
              <hr />
              <h5 className="mb-4">1.1. {t("Our goal")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "Our Services offer our Users (as defined below) the ability to easily create a beautiful and functional online presence, manage and promote businesses, content and ideas, and have an overall satisfying experience doing so, even if they are not tech or design savvy. As detailed below, we offer Users many tools and features to create, publish and use amazing websites, e-commerce platforms, newsletters, galleries, media players, mobile applications and other online and mobile applications, tools and services. The websites, features and online and mobile platforms created by Users are collectively referred to as"
                  )}{" "}
                  “<span className="fw-bold">{t("User Platforms")}</span>”.
                </p>
              </Col>
              <Col className="ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "WebComprehensive offers online and mobile services and tools that allow you to create, manage and/or operate your own online and mobile presence."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">1.2. {t("Legal agreement")}</h5>
              <Col md={8}>
                <p>
                  {t("These WebComprehensive Terms of Use")} (“
                  <span className="fw-bold">{t("Terms of Use")}</span>”),{" "}
                  {t(
                    "together with any additional terms that apply specifically to certain of our services and features as presented on the WebComprehensive website(s)"
                  )}{" "}
                  (“
                  <span className="fw-bold">
                    {t("WebComprehensive Website")}
                  </span>
                  ” {t("and, collectively, the")} “
                  <span className="fw-bold">{t("WebComprehensive Terms")}</span>
                  ”),{" "}
                  {t(
                    "Together they form the total of terms and conditions applicable to each visitor or user"
                  )}{" "}
                  (“
                  <span className="fw-bold">{t("User")}</span>” {t("or")} “
                  <span className="fw-bold">{t("you")}</span>”){" "}
                  {t(
                    "of the WebComprehensive Website, the WebComprehensive mobile application (the “WebComprehensive App”) and/or any other services, applications or features offered by us in connection herewith, except where we specifically state otherwise (all services offered on the WebComprehensive Website or the WebComprehensive App, collectively, the"
                  )}{" "}
                  “
                  <span className="fw-bold">
                    {t("WebComprehensive Services")}
                  </span>
                  ” {t("or")} “<span className="fw-bold">{t("Services")}</span>
                  ”).{" "}
                  {t(
                    "For the avoidance of doubt, WebComprehensive Services (as defined in these Terms of Use) do not include any services, applications, features or components that were created, developed, connected to or offered by a WebComprehensive User, even if presented on the WebComprehensive Website or WebComprehensive App."
                  )}
                  <br />
                  {t(
                    "The WebComprehensive Terms constitute a binding and enforceable legal agreement between WebComprehensive and its affiliated companies and subsidiaries worldwide."
                  )}{" "}
                  (“
                  <span className="fw-bold">WebComprehensive</span>” {t("or")} “
                  <span className="fw-bold">{t("we")}</span>”){" "}
                  {t(
                    "and you in connection with your use of any of the WebComprehensive Services. We therefore encourage you to read them carefully."
                  )}
                  <br />
                  <br />
                  {t(
                    "You may only visit or use the WebComprehensive Services or the WebComprehensive App if you fully agree to the WebComprehensive Terms. If you use or register for any of the WebComprehensive Services, you indicate and affirm your informed consent to these Terms of Use and any other WebComprehensive Terms that apply to your use of any of the WebComprehensive Services. If you do not read the WebComprehensive Terms or if you do not fully understand or agree to them, you must immediately leave the WebComprehensive Website and avoid or discontinue use of all WebComprehensive Services or the WebComprehensive App."
                  )}
                  <br />
                  <br />
                  {t(
                    "By using our Services, you also represent that you have read our Privacy Policy, available at our Privacy Policy."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "By visiting our website or using our application or services, you are entering into a legal agreement with us, consisting of these Terms of Use and the legal terms of our additional services."
                  )}
                  <br />
                  <br />
                  {t(
                    "By using our services, you signify your consent to these terms and acknowledge that you have read our Privacy Policy. You may not use our services if you do not agree to all of our terms."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">1.3. {t("User Account")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "To access and use certain sections and features of the WebComprehensive Services, you must first register and create an account with WebComprehensive."
                  )}{" "}
                  (“
                  <span className="fw-bold">{t("User Account")}</span>”).
                  <br />
                  <br />
                  {t(
                    "Creating a User Account or purchasing Paid Services (as defined in Clause 6 below) may be done directly on the WebComprehensive Website or through unrelated third parties who are authorized to sell WebComprehensive subscriptions for User Accounts and other Paid Services (a"
                  )}{" "}
                  “<span className="fw-bold">{t("Reseller")}</span>”){" "}
                  {t("under a separate agreement with WebComprehensive (a")} “
                  <span className="fw-bold">{t("Reseller Agreement")}</span>”).
                  <br />
                  <br />
                  {t(
                    "If you register for WebComprehensive Services or purchase Paid Services through a Reseller (herein referred to as a “Reseller User”), please note the following:"
                  )}
                  <ul>
                    <li>
                      {t(
                        "These Terms of Use apply in addition to any understanding between you and Reseller, and govern your use of the WebComprehensive Services."
                      )}
                    </li>
                    <li>
                      {t(
                        "As between you and WebComprehensive, depending on your relationship with WebComprehensive and unless otherwise provided in these Terms of Use, these Terms of Use take precedence over any understanding between you and Reseller regarding your use of WebComprehensive Services or activities on your User Account (or the User Account to which you are added)."
                      )}
                    </li>
                    <li>
                      {t(
                        "Certain services, applications and features of the WebComprehensive Services may not be available to you or may only be available to you through the Reseller platform."
                      )}
                    </li>
                  </ul>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "You may also create an account and purchase services through a third party authorized to sell WebComprehensive (Reseller). If you register with WebComprehensive or purchase services through a Reseller, you acknowledge and agree to these WebComprehensive Terms of Use (in addition to any agreement between you and the Reseller), so that in your relationship with WebComprehensive, these terms will take precedence over any other terms, and some WebComprehensive services and features may not be available to you, or may only be available through the Reseller's platform."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                1.4 {t("Access to accounts, roles and permissions")}
              </h5>
              <Col md={8}>
                <p>
                  {t(
                    "You may invite others to your User Account and User Platforms and assign them certain roles and permissions to perform certain activities within your User Account and User Platforms."
                  )}
                  <br />
                  {t(
                    "A person who is granted permission to do activities on a User Account that he or she does not own is referred to herein as a"
                  )}{" "}
                  “<span className="fw-bold">{t("Contributor")}</span>”.
                  <br />
                  {t(
                    "If anyone other than you (including Contributors) accesses your User Account settings or any of your User Platforms, they may also (and in the case of Contributors, depending on the roles and permissions you assign to them) perform actions available to you (unless specifically stated otherwise in the WebComprehensive Services), make changes to your User Platforms and User Account, agree to any legal terms available there, make various representations and warranties, and more. All such activities will be deemed to be done on behalf of and for the account of you as the owner of the User Account, whether or not you have specifically authorized this."
                  )}
                  <br />
                  {t(
                    "We therefore strongly encourage you to (i) keep your User Account login credentials confidential and (ii) allow access to your User Account only to individuals you trust, as you will be fully responsible for all activities that occur under your User Account or User Platforms (including all representations and warranties any Contributor makes therein) and for all damages, expenses or losses that may arise from such activities."
                  )}
                  <br />
                  {t(
                    "You must provide complete and accurate information when registering your User Account and using the WebComprehensive Services. We strongly encourage you to provide your own (or your company's, as applicable) contact and billing information, as well as your valid email address, as we may use it to identify you and determine the actual and true owner of the User Account or User Content (as defined below) submitted to us."
                  )}
                  <br />
                  {t(
                    "If you are a Contributor, you must register and create your own User Account in order to access the User Account for which you will be a Contributor. Therefore, these Terms of Use also apply to Contributors. If you are a Reseller User, depending on the agreement between you and the Reseller, such Reseller may be the owner of the User Platform and you may be invited by the Reseller as a Contributor to such User Platform."
                  )}
                  <br />
                  {t(
                    "If you are invited as a Contributor on a User Platform or if you are a Reseller User, you represent that the owner of such User Platform or the Reseller (i) will have full access to all information (including personal information) stored by you or stored on your behalf on such User Platform; and (ii) will have all rights and functions granted to a User Account owner in connection with such User Platform."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "You may invite other people (Collaborators) to perform activities on your User Account by assigning them certain roles and permissions. If you are invited to be a Collaborator on another User's Platform, you must create your own User Account before accessing the other User's Platform."
                  )}
                  <br />
                  <br />
                  {t(
                    "If you are a Reseller User, the Reseller may be the owner of the User Platform and have access to the information on such User Platform. The Reseller will also have all rights and functionalities granted to a User Account owner. The Reseller may invite you as a Contributor to such User Platform."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">1.5 {t("Account Ownership")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "For each User Platform, WebComprehensive will consider the owner of such User Platform to be the person or entity whose email address appears in WebComprehensive's records as the owner of the User Account within which the User Platform was created."
                  )}
                  <br />
                  {t(
                    "In the event of a dispute over ownership of a User Account, we reserve the right to determine ownership of a User Account at our discretion, whether or not we conduct an independent investigation. However, if we are unable to make such a determination (as assessed in our sole discretion), we reserve the right to refrain from doing so or to suspend a User Account until the parties to the dispute reach an agreement, without WebComprehensive having any liability to you or any other party. We may request documents (e.g., government-issued identification, business license) to assist us in determining ownership."
                  )}
                  <br />
                  {t(
                    "If you created your User Account directly on the WebComprehensive Website (and not through a Reseller), we may evaluate the principles set forth below to determine ownership."
                  )}
                  <br />
                  {t(
                    "If you are invited as a Contributor on a User Platform or if you are a Reseller User, you represent that the owner of such User Platform or the Reseller (i) will have full access to all information (including personal information) stored by you or stored on your behalf on such User Platform; and (ii) will have all rights and functions granted to a User Account owner in connection with such User Platform."
                  )}
                  <br />
                  1.{" "}
                  {t(
                    "WebComprehensive will consider the person or entity that has access to the email address (entered into WebComprehensive's records for a User Account on which such User Platform or User Content was created) to be the owner of a User Account, User Platform or User Content that was created or uploaded to the applicable WebComprehensive Service."
                  )}
                  <br />
                  2.{" "}
                  {t(
                    "If any Paid Services were purchased through a User Account, WebComprehensive may consider the owner of such User Account or the relevant User Platform or the User Content created thereon to be the person or entity whose billing information was used for the purchase of such Paid Services (“Billing Information”). Notwithstanding the foregoing, where applicable, if a User Platform was connected to an external domain name (whether imported or purchased as part of certain Paid Services, as defined below), and the registration information for such domain is publicly available through the WHOIS database available at www.whois.net or in WebComprehensive's database, WebComprehensive may consider the owner of such User Platform to be the person or entity listed as the registrant of such domain. In the event that both a person and an organization are listed as the registrant or as the organization registering for such domain, WebComprehensive will consider the organization to be the actual owner of the domain. In the event that the Billing Information indicates that one person owns the User Platform and the domain registration indicates a different owner, WebComprehensive will consider the person registered as the owner of the domain connected to the WebComprehensive Account to be the Owner of the User Platform."
                  )}
                  <br />
                  <br />
                  {t(
                    "Notwithstanding the foregoing, WebComprehensive shall have the right to determine ownership of User Content or a User Website in its sole discretion, including without regard to the foregoing, if WebComprehensive believes, in its sole discretion, that the situation warrants such a determination based on the factual situation as determined by WebComprehensive. In the case of a Reseller User, WebComprehensive may determine ownership of the applicable User Account pursuant to the terms of the Reseller Agreement."
                  )}
                </p>
              </Col>
            </Row>
            {/* 2 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">2. {t("Your obligations")}</h3>
              <hr />
              <h5 className="mb-4">
                2.1. {t("You represent and warrant that:")}
              </h5>
              <Col md={8}>
                <p>
                  <ol>
                    <li>
                      {t(
                        "You are at least thirteen (13) years old, or sixteen (16) years old if you are a person within the European Union (EU), or the age of legal majority in your jurisdiction, and you have the legal authority, right and freedom to enter into the WebComprehensive Terms and form a binding agreement, for yourself or on behalf of the person or entity to whom you are bound by the WebComprehensive Terms;"
                      )}
                    </li>
                    <li>
                      {t(
                        "You are not an habitual resident of, and will not use or distribute the WebComprehensive Services or the WebComprehensive App in, any country or region that is subject to comprehensive country or region blocks by the U.S. You further confirm that you are not listed on any sanctions list, including without limitation the U.S. Specially Designated Nationals and Blocked Persons List, maintained by the U.S. (“SDN List”), or any other applicable sanctions list maintained by the EU, the U.K., or others, and that your business is not owned by or otherwise controlled by any Sanctioned Party, directly or indirectly in the aggregate, or controlled by any such party (“Sanctioned Party”). You further confirm that you will not use or distribute the WebComprehensive Services or the WebComprehensive App directly or indirectly for or for the benefit of a Sanctioned Party. You agree to indemnify WebComprehensive against any and all losses, including but not limited to monetary penalties and legal fees, that WebComprehensive may suffer in the event that you violate any of the foregoing representations regarding your sanctions-free status."
                      )}
                    </li>
                    <li>
                      {t(
                        "your country of residence or country of company incorporation is the same as the country specified in the contact or billing address you provide to us;"
                      )}
                    </li>
                    <li>
                      {t(
                        "You understand that WebComprehensive does not provide legal advice or recommendations regarding laws or regulations applicable to your use or that of any of your End Users, or your compliance therewith;"
                      )}
                      <br />
                      <br />
                      <span className="fw-bold">
                        {t(
                          "And specifically, with respect to your User Content:"
                        )}
                      </span>
                    </li>
                    <li>
                      {t(
                        "You confirm that you own all rights to the content uploaded, developed or provided by you, or imported, connected, copied or uploaded by the WebComprehensive Services for you, on your User Platform"
                      )}{" "}
                      (“
                      <span className="fw-bold">{t("User Content")}</span>”),
                      {t(
                        "including any design, image, animation, video, audio file, font, logo, code, algorithm, SPI, API, database, illustration, composition, artwork, interface, username, information you provide for the creation of a subdomain name, text, literary work and any other material"
                      )}{" "}
                      (“
                      <span className="fw-bold">{t("Content")}</span>”),{" "}
                      {t(
                        "or that you otherwise have (and will continue to have) full power, title, license, consent and authority, in relation to the User Content, that is necessary to access, import, copy, use, connect, perform, publish, transfer or license such User Content, whether by you and us or any of our affiliates;"
                      )}
                    </li>
                    <li>
                      {t(
                        "you have (and will retain) full power, title, licenses, consents and authority to permit WebComprehensive Services to access any websites, web pages and other online services for the purpose of importing, copying, displaying, uploading, transmitting or otherwise using your User Content."
                      )}
                    </li>
                    <li>
                      {t(
                        "the User Content is (and will remain) true, current, accurate, does not infringe the rights of any third party, and is not otherwise unlawful for you to upload, import, copy, possess, publish, transmit, display or otherwise use in the country in which you or visitors and users of your User Platform (“End Users”) reside, or for WebComprehensive or its End Users to access, import, copy, upload, use or possess in connection with the WebComprehensive Services;"
                      )}
                    </li>
                    <li>
                      {t(
                        "you have obtained all necessary consents and permissions under all applicable laws, in connection with the posting, transmission and publication of any personal information and/or the image or likeness of any person, entity or property that is part of the User Content, and that you adhere to all applicable laws."
                      )}
                    </li>
                    <li>
                      {t(
                        "you will comply with and adhere to WebComprehensive's Content Guidelines, which describe permitted and prohibited content on our service."
                      )}
                    </li>
                  </ol>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  Para poder utilizar nuestros servicios, hay ciertas
                  obligaciones y condiciones que debe cumplir.
                  <br />
                  <br />
                  Entre otras, necesita tener una cierta mayoría de edad legal,
                  residir y utilizar nuestros servicios en un lugar permitido.
                  <br />
                  <br />
                  Además, debe poseer todos los derechos de cualquier contenido
                  que suba o publique o al que accedamos, importemos o carguemos
                  a través de nuestros servicios, asegurarse de que dicho
                  contenido sea legal y confiable, y que cualquier cosa que haga
                  con él (o que permita que WebComprehensive o sus usuarios
                  finales hagan con él) sea legal.
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">2.2. {t("You agree and accept:")}</h5>
              <Col md={8}>
                <p>
                  <ol>
                    <li>
                      {t(
                        "fully comply with all applicable laws and contractual terms governing your use of the WebComprehensive Services (and any related interactions or transactions), including any specific laws applicable to you or your End Users in any of your geographic locations;"
                      )}
                    </li>
                    <li>
                      {t(
                        "be solely responsible with respect to any uses of the WebComprehensive Services that occur under your User Account or your User Platform(s) and for any of your User Content (including the consequences of accessing, importing, uploading, copying, using or publishing such User Content on or in connection with the WebComprehensive Services);"
                      )}
                    </li>
                    <li>
                      {
                        "regularly and independently save and back up your User Content and the information you are processing in connection with your User Website, including with respect to End Users, User Products and any Third Party applications or Services used by you;"
                      }
                    </li>
                    <li>
                      {t(
                        "receive periodic promotional messages and materials from WebComprehensive or its partners, by mail, email or any other form of contact you may provide to us (including your telephone number for calls or text messages). If you do not wish to receive these promotional materials or marketing messages, simply notify us at any time;"
                      )}
                    </li>
                    <li>
                      {t(
                        "allow WebComprehensive to use in perpetuity, worldwide and free of charge, any version of your User Website (or any part thereof) for any of WebComprehensive's online or offline promotional and marketing activities and to modify them as reasonably necessary for such purposes, and you waive any claim against WebComprehensive or anyone acting on WebComprehensive's behalf relating to any past, present or future moral rights, artist's rights or any other similar rights throughout the world you may have in or to your User Website in respect of such permitted limited uses;"
                      )}
                    </li>
                    <li>
                      {t(
                        "WebComprehensive's sole discretion as to the means, manner and method of providing the WebComprehensive Services, including with respect to the hosting, transmission, publication or display of any Platform or User Content (including the inclusion and presentation of any advertising messages or other commercial content in connection therewith)."
                      )}
                    </li>
                    <li>
                      {t(
                        "WebComprehensive shall have the right to offer the WebComprehensive Services under different pricing plans and to impose different restrictions on uploading, storing, downloading and using the WebComprehensive Services under each pricing plan, including but not limited to restrictions on network traffic and bandwidth, size or duration of Content, quality or format of Content, sources of Content, volume of download time, number of subscribers to Content, storage volume, etc."
                      )}
                    </li>
                  </ol>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">
                    {t("#ThatEasy")} - {t("List of Obligations")}
                  </div>
                  {t("You must comply with all applicable laws.")}
                  <br />
                  <br />
                  {t(
                    "You will be responsible for your actions and the actions of anyone who accesses your user account settings or user platform."
                  )}
                  <br />
                  <br />
                  {t("You should regularly back up your content.")}
                  <br />
                  <br />
                  {t(
                    "You agree that we or our partners may send you promotional messages and content."
                  )}
                  <br />
                  <br />
                  {t(
                    "You can easily opt out of receiving promotional messages by contacting us."
                  )}
                  <br />
                  <br />
                  {t(
                    "You allow us to use your website for our promotional activities and to determine how services will be provided."
                  )}
                  <br />
                  <br />
                  {t(
                    "You agree that WebComprehensive has the right to impose and modify pricing plans for its Services. In addition, WebComprehensive may impose restrictions based on your specific use of the Service."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                2.3. {t("You agree and undertake not to:")}
              </h5>
              <Col md={8}>
                <p>
                  <ol>
                    <li>
                      {t(
                        "copy, modify, reuse, create derivative works from, download, adapt, reverse engineer, emulate, migrate to another service, translate, compile, decompile or disassemble the WebComprehensive Website, the WebComprehensive Services (or any portion thereof), any Content offered by WebComprehensive or Third Party Services for use and display on the User Platforms (“Licensed Content”) or any portion thereof in any way, publicly display, perform, transmit or distribute any of the foregoing without WebComprehensive's prior specific written consent or as expressly permitted under the WebComprehensive Terms;"
                      )}
                    </li>
                    <li>
                      {t(
                        "use any unlawful action to collect login data and/or passwords for other websites, third parties, software or services,"
                      )}
                    </li>
                    <li>
                      {t(
                        "phish, harvest, collect, upload or otherwise make available credit card information or other forms of financial data used to receive payments, unless done so in accordance with any applicable law, including PCI DSS where applicable,"
                      )}
                    </li>
                    <li>
                      {t(
                        "post or make any use of the WebComprehensive Services or Licensed Content on any website, media, network or system other than as intended by WebComprehensive, or frame, “deep-link,” “page-scrape,” mirror or create a browser or border environment around any of the WebComprehensive Services, Licensed Content and/or User Platform (or any portion thereof), except as expressly permitted by WebComprehensive in advance and in writing;"
                      )}
                    </li>
                    <li>
                      {t(
                        "act in a manner that could be perceived as damaging to the reputation and goodwill of WebComprehensive or that could cause discredit or damage to WebComprehensive;"
                      )}
                    </li>
                    <li>
                      {t(
                        "buy search engine keywords or other pay-per-click (e.g., Google AdWords) advertising, or domain names using WebComprehensive or the WebComprehensive Marks or variations and misspellings thereof;"
                      )}
                    </li>
                    <li>
                      {t(
                        "reverse look-up, trace or attempt to trace another User of the WebComprehensive Services, interfere with or violate in any way another User's right to privacy or other rights, or harvest or collect personally identifiable information about visitors or users of the WebComprehensive Services and/or the User Platform without their express informed consent;"
                      )}
                    </li>
                    <li>
                      {t(
                        "disable, circumvent, bypass or otherwise avoid any measures used to prevent or restrict access to the WebComprehensive Services, the User Platform, other user(s) account(s), or other systems or networks connected to the WebComprehensive Services, using hacking, password mining or other illegitimate or prohibited means;"
                      )}
                    </li>
                    <li>
                      {t(
                        "probe, scan or test the vulnerability of the WebComprehensive Services or any network connected to the WebComprehensive Services;"
                      )}
                    </li>
                    <li>
                      {t(
                        "take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the WebComprehensive Services, WebComprehensive systems, or the networks connected to the WebComprehensive Services, or otherwise interfere with or disrupt the operation of any of the WebComprehensive Services, or the servers or networks that host them or make them available, or disobey any requirements, procedures, policies or regulations of such servers or networks;"
                      )}
                    </li>
                    <li>
                      {t(
                        "use any of the WebComprehensive Services or WebComprehensive Systems for video streaming or storage purposes (whether or not primarily for streaming purposes). For example, creating and operating websites whose purpose is video streaming or whose primary purpose is file storage. This includes indirect use through integration or connection with third party platforms or services;"
                      )}
                    </li>
                    <li>
                      {t(
                        "access WebComprehensive Services, User Accounts, Licensed Content or User Content, through any means or technology (e.g., scraping and crawling) other than our publicly available interfaces."
                      )}
                    </li>
                    <li>
                      {t(
                        "sell, license or exploit for any commercial purposes any use of or access to the Licensed Content or WebComprehensive Services, except as expressly permitted by the WebComprehensive Terms;"
                      )}
                    </li>
                    <li>
                      {t(
                        "remove or alter any copyright notices, watermarks, restrictions and proprietary rights notices of any of our licensors, including the copyright mark [©], Creative Commons [(cc)] indicators or trademarks [® or ™] contained in or accompanying the WebComprehensive Services or the Licensed Content; or"
                      )}
                    </li>
                    <li>
                      {t(
                        "violate, attempt to violate, or otherwise fail to comply with any of the WebComprehensive Terms or any applicable laws or requirements for your use of the WebComprehensive Services."
                      )}
                    </li>
                    <li>
                      {t(
                        "access or use the Services for competitive analysis or similar competitive analysis purposes or to develop a competing product or service."
                      )}
                      <br />
                      <br />
                      <div className="fw-bold">
                        {t(
                          "You acknowledge and agree that failure to comply with any of the foregoing or any misrepresentation made by you herein may result in immediate termination of your User Account or any Services provided to you - with or without notice and without any refund of any amounts paid for such Services."
                        )}
                      </div>
                    </li>
                  </ol>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">
                    {t("#ThatEasy")} - {t("List of Prohibitions")}
                  </div>
                  {t(
                    "In general, we just want you to be nice, and to avoid doing anything that might harm us or anyone else."
                  )}
                  <br />
                  <br />
                  {t(
                    "Among others, you may not copy our materials, use any content in an illegal or harmful manner, use our services or content on any other platform or website not provided by us, make false statements or abuse our services, or otherwise violate anyone's rights or applicable laws."
                  )}
                  <br />
                  <br />
                  {t(
                    "Failure to comply with any of these rules may result in us terminating your account and ceasing to provide you with services."
                  )}
                </small>
              </Col>
            </Row>
            {/* 3 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                3. {t("Content and ownership")}
              </h3>
              <hr />
              <h5 className="mb-4">3.1. {t("Your intellectual property")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "As between WebComprehensive and you, you will own all intellectual property related to your User Content and any other material created, developed or connected to the WebComprehensive Services by you, including any design, image, animation, video, audio file, font, logo, illustration, composition, artwork, code, algorithm, SPI, API, database, interface, text and literary work. WebComprehensive does not claim ownership rights in your User Content or the content you have connected to the WebComprehensive Services. You acknowledge and agree that in order to provide the Services and to maintain and improve the Services (the “Purpose”), we need to access, upload or copy your User Content to our platform, including cloud services and CDNs, to make display adjustments, to improve our software tools (e.g., artificial intelligence or machine learning models), to make backup duplicates, and to make any other technical actions or uses necessary for the provision of our services, in our sole discretion. You hereby grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to use your User Content for such Purpose."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "You have all rights to your content. In order to provide you with our services, we manipulate, store, back up and copy your content between servers."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                3.2. {t("Intellectual property of WebComprehensive")}
              </h5>
              <Col md={8}>
                <p>
                  {t(
                    "All rights, title and interest in and to the WebComprehensive Services, including any and all copyrightable materials or other content thereon that is or may be subject to intellectual property rights under any applicable law (including artwork, graphics, images, website templates and widgets, literary works, source and object code, computer code (including html), applications, audio, music, video and other media, designs, animations, interfaces, the look and feel of the Services, WebComprehensive's methods, products, algorithms, data, interactive functions and objects, advertising and acquisition tools and methods, inventions, trade secrets, logos, domains, custom URLs, trademarks, service marks, trade names and other proprietary identifiers, whether or not registered or capable of being registered (collectively, “Intellectual Property”), and any derivatives thereof, are owned by or licensed to WebComprehensive."
                  )}
                  <br />
                  {t(
                    "Subject to your full compliance with the WebComprehensive Terms and timely payment of all applicable Fees, WebComprehensive hereby grants you, upon creation of your User Account and for as long as WebComprehensive chooses to provide you with the WebComprehensive Services, a non-exclusive, non-transferable, non-sublicensable, limited, fully revocable license to use the WebComprehensive Services and the Licensed Content for the purpose of generating and displaying your User Platform to End Users and offering your User Products (as defined below) thereon, solely as expressly permitted under the WebComprehensive Terms, and solely within the WebComprehensive Services."
                  )}
                  <br />
                  {t(
                    "The WebComprehensive Terms do not convey any right or interest in or to the WebComprehensive Intellectual Property (or any part thereof), except only for the limited license set forth above. Nothing in the WebComprehensive Terms constitutes an assignment or waiver of WebComprehensive's Intellectual Property rights under any law."
                  )}
                  {/* <br />
                  Además de lo anterior, ciertas fuentes puestas a su
                  disposición dentro de los servicios de WebComprehensive, están
                  licenciadas a WebComprehensive por un tercero proveedor y
                  están por lo tanto sujetas a términos de licencia adicionales
                  de tal proveedor, los cuales están resumidos y disponibles
                  para su revisión en
                  https://es.WebComprehensive/about/mono-terms-of-use. */}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We own all rights in and to our services, content, data, technology and features."
                  )}
                  <br />
                  <br />
                  {t(
                    "You may use our services and content provided you fully comply with these terms and ensure full and timely payments."
                  )}
                  <br />
                  <br />
                  {
                    "Certain fonts that are available to you are licensed by a third party, be sure to read their terms as well."
                  }
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">3.3. {t("Comments and suggestions")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "If you provide us with suggestions, comments or other information relating to the WebComprehensive Services (whether existing, suggested or contemplated), which are or may be subject to Intellectual Property rights"
                  )}{" "}
                  (“
                  <span className="fw-bold">Feedback</span>”),{" "}
                  {t(
                    "this Feedback shall be the exclusive property of WebComprehensive. By submitting such Feedback to WebComprehensive, you acknowledge and agree that it may be used by WebComprehensive to (i) continue to develop, customize, and improve the WebComprehensive Services, (ii) provide ongoing technical assistance and support, (iii) contact you with news and/or general or personalized interview requests relating to WebComprehensive that may or may not be based on your opinions, (iv) facilitate, sponsor, and offer certain promotions, and monitor performance, (v) generate aggregated statistical data and other aggregated and/or inferred information, which WebComprehensive may use to provide and improve its Services, (vi) enhance WebComprehensive's data security and fraud prevention capabilities, and (vii) comply with all applicable laws and regulations. In addition, you (1) represent and warrant that such Feedback is accurate, complete, and does not infringe the rights of any third party; (2) you irrevocably assign to WebComprehensive all right, title and interest you may have in such Feedback, and (3) you expressly and irrevocably waive any and all claims relating to any past, present or future moral rights, artist's rights or any other similar rights throughout the world in or to such Feedback."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We encourage you to send us any form of feedback or suggestions. If you send us any of these, please make sure that it is accurate and legal."
                  )}
                </small>
              </Col>
            </Row>
            {/* 4 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">4. {t("Privacy")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "Certain parts of the WebComprehensive Services (including certain Third Party Services available on them, as explained in more detail in Section 10 below) require or involve the submission, collection and/or use of certain personally identifiable or personally identifiable information. In particular, as part of accessing or using the WebComprehensive Services, WebComprehensive and such Third Party Services may collect, access and use certain data regarding Users and End Users, including the activities or navigation undertaken by Users and End Users through the WebComprehensive services and/or User Platforms. We encourage you to regularly review our Privacy Policy and the relevant policies of each of these Third Party Services for a description of such data collection and use practices."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We care about your privacy, and you should too. Please read our Privacy Policy to learn more about our practices regarding personal information."
                  )}
                </small>
              </Col>
            </Row>
            {/* 5 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">5. {t("AI Services")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "As part of the WebComprehensive Services, WebComprehensive may provide you with access to artificial intelligence and machine learning tools and products, which will assist you in building your User Platform by generating Content (the “AI Tools”). Additionally, WebComprehensive may provide you with access to artificial intelligence and machine learning tools and products for you to enable and thereby offer your End Users the option to interact with AI (“AI Products”, and together with AI Tools - “AI Services”). This section applies only to the extent that you use AI Services as part of the WebComprehensive Services."
                  )}
                </p>
              </Col>
              <Col className="ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t("Some of our services include AI tools and products.")}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">5.1. {t("Input and result.")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "The AI Services allow you or your End Users to input, provide, or send a message (in the form of text, image, or otherwise) (an “Input”) to the AI Services and thereby direct the AI Services to generate Content as a result (an “Output”)."
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">5.2. {t("Accuracy of the Result.")}</h5>
              <Col md={8}>
                {t(
                  "The nature of AI technology is such that it is difficult to fully control and predict outcomes. As such, WebComprehensive does not represent or warrant that the Output generated by the AI Services will meet your or your End Users' needs or expectations, and we disclaim all liability for the accuracy, completeness, relevance, intellectual property compliance, legality, decency, quality, fairness, or any other aspect of such Output. This may occur, for example, in cases where the Output generated relates to medical, legal, or financial matters. The Output may also violate another person's rights, such as privacy or intellectual property rights. The Output may not be unique to you or your End Users, and other Users of the AI ​​Services may generate their own Output that is identical or similar to the Output that may be generated for you, or your End Users. Respectively:"
                )}
                <ol>
                  <li>
                    {t(
                      "You are responsible for reviewing all Output from the AI ​​Tools before using, posting, transmitting, or displaying an Output in any manner, and ensuring that you are legally authorized to use such Output."
                    )}
                  </li>
                  <li>
                    {t(
                      "You must treat the Output as merely a suggestion and decide in your sole discretion whether or not it represents your views, serves your intended purposes, and conveys the message you wish to convey."
                    )}
                  </li>
                  <li>
                    {t(
                      "You are responsible for informing your End Users, where required by applicable laws and regulations, of any restrictions, limitations, or obligations that End Users must comply with when using the Output from the AI ​​Products, including the need to review the Output before using it."
                    )}
                  </li>
                  <li>
                    {t(
                      "You acknowledge that the Output generated does not represent the opinions of WebComprehensive."
                    )}
                  </li>
                </ol>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "The results generated by these tools may not be accurate, truthful, legal or unbiased. Please verify the result before using it, because the result is just a suggestion."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">5.3. {t("Your entries.")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "You agree that your and your End Users' Submissions will be considered User Content (as defined above) and the Terms will apply, to the extent permitted by applicable law, to all such Submissions as if they were User Content."
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">5.4. {t("Ownership of the Result.")}</h5>
              <Col md={8}>
                <p className="pb-3">
                  {t(
                    "If you choose to use, post, transmit or display a Result in any manner, it will be considered your User Content (as defined above), and the Terms will apply, to the extent permitted by applicable law, as if it were User Content. As between you (or your End Users) and WebComprehensive, WebComprehensive does not claim any ownership rights in the Result to the extent the Result does not contain any pre-existing intellectual property owned by WebComprehensive."
                  )}
                </p>
                <h5 className="my-4">
                  5.5. {t("License granted to WebComprehensive.")}
                </h5>
                <p>
                  {t(
                    "To the extent a license is required under applicable laws and regulations, and in addition to the license you grant to us under Section 3.1 above, you also grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide license to: (a) access, upload, copy, perform and display adjustments to, train our software tools on, duplicate, and perform any other technical actions and/or uses with your (and your End Users') Inputs and Results in order to: improve WebComprehensive's services; review Inputs and Results for compliance with applicable laws and enforce the Terms; and (b) provide copies of your (and your End Users') Inputs and Results to our Third Party Services or our subcontractors as required to provide the WebComprehensive Services to our Users."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Although we do not claim ownership of the results generated, you grant us a license to use them to improve our services and to share them with third-party providers necessary for the operation of those services."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">5.6. {t("Third party services.")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive may use Third Party Services to provide the AI ​​Services to you and your use of such Third Party Services shall be subject to Section 10 of these Terms. In your use of WebComprehensive's AI Services, including your use of any Input and Output, you acknowledge and agree to review and comply with, and require each of your End Users to review and comply with, the policies of the Third Party AI Services as they may change from time to time."
                  )}
                </p>
              </Col>
            </Row>
            {/* 6 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">6. {t("Service charges")}</h3>
              <hr />
              <h5 className="mb-4">6.1. {t("Paid services")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "Use of certain WebComprehensive Services may be subject to payment of specific fees, as determined by WebComprehensive in its sole discretion"
                  )}{" "}
                  (“
                  <span className="fw-bold">{t("Paid Services")}</span>”{" "}
                  {t("and")} “<span className="fw-bold">{t("Rate(s)")}</span>”,
                  {t(
                    "respectively). Important: If you are a Reseller User and your payment is made to the Reseller, then the provisions of paragraphs 6.1 (1), 6.1 (4), 6.2, 6.3 (2), 6.4 (1) and 6.5 of this Section 6 in relation to such specific Payment Services may not apply to you and payment and management of such Payment Services will be made directly with the Reseller, according to the terms agreed between you and the Reseller."
                  )}
                  <br />
                  1.{" "}
                  {t(
                    "WebComprehensive will notify you of the applicable Fees; you must pay WebComprehensive directly in connection with the Paid Services if you choose to purchase from WebComprehensive. If you wish to receive or use the Paid Services, you must pay all applicable Fees in advance."
                  )}
                  <br />
                  2.{" "}
                  {t(
                    "WebComprehensive reserves the right to change its Fees at any time at its discretion. WebComprehensive will provide notice of the change to you or Reseller if such change will affect your existing subscriptions."
                  )}
                  <br />
                  3.{" "}
                  {t(
                    "If you have received a discount or other promotional offer, whether from WebComprehensive or a Reseller, WebComprehensive or the Reseller, respectively, will have the right, upon expiration of the applicable discount period, to automatically and without prior notice renew your subscription for such WebComprehensive Services at the then-current full Rate."
                  )}
                  <br />
                  4.{" "}
                  {t(
                    "All Fees paid directly to WebComprehensive will be in U.S. dollars, unless WebComprehensive specifically states otherwise in writing. To the extent permitted by law (and unless WebComprehensive specifically states otherwise in writing), all Fees are exclusive of taxes (including value-added tax, sales tax, goods and services tax, etc.), duties or tariffs imposed by taxing authorities (“Taxes”), and you will be responsible for paying all applicable Taxes related to your use of the WebComprehensive Services or any payments or purchases you make. If WebComprehensive is obligated to collect or pay Taxes on Fees paid by you, whether or not such Taxes have been added and collected from you on prior transactions, such Taxes may be added to the payment for any outstanding Fees and will be reflected on the Invoice for such transaction. We recommend that you confirm any additional fees that may be charged by third parties in connection with your purchase of Paid Services or renewal (such as international transaction fees, currency exchange fees, or fees charged by banks or credit card companies). WebComprehensive is not responsible for any such additional fees or costs."
                  )}
                  <br />
                  5.{" "}
                  {t(
                    "If you purchase Payment Services directly from WebComprehensive, then as part of registering or submitting information to receive Payment Services, you also authorize WebComprehensive (whether directly or through its affiliates, subsidiaries or other third parties) to request and collect payments and service fees (or otherwise charge, refund or take any other billing action) from our payment provider or your designated bank account and to make such inquiries as WebComprehensive or its affiliates deem necessary to validate your designated payment account or financial information to ensure timely payment, and to receive updated payment details from your payment provider, credit card or bank account (e.g., updated expiration date or card number as may be provided to us by your credit card company)."
                  )}
                  {/* <br />
                  6. Debe tener una tarjeta de crédito almacenada con
                  WebComprehensive para pagar los Servicios de Pago (“Tarjeta
                  Almacenada”) que se compren directamente a WebComprehensive.
                  Podrá identificar su Tarjeta Almacenada mediante los últimos
                  cuatro dígitos en su Página de Configuración de la Cuenta. */}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "If you are a Reseller User, payment and administration of the Payment Service must be made directly with the Reseller."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">6.2. {t("Invoices")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "If you are a Reseller User and you pay the Reseller and not WebComprehensive directly, Clause 6.2 does not apply to you."
                  )}
                  <br />
                  {t(
                    "WebComprehensive or its affiliated companies will issue an invoice or credit memo for any Fee payment or refund made to or by WebComprehensive"
                  )}{" "}
                  (“
                  <span className="fw-bold">{t("Invoice")}</span>”).{" "}
                  {t(
                    "Each Invoice will be issued electronically and in accordance with the country indicated in your billing address, and will be made available to you through your User Account or by email. For the purposes of issuing the Invoice, you may be required to provide certain Personal Information (as defined in the Privacy Policy) in order to comply with local laws. Please note that the Invoice presented in your User Account may be inappropriate according to the requirements of your local laws and, in such case, may only be used as a pro forma invoice."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Invoices for our paid services will be available in your user account, unless you are a Reseller User."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                6.3. {t("Automatic subscription renewals")}
              </h5>
              <Col md={8}>
                <p>
                  1.{" "}
                  {t(
                    "To ensure that you do not experience any interruption or loss of services, certain Paid Services include a default auto-renewal option whereby, unless you or your Reseller disables the auto-renewal option, such Paid Services will automatically renew at the end of the applicable subscription period for a renewal term equal to the original subscription term (excluding extension periods) and, unless otherwise notified to you (by WebComprehensive or your Reseller), at the same price (subject to applicable changes in Taxes and excluding any discounts or other promotional offers provided for the initial term) (“Renewing Paid Services”). For example, if the original subscription term for a Service is one month, then each of its renewal terms (as applicable) will be one month."
                  )}
                  <br />
                  2.{" "}
                  {t(
                    "Accordingly, as applicable and in connection with Fees paid directly to WebComprehensive, WebComprehensive will attempt to automatically charge the applicable Fees to the Stored Card up to two (2) weeks prior to the commencement of such renewal period. In the event of failure to collect any Fees owed, we may, at our discretion (but are not obligated to), retry the charge at another time or suspend or terminate your User Account, without further notice. If your Renewing Paid Service is subject to a one-year or multi-year subscription period, WebComprehensive will attempt to provide you with notice prior to the renewal of such Paid Service at least thirty (30) days prior to the renewal date."
                  )}
                  <br />
                  3. {t("By accepting these Terms of Use and")}{" "}
                  <span className="fw-bold">
                    {t("buy a Paid Service with Renewal")}
                  </span>
                  , {t("declares")}{" "}
                  <span className="fw-bold">{"and accepts"}</span>{" "}
                  {t("that the")}
                  <span className="fw-bold">
                    {t("Paid Service with Renewal")}
                  </span>{" "}
                  {t(
                    "will be automatically renewed in accordance with the terms mentioned."
                  )}
                  <br />
                  4.{" "}
                  {t(
                    "You (or the Reseller from whom you purchase Paid Services) may turn off the automatic renewal option for Paid Services with Renewal at any time within your User Account or by visiting the WebComprehensive Help Center."
                  )}
                  <br />
                  5.{" "}
                  {t(
                    "Certain domains are subject to a different renewal policy as detailed in the Web.com Reseller Domain Name Registration Agreement and the Tucows Reseller Domain Name Registration Agreement, as applicable."
                  )}
                  <br />
                  6.{" "}
                  {t(
                    "Notwithstanding anything to the contrary in the foregoing, you are, and shall remain, solely responsible for verifying and ensuring successful renewal of the WebComprehensive Services you use (whether or not such WebComprehensive Services are subject to automatic subscription renewals). Accordingly, you shall be fully liable in connection with any suspension of any WebComprehensive Services you have previously purchased, including in the event of cancellation, failure to collect applicable Recurring Fees, or failure to automatically renew your subscription for any WebComprehensive Services. You acknowledge and agree that you shall have no claim against WebComprehensive in connection with the suspension of any WebComprehensive Services or Third Party Services, for any reason."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "To ensure that you do not lose your domain or experience interruptions with your website at the end of your subscription period"
                  )}
                  ,{" "}
                  <span className="fw-bold">
                    {t(
                      "we will automatically renew our service and bill you accordingly, at regular intervals based on your initial subscription, unless you (or the Reseller from whom you purchased Paid Services) turn off auto-renewal."
                    )}
                  </span>
                  <br />
                  <br />
                  <br />
                  <br />
                  {t("Some services may not")}{" "}
                  <span className="fw-bold">{t("renew automatically")}</span>,
                  {t(
                    "whether on purpose or by mistake. You must ensure that your subscriptions are renewed on time."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">6.4. {t("Money back guarantee")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "If you are a Reseller User and you pay the Reseller and not WebComprehensive directly, Clause 6.4 does not apply to you."
                  )}
                  <br />
                  1.{" "}
                  {t(
                    "If you are dissatisfied with your initial purchase of a Premium Plan, you may provide notice of cancellation for any reason within fourteen (14) days of first ordering or activating such Premium Plan (the “Refund” and “Refund Period”). The Refund will only apply to the initial purchase of a Premium Plan that is a free website upgrade. The Refund does not apply to any additional purchase, upgrade, modification or renewal of WebComprehensive Services. If you live in a jurisdiction that requires a longer Refund Period, we will accommodate such requirements under all applicable laws. If WebComprehensive receives such notice within the Refund Period, WebComprehensive will refund to you the amount WebComprehensive charged you for such WebComprehensive Services, in the same currency as the original charge, and cancel the services accordingly. Please note that the Refund amount may be different than the amount charged due to currency exchange rates and third party fees. WebComprehensive will not be liable for any difference caused by a change in currency exchange rates or third party fees. After the Refund Period, the Fees paid by you will be non-refundable and non-cancellable. In addition, if we determine that a User provided a cancellation notice in bad faith or in an unlawful attempt to avoid payment for services actually received, we reserve our right to still charge the User for all WebComprehensive Services actually received, to the extent permitted by applicable law."
                  )}
                  <br />
                  2. <span className="fw-bold">{t("Note")}</span>:{" "}
                  {t(
                    "Certain services purchased on or through the WebComprehensive Services may be non-refundable. This includes Third Party Services, such as domains, tools, and commerce applications. The terms of each purchased service or application are stated on the WebComprehensive Website or as part of or during the purchasing process for such services or applications. It is your responsibility to verify the ability to cancel a service before purchasing it. WebComprehensive will not refund any amounts paid for non-refundable Paid Services, applications, or Third Party Services."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We are happy to offer a 14-day money-back guarantee for our monthly or annual paid services when purchased for the first time. This can be extended subject to law."
                  )}
                  <br />
                  <br />
                  {t(
                    "Please carefully review the terms of each service before purchasing, as some services are non-refundable."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">6.5. {t("Chargebacks")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "If at any time we record a decline, chargeback or other rejection of a charge for the Fees Payable on your WebComprehensive account"
                  )}{" "}
                  (“
                  <span className="fw-bold">{t("Chargeback")}</span>”),{" "}
                  {t(
                    "this will be deemed a breach of your payment obligations hereunder, and your use of WebComprehensive's services may be automatically disabled or terminated."
                  )}
                  <br />
                  <br />
                  {t(
                    "In the event of a Chargeback, your User Account may be blocked without the option to repurchase or reuse it, and the data contained in such User Account, including domains, applications and Third Party Services may be subject to cancellation and Loss of Capacity (as defined in Section 6.3 below)."
                  )}
                  <br />
                  <br />
                  {t(
                    "Your use of the WebComprehensive Services will not resume until you re-subscribe to the WebComprehensive Services and pay any applicable charges in full, including all Fees and expenses incurred by WebComprehensive or any Third Party Service for each Chargeback received (including Fees for WebComprehensive Services provided prior to the Chargeback, handling and processing fees, and expenses incurred by the payment processor)."
                  )}
                  <br />
                  <br />
                  {t(
                    "If you have any questions or concerns regarding a payment made to WebComprehensive"
                  )}
                  ,{" "}
                  <span className="fw-bold">
                    {t(
                      "we encourage you to first contact our Customer Service team before filing a Chargeback or Payment Reversal."
                    )}
                  </span>
                  ,{" "}
                  {t(
                    "to prevent cancellation of WebComprehensive Services and blocking of your User Account, and to prevent unjustified or erroneous filing of a Chargeback, which may result in you being liable for applicable Charges, in addition to repayment of all Fees applicable to WebComprehensive Services purchased (and Chargebacked) by you."
                  )}
                  <br />
                  <br />
                  {t(
                    "We reserve the right to dispute any Chargeback received, including providing the relevant credit card company or financial institution with information and documentation proving that the User responsible for such Chargeback actually authorized the transaction and made use of the services provided thereafter."
                  )}
                </p>
              </Col>
              <Col>
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Chargebacks may result in us cancelling your account, so please avoid doing so."
                  )}
                  <br />
                  {t(
                    "Please contact our Customer Care team before filing a chargeback. You will be responsible for any incorrect fees, and any losses and expenses that follow."
                  )}
                </small>
              </Col>
            </Row>
            {/* 7 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">7. {t("Cancellation")}</h3>
              <hr />
              <h5 className="mb-4">7.1. {t("Cancellation by the User")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "You may stop using and request cancellation of your User Account or the WebComprehensive Services at any time, in accordance with the instructions available in the WebComprehensive Services. The effective date and time for such cancellation will be the date and time you complete the cancellation process for the WebComprehensive Services, and the effective date of cancellation for Paid Services will be the end of your subscription period for the Paid Services. Notwithstanding the foregoing, any Paid Services (including Renewing Paid Services) purchased from a Reseller are subject to the cancellation terms agreed upon between you and your Reseller."
                  )}
                  <br />
                  {t(
                    "Notwithstanding anything to the contrary in the foregoing, in relation to subscriptions for Paid Services with Renewal, such subscriptions will be suspended only upon expiration of the relevant period for which you have already paid. Please note that as the process may take a few days, to avoid the next automatic renewal and the corresponding charge, the cancellation request must be made at least fourteen (14) days before the expiration of the current service period."
                  )}
                  <br />
                  {t(
                    "For more information about canceling Paid Services you have purchased from WebComprehensive, please visit the WebComprehensive Help Center."
                  )}
                  <br />
                  <br />
                  {t(
                    "For information about canceling Paid Services you purchased from a Reseller, please contact your Reseller."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "You may cancel your account or any of the Services at any time. Once we process your cancellation request, we will not charge you for any additional subscription renewals. Cancellation of Paid Service purchased from a Reseller is subject to the terms agreed upon between you and your Reseller."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                7.2. {t("Cancellation by WebComprehensive")}
              </h5>
              <Col md={8}>
                <p>
                  {t(
                    "Your breach of any of the WebComprehensive Terms or failure to pay any Fees due will give WebComprehensive the right to suspend (until full payment is made) or terminate your User Account and User Platform (or certain features thereof), as well as the provision of related WebComprehensive Services (e.g., Paid Services) or Third Party Services to you."
                  )}
                  <br />
                  {t(
                    "Si abre una Cuenta de Usuario a través de un Revendedor o compra Servicios de Pago a un Revendedor, entonces WebComprehensive tendrá derecho a suspender, cerrar o bloquear el acceso a su Cuenta de Usuario y Plataforma de Usuario (o determinadas funciones de estas) además de la entrega de cualquier Servicio de WebComprehensive o Servicios de Terceros relacionados, en el caso de que: (a) el Revendedor lo solicite a WebComprehensive; o (b) el Revendedor no pague a WebComprehensive cualquier monto adeudado a WebComprehensive en virtud del Contrato del Revendedor. Usted acepta estos derechos de suspensión y cierre y reconoce y acepta que WebComprehensive no tendrá responsabilidad alguna hacia usted en relación con dicha suspensión o cierre. Su único recurso en relación con dicha suspensión o cierre será contra el Revendedor."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "If you violate any of these terms or fail to make timely payments, we may suspend or terminate your account."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                7.3. {t("Loss of data, content and capacity")}
              </h5>
              <Col md={8}>
                <p>
                  {t(
                    "If your User Account or any WebComprehensive Services or Third Party Services related to your User Account are terminated (whether at your request or at WebComprehensive’s discretion), this may cause or result in the loss of certain content, features or capacity of your User Account, including any User Content, End User data or other usage data held therein, and any domain name registrations or reservations held therein (“Capacity Loss”). WebComprehensive shall have no liability whatsoever for such Capacity Loss, or for backing up your User Account, User Content or End User data. Please also note that additional Fees may apply to reactivating a User Account or the WebComprehensive Services following your termination, as determined by WebComprehensive in its sole discretion."
                  )}
                  <br />
                  {t(
                    "Following the closure of your User Account or User Platform, WebComprehensive reserves the right to delete all data in the normal course of business. Data cannot be recovered once your User Account or User Platform is closed."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "If your account or any of your services are terminated, loss of content and data may occur."
                  )}
                  <br />
                  {t(
                    "You are responsible for backing up your data and materials."
                  )}
                </small>
              </Col>
            </Row>
            {/* 8 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">8. {t("E-commerce")}</h3>
              <hr />
              <h5 className="mb-4">8.1. General</h5>
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive Services also include certain features that enable you to sell goods, content, media, event tickets and services through your User Platform"
                  )}{" "}
                  (“
                  <span className="fw-bold">{t("User Products")}</span>”{" "}
                  {t("and, together with the WebComprehensive Services")}, “
                  <span className="fw-bold">{t("E-commerce")}</span>”).
                  <br />
                  {t(
                    "You are fully responsible for your User Products and E-Commerce activities, and for any promotions and related Content contained within or referenced by your User Platform, and for compliance with all applicable laws. We only provide you with the platform to manage your online E-Commerce activities. We are not involved in your relationship or dealings with any actual or potential purchasers of your User Products."
                  )}
                  <br />
                  {t(
                    "When someone purchases your User Products, payments for such transactions will be processed through WebComprehensive or a third-party payment service provider."
                  )}{" "}
                  (“
                  <span className="fw-bold">
                    {t("Payment Service Provider(s)")}
                  </span>
                  ”).
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "You may use our services to sell your products, content and services online."
                  )}
                  <br />
                  <br />
                  {t(
                    "WebComprehensive can connect you to allow you to accept payments from your customers."
                  )}
                  <br />
                  <br />
                  {t(
                    "You are responsible for all of your sales activities, including your relationships with customers and any payment service providers."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">8.2. {t("Payment Service Providers")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "You acknowledge and agree that WebComprehensive will be your default payment service provider."
                  )}
                  <br />
                  {/* La cuenta de WebComprehensive se regirá por los Términos de
                  Servicio de WebComprehensive, que se incorporan al presente
                  por referencia. Lea los términos de uso del servicio antes de
                  publicar su sitio y aceptar pagos.
                  <br /> */}
                  {t(
                    "Additionally, subject to territorial limitations, you must accept payments using Apple Pay. By using Apple Pay, you acknowledge and agree to the Apple Pay Platform Web Merchant Terms and Conditions."
                  )}
                  <br />
                  {t(
                    "Payment processing through other Payment Service Providers will be governed by the terms of service of such Payment Service Providers. We are not a party to your relationship with the Payment Provider and you are responsible for any interaction with any such Payment Provider, or the actions of any such Payment Provider."
                  )}
                  <br />
                  {t(
                    "WebComprehensive may suspend, disable access to, or remove from your User Account, User Platform(s) and/or the WebComprehensive Services any Payment Provider, whether or not incorporated into or forming part of your User Account and/or User Platform(s) at that time, without any liability to you or any End User."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We may charge service fees for event tickets sold through your site."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">8.3. {t("Events")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "Depending on your plan, WebComprehensive may charge you service fees for tickets to events sold on your site. You hereby agree to pay such fees, as required by WebComprehensive, and authorize WebComprehensive to direct its payment processing partners or your Payment Service Provider, as applicable, to deduct such fees from your relevant transactions or otherwise collect such fees."
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                8.4.{" "}
                {t("Declarations and guarantees regarding electronic commerce")}
              </h5>
              <Col md={8}>
                <p>
                  {t(
                    "By using any of our E-Commerce features, you represent, warrant and agree as follows:"
                  )}
                  <br />
                  1.{" "}
                  {t(
                    "that you are solely and fully responsible for all Taxes and charges of any kind associated with your E-Commerce activities, including all Taxes related to the purchase or sale of User Products, and for collecting, reporting and remitting the correct amounts to the appropriate authorities or reporting such acts to your End Users and providing a duly issued invoice as required by law;"
                  )}
                  <br />
                  2.{" "}
                  {t(
                    "that all Taxes indicated by the E-Commerce functions delivered to you by WebComprehensive are only provided for exemplary purposes and cannot be used as references;"
                  )}
                  <br />
                  3.{" "}
                  {t(
                    "that you are responsible for all costs for the purchase and delivery of the User Products and that you deliver them in a safe and professional manner, consistent with industry standards;"
                  )}
                  <br />
                  4.{" "}
                  {t(
                    "that you are fully responsible for all representations and promises you make and for all assistance, warranty and support in connection with the User Products, and that you will provide actual contact information on your User Platform for any questions, complaints or claims; and"
                  )}
                  <br />
                  5.{" "}
                  {t(
                    "that you may not offer or sell User Products, or provide any information, Content or materials in connection with User Products, that may be deemed dangerous, counterfeit, stolen, fraudulent, offensive or abusive; that are prohibited for sale, distribution or use; or that are otherwise contrary to any applicable law, including in relation to consumer rights, intellectual property or privacy rights, product safety, regulations and trade sanctions, support, maintenance and export; and"
                  )}
                  <br />
                  6.{" "}
                  {t(
                    "that WebComprehensive may, at any time and in its sole discretion, suspend, disable access to, or remove your User Platform or any User Product, whether or not incorporated, published or integrated with your User Platform at such time, without any liability to you or any End User, including for any Loss of Capacity as a result thereof."
                  )}
                </p>
              </Col>
            </Row>
            {/* 9 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">9. {t("Video Services")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "As part of WebComprehensive's services, WebComprehensive may provide video services"
                  )}{" "}
                  {/*  (tales como WebComprehensive vídeo o WebComprehensive Pro Gallery) */}
                  {t(
                    "for the management of videos on the User Platforms (the “"
                  )}
                  <span className="fw-bold">{t("Video Services")}</span>”).
                  <br />
                  <br />
                  {t(
                    "Your use of the video services for your User Platform may require you to obtain a license to use certain MPEG-LA patents (the “License”). It is your sole responsibility to determine whether your activity requires a License and to obtain one. You may obtain information about MPEG LA L.L.C.'s License at MPEG LA L.L.C."
                  )}
                  <br />
                  <br />
                  {t(
                    "In addition to anything set forth in Section 15 of these Terms of Use and without any limitation of liability, you shall indemnify, defend and hold harmless WebComprehensive, its officers, directors, shareholders, employees, affiliates and agents, from and against any and all damages, costs, obligations, losses, liabilities and expenses (including attorneys' fees), accrued, arising from or relating to your infringement or misuse of the patent coverage under the MPEG-LA consortium."
                  )}
                  <br />
                  <br />
                  {t(
                    "If your needs require a more inclusive plan than what we currently offer, please contact the support team at: business@webcomprehensive.com."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Certain use of WebComprehensive Video Services may require a license for the use of certain patents. You are required to verify whether you are required to obtain such a license."
                  )}
                  <br />
                  <br />
                  {t(
                    "You may be required to obtain a license for certain online video activities. It is your responsibility to check for such a requirement and obtain this license if necessary."
                  )}
                </small>
              </Col>
            </Row>
            {/* 10 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                10. {t("Third party services")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive Services allow you to engage and acquire certain third-party services, products, and tools to enhance your User Platform and overall user experience, including, without limitation, domain registrars from which you may purchase a domain name for your User Website, third-party applications and widgets offered through the WebComprehensive Website"
                  )}
                  {/* (incluyendo el WebComprehensive App Market o
                  Mercado de Aplicaciones de WebComprehensive) */}
                  ,{" "}
                  {t(
                    "Third-party Licensed Content, media distribution services, vendors of tangible products, third-party designers who may assist you with your User Platform, external databases, code packages, etc. (collectively"
                  )}
                  , “
                  <span className="fw-bold">{t("Third Party Services")}</span>
                  ”).
                  <br />
                  <br />
                  {t(
                    "You acknowledge and agree that regardless of the manner in which such Third Party Services may be offered to you (including or integrated with certain WebComprehensive Services, offered separately by WebComprehensive or persons certified or authorized by WebComprehensive, or otherwise offered anywhere on the WebComprehensive Services), WebComprehensive in no way endorses such Third Party Services, nor shall it be in any way responsible or liable for them. WebComprehensive shall not be a party to, nor in any way responsible for monitoring, any interaction or transaction between you and any Third Party Service."
                  )}
                  <br />
                  <br />
                  {t(
                    "You acknowledge that such services may require payment of additional amounts to WebComprehensive and/or the providers of such Third Party Services."
                  )}
                  <br />
                  <br />
                  {t(
                    "Any and all use of such Third Party Services shall be made solely at your own risk and responsibility and may be subject to the legal and financial terms governing such Third Party Services, which we encourage you to review before engaging with them."
                  )}
                  <br />
                  <br />
                  {t(
                    "If you use any third party services, tools, code, algorithms, databases, products, programs or assets while using our Services, you represent that you are acting in accordance with their terms of use. For example, if you use YouTube while using the Services, you must comply with YouTube's applicable terms and privacy policy, as amended at the time of your use of such services."
                  )}
                  <br />
                  <br />
                  {t(
                    "Although we hope to avoid such instances, WebComprehensive may, at any time and in its sole discretion, suspend, disable access to, or remove from your User Account, User Platforms or the WebComprehensive Services any third party services, whether or not incorporated with or made part of your User Account or User Platform(s) at that time, without any liability to you or End Users."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Our Services allow you to obtain various third-party services and tools that are not affiliated with us."
                  )}
                  <br />
                  <br />
                  {t(
                    "Since we only act as an intermediary platform between you and such parties, you alone will be responsible for your dealings with them."
                  )}
                  <br />
                  <br />
                  {t(
                    "We may remove services at any time that may affect your website, user account or overall experience."
                  )}
                </small>
              </Col>
            </Row>
            {/* 11 */}
            {/* <Row>
              <h3 className="fw-bold pb-2 mt-5">
                11. WebComprehensive Logo Maker
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  Como parte de los servicios de WebComprehensive, le permitimos
                  mejorar su marca creando su propio logotipo comercial
                  personalizado (el “WebComprehensive Logo”) usando nuestro
                  WebComprehensive Logo Maker. Los términos adicionales
                  disponibles en:
                  https://es.WebComprehensive/about/logo-terms-of-use se aplican
                  al uso de WebComprehensive Logo Maker, y son una adición a, y
                  no una sustitución de, los Términos de WebComprehensive, salvo
                  que se indique expresamente lo contrario.
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  Asegúrese de leer los términos adicionales con respecto al
                  WebComprehensive Logo Maker que son adicionales a nuestras
                  condiciones generales.
                </small>
              </Col>
            </Row> */}
            {/* 12 */}
            {/* <Row>
              <h3 className="fw-bold pb-2 mt-5">12. WebComprehensive Studio</h3>
              <hr />
              <Col md={8}>
                <p>
                  Como parte de los Servicios WebComprehensive, usted puede
                  registrarse en WebComprehensive Studio y obtener acceso al
                  espacio de trabajo WebComprehensive Studio ("Espacio de
                  Trabajo WebComprehensive Studio"). El Espacio de Trabajo
                  WebComprehensive Studio solo está disponible para Usuarios que
                  sean agencias o freelancers que construyan sitios o
                  aplicaciones para otros (incluyendo aquellos que construyan
                  para clientes empresariales). WebComprehensive se reserva el
                  derecho a deshabilitar o interrumpir el uso del Espacio de
                  Trabajo WebComprehensive Studio a los Usuarios que no cumplan
                  los criterios anteriores.
                </p>
              </Col>
            </Row> */}
            {/* 13 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                11. {t("Misconduct and copyright")}
              </h3>
              <hr />
              <h5 className="mb-4">11.1. {t("Misconduct and abuse")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "By using WebComprehensive's services, you may be exposed to User Platform, User Content or Third Party Services from a variety of sources, which may be inaccurate, offensive, objectionable or unlawful. You hereby waive any legal or equitable rights or remedies you have or may have against WebComprehensive with respect thereto."
                  )}
                  <br />
                  {t(
                    "If you believe that a User or any Third Party Service has acted inappropriately or otherwise misused any of the WebComprehensive Services, please immediately report such User or Third Party Service to us using this form. You agree that your report will not impose any liability or obligation on WebComprehensive, and that WebComprehensive may consider such report and act accordingly, refrain from any action, or require additional information or documents before doing so, at its sole discretion."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "If you witness or experience any misconduct or abusive behavior by anyone using our services, please let us know."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">11.2. {t("Copyright")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive actúa conforme a su interpretación de la Digital Millennium Copyright Act"
                  )}{" "}
                  (“
                  <span className="fw-bold">DMCA</span>”).{" "}
                  {t(
                    "If you believe that your work has been copied or otherwise used in a way that constitutes copyright infringement, you may notify us of such infringement using this form, or by providing our designated Copyright Agent with the following information in writing: (1) contact information for the person authorized to act on behalf of the copyright owner; (2) a description of the copyrighted work that you claim has been infringed; (3) a description of the material that you claim is infringing or is the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit WebComprehensive to locate the material (including URL address); (4) a statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (5) a statement that the information in the notification is accurate, and under penalty of perjury, that you are the copyright owner or are authorized to act on behalf of the owner of the copyright that is allegedly infringed. WebComprehensive's Copyright Agent can be reached at the following address:"
                  )}
                  <br />
                  {/* <br />
                  <div className="fw-bold">WebComprehensive Inc.</div>
                  100 Gansevoort Street, Nueva York, NY 10014 EE. UU. Atención:
                  Copyright Agent Número de teléfono: 1-415-358-0857 | Fax:
                  1-415-358-0884 Correo electrónico: abuse@WebComprehensive */}
                  business@webcomprehensive.com
                  <br />
                  <br />
                  {t(
                    "In the event that WebComprehensive receives notice of a copyright infringement related to your User Account or the User Platform, it may terminate your User Account, disable your User Platform, or remove any Content at its discretion, with or without notice. In such event, you may file an appropriate remedy under Section 512 of the DMCA, which must include: (1) your full name, address, telephone number, and physical or electronic signature; (2) identification of the material and its location prior to removal; (3) a statement under penalty of perjury that the material was removed by mistake or misidentification; (4) your consent to an appropriate court of law; and (5) any other information required under the provisions of the DMCA. Any notification submitted under this Section 13 may or may not be deemed accepted, enforceable, and compliant with the DMCA, in WebComprehensive's reasonable discretion. WebComprehensive reserves the right to notify the person or entity that provided the notice of infringement of such remedy and will provide the data contained therein."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t("We respect the intellectual property rights of others.")}
                  <br />
                  <br />
                  {
                    "If your copyrighted works have been misused by any of our users, please let us know and provide us with all necessary information, and we will take care of this in accordance with the DMCA."
                  }
                  <br />
                  <br />
                  {t(
                    "If we receive a notice of copyright infringement regarding your website or content, we may remove or terminate your website and account."
                  )}
                </small>
              </Col>
            </Row>
            {/* 14 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                12. {t("Disclaimer of Warranties")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  <div className="fw-bold">
                    {t(
                      "We provide the WebComprehensive Services on an “as is“, “with all faults“ and “as available“ basis, without warranties of any kind, including any implied warranties or conditions of merchantability, fitness for a particular purpose, workmanlike effort, non-infringement, or any other warranties, all to the fullest extent permitted by law. We specifically do not represent or warrant that the WebComprehensive Services (or any part, feature or content thereof) are complete, accurate, of any quality, reliable or secure in any way, suitable for or compatible with any of your (or your end users') contemplated activities, devices, operating systems, browsers, software or tools (or that they will remain such at any time), or that they will comply with any laws applicable to you or your End Users (including in any jurisdiction in which you operate), or that their operation will be free of any viruses, bugs or other harmful components or program limitations. Furthermore, we do not endorse any entity, product or service (including Third Party Services) mentioned on or made available through the WebComprehensive Services - so be sure to check them out before using or engaging with them in any way."
                    )}
                  </div>
                  <br />
                  {t(
                    "WebComprehensive may, in its sole discretion (but shall have no obligation to do so), filter, monitor or edit any User Platform or User Content, at any time and for any reason, with or without notice."
                  )}
                  <br />
                  <br />
                  {t(
                    "Notwithstanding anything to the contrary foregoing, in no event shall WebComprehensive be deemed a “Publisher” of any User Content, in no way endorses any User Content, and assumes no responsibility for any User Content uploaded, written, posted and/or made available by any User or anyone else on or through the WebComprehensive Services, for any use by any party, or for any loss, deletion or damage thereto or thereto or any loss, damage, costs or expenses which you or others may suffer or incur as a result of or in connection with posting, accessing or reliance on any User Content. Furthermore, WebComprehensive shall not be liable for any mistakes, defamation, libel, falsehoods, obscenity, pornography, inflammatory or other unlawful or infringing User Content you or anyone else may encounter."
                  )}
                  <br />
                  <br />
                  {t(
                    "You acknowledge that there are risks in using WebComprehensive Services, connecting to or dealing with any Third Party Services through or in connection with WebComprehensive Services, and that WebComprehensive cannot and does not guarantee specific results from such use or interactions, and you hereby assume all such risks, liabilities, or damages of any kind arising in connection with or as a result of such interactions. Such risks may include, but are not limited to, misrepresentation of information about and by Third Party Services or Licensed Content, breach of warranty or contract, violation of rights, and any resulting claims."
                  )}
                  <br />
                  <br />
                  {t(
                    "WebComprehensive does not recommend the use of WebComprehensive Services for hosting personal content and will not assume any obligation or risk of security or integrity with respect to non-compliance or damage of any content."
                  )}
                  <br />
                  <br />
                  {t(
                    "Please note that certain WebComprehensive Services are currently offered in BETA and are undergoing BETA testing. You understand and agree that certain WebComprehensive Services may contain software errors, be subject to interruptions, and may not function as intended or designed. Your use of the WebComprehensive Services in this BETA stage signifies your agreement to participate in BETA testing of the WebComprehensive Services."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We make no warranties regarding our services, including their quality, reliability, security, compatibility and conditions."
                  )}
                  <br />
                  <br />
                  {t("We may monitor and edit the website and its content.")}
                  <br />
                  <br />
                  <br />
                  {t(
                    "However, we should not be considered a “publisher” of your content, we do not endorse the content, and we are not responsible for any content used by you or anyone else."
                  )}
                  <br />
                  <br />
                  <br />
                  <br />
                  {t(
                    "There are risks in using our services or anyone else's. You accept these risks."
                  )}
                  <br />
                  <br />
                  <br />
                  <br />
                  {t(
                    "Some of our services are still in BETA phase and may contain errors or experience interruptions."
                  )}
                </small>
              </Col>
            </Row>
            {/* 15 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                13. {t("Limitation of liability")}
              </h3>
              <hr />
              <Col md={8}>
                <p className="fw-bold">
                  {t(
                    "To the fullest extent permitted by law in each applicable jurisdiction, neither WebComprehensive nor its officers, directors, shareholders, employees, affiliates and/or agents shall be liable for any direct, indirect, incidental, special, punitive, exemplary or consequential damages of any kind, including any damages resulting from (1) errors, inaccuracies, or inaccuracies of or in any content; (2) personal injury or property damage related to your use of the WebComprehensive Services; (3) any unauthorized access to or use of our servers or any personal information or other information stored therein; (4) any interruption or cessation of transmission to or from the WebComprehensive Services; (5) the use or display of any Content or User Content posted, emailed, transmitted or otherwise made available via the WebComprehensive Services; or (6) events beyond WebComprehensive's reasonable control, including internet failures, equipment failures, power failures, strikes, labor disputes, riots, mutinies, civil disturbances, shortages of labor or materials, fires, floods, storms, earthquakes, explosions, acts of God, war, terrorism, intergalactic strife, governmental actions, orders of courts, agencies and tribunals or the failure of third parties to perform them; and (7) loss of use, data, profits, goodwill or other intangible losses, resulting from the use of or inability to use any of WebComprehensive's Services."
                  )}
                  <br />
                  <br />
                  {t(
                    "You acknowledge and agree that these limitations of liability are agreed allocations of risk that constitute in part WebComprehensive's consideration for services to you, and such limitations will apply even if WebComprehensive has been advised of the possibility of such liabilities."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Under applicable law, we will not be liable for any damages."
                  )}
                </small>
              </Col>
            </Row>
            {/* 16 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">14. {t("Immunity")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "You shall defend, indemnify and hold harmless WebComprehensive, its officers, directors, shareholders, employees, affiliates and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including attorneys' fees) arising from: (1) your violation of any of these Terms of Use or any other WebComprehensive Terms; (2) your violation of any third party right, including any copyright, access right, property right or privacy right, resulting from your User Platform or User Content, or use of the WebComprehensive Services; including, without limitation, actions of the WebComprehensive Services for your benefit; and/or (3) any claim that your User Platform and/or User Content has caused damage to a third party."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "If we are sued or otherwise put in danger because of something you did, you will be responsible for the associated costs and damages."
                  )}
                </small>
              </Col>
            </Row>
            {/* 17 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">15. General</h3>
              <hr />
              <h5 className="mb-4">15.1. {t("Changes and updates")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive reserves the right to change, suspend or cancel any of the WebComprehensive Services (or features thereof or prices applicable thereto), and/or terminate your access to any of the WebComprehensive Services (including removing any materials created by you in connection with the WebComprehensive Services) for any reason and/or modify any of the WebComprehensive Terms with or without notice - at any time and in any manner."
                  )}
                  <br />
                  {t(
                    "You agree that WebComprehensive shall not be liable to you or any third party for any modification, suspension or discontinuance of such WebComprehensive Services (or any materials, content or services created, developed or connected by you in connection with the WebComprehensive Services)."
                  )}
                  <br />
                  {t(
                    "If such changes involve the payment of additional Fees, we will provide you with notice of such Fees prior to activating such specific changes. If you fail or refuse to pay such Fees, we may (at our sole discretion) terminate your User Account (as explained in more detail in Section 6 above), continue to support the previously effective WebComprehensive Services without enabling such changes, or provide you with alternative Services."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We may make changes to our services or these terms at any time."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">
                15.2.{" "}
                {t("Applicable Law and Jurisdiction; Class Action Waiver")}
              </h5>
              <Col md={8}>
                <p>
                  {t(
                    "You expressly acknowledge and agree that WebComprehensive shall have the right to enforce these Terms of Use against you."
                  )}
                  <br />
                  {t(
                    "The WebComprehensive Terms, the rights and remedies provided under this warranty, and any and all claims and disputes relating to these Terms or the WebComprehensive Services, their interpretation, or the breach, termination or validity thereof, the relationships resulting from or under the WebComprehensive Terms, or any related transactions or purchases, shall be governed by, construed under and be binding in all respects solely and exclusively in accordance with the internal substantive laws of the State of Argentina, without respect to its conflict of laws principles."
                  )}
                  <br />
                  {t(
                    "Any and all such claims and disputes shall be brought in a court of competent jurisdiction located in Buenos Aires, Argentina, and you hereby consent to their exclusive resolution by such court. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded."
                  )}
                  <br />
                  {t(
                    "Subject to any applicable law, all disputes between you and WebComprehensive will only be resolved on an individual basis, and you will not have the right to bring any claim against WebComprehensive as a plaintiff or as a class member in a class, consolidated or representative action (or any other legal proceeding brought by a group or by representatives on behalf of others)."
                  )}
                  <br />
                  {t(
                    "Notwithstanding anything to the contrary in this Section 15.2, if you are located in the United States of America, (i) the legal jurisdiction governing all aspects of the payment transaction contemplated by Section 6 between you and WebComprehensive shall be the State of New York, without respect to its conflict of laws principles, and (ii) any and all claims and disputes relating to such payment transaction contemplated by Section 6 shall be brought in, and you hereby consent to have them resolved exclusively by, a court of competent jurisdiction located in New York, New York."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "These Terms and our relationship shall be governed by the laws of the State of Argentina. Disputes between us may only be brought before the courts of Buenos Aires, Argentina."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">15.3. {t("Notifications")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "We may give you notices by any of the following methods: (1) through the WebComprehensive Services, including a banner or pop-up on the WebComprehensive Website, User Account or elsewhere; (2) by email, sent to the email address you provided to us, (3) if you are a Reseller user, through your Reseller, and/or (4) by any other means, including any telephone number or physical address or number you have provided to us. WebComprehensive's notice to you will be deemed received and effective within twenty-four (24) hours after it was posted or sent via any of the foregoing methods, unless otherwise stated in the notice."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We may provide you (or your Reseller, if you are a Reseller User) with notices about our services, by email or through other contact methods you have provided to us."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">15.4. {t("Relationship")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "The WebComprehensive Terms, and your use of the WebComprehensive Services, are not and shall not be construed to create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between you and WebComprehensive."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Acceptance of these terms and services does not form a partnership or any special relationship between us."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">15.5. {t("Contract Integrity")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "These Terms of Use, together with the WebComprehensive Terms and any other legal notices or payment notices sent to you by WebComprehensive, shall constitute the entire agreement between you and WebComprehensive concerning the subject matter hereof and supersede any and all prior or contemporaneous agreements, understandings, promises, conditions, negotiations, arrangements or representations, whether oral or written, between you and WebComprehensive, including those made by or between any of our respective representatives, with respect to any of the WebComprehensive Services. You further agree that you are not relying on any promise, inducement, representation, statement, disclosure or duty of disclosure by WebComprehensive in entering into any of the WebComprehensive Terms."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "These Terms (together with our Privacy Policy and any additional terms) shall constitute the sole and entire agreement between us."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">15.6. {t("Assignment")}</h5>
              <Col md={8}>
                <p>
                  <ol>
                    <li>
                      {t(
                        "WebComprehensive may assign its rights and/or obligations under this Agreement or transfer the rights and title in the WebComprehensive Services and/or Licensed Content to a third party without your consent or prior notice to you. You may not assign or transfer any of your rights and obligations hereunder without the prior written consent of WebComprehensive. Any attempted or actual assignment thereof without the prior express written consent of WebComprehensive shall be null and void. In any event, an assignment or transfer pursuant to this Section 17.6 shall not by itself grant WebComprehensive or you the right to terminate any of the WebComprehensive Services or Third Party Services then in effect."
                      )}
                    </li>
                    <li>
                      {t(
                        "If you are a Reseller User, then your subscription with the Reseller in connection with your User Account or User Platform (or any part thereof) may be assigned to WebComprehensive. You agree that if the subscription (or any part thereof) of a WebComprehensive User Account or User Platform with the Reseller is assigned to WebComprehensive, the surviving rights to access and use the User Account and User Platform shall be subject to these Terms of Use, in their entirety (as amended from time to time), and you agree to the full application of these Terms of Use, including, without limitation, the billing and payment provisions set forth herein. You agree that, following any such assignment, you shall, upon WebComprehensive's request, provide such information as is necessary to secure payment for any Paid Service that commences after such assignment."
                      )}
                    </li>
                  </ol>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "We may assign our rights and obligations to third parties. You may only do so with our prior written consent."
                  )}
                  <br />
                  <br />
                  {t(
                    "If you are a Reseller User, your User Account may be assigned to WebComprehensive. In such case, if you wish to continue using your User Account, you agree to be legally bound by WebComprehensive's Terms of Use, and to provide WebComprehensive with all information necessary to secure payment for all Paid Services you have purchased."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">15.7. {t("Divisibility and waivers")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "If any provision of the WebComprehensive Terms is held by a court of competent jurisdiction to be invalid, unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable and shall not affect the validity and enforceability of any remaining provisions. No waiver of any breach or default of any of the WebComprehensive Terms shall be deemed a waiver of any preceding or subsequent breach."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "These terms are independent of each other, in case any of them is found to be invalid."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">15.8. {t("Interpretation")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "Any heading, subheading or section title contained herein and any explanation or summary in the right-hand column “#AsiDeFacil” is provided for convenience only and in no way defines or explains any present section or provision or legally binds any of us in any manner."
                  )}
                  <br />
                  <br />
                  {t(
                    "These Terms of Use were written in English and translated into other languages for your convenience. You may access and view other language versions by changing your language settings on the WebComprehensive Website. If a translated (non-English) version of these Terms of Use conflicts in any way with the English version, the provisions of the English version shall prevail."
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Only the left column is legally binding (this column is for clarity only)."
                  )}
                  <br />
                  <br />
                  {t(
                    "Translated (non-English) versions of these terms are provided for your convenience only."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <h5 className="my-4">15.9. {t("Contact Customer Service")}</h5>
              <Col md={8}>
                <p>
                  {t(
                    "Please contact our Customer Service at business@webcomprehensive.com"
                  )}
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold mb-2">{t("#ThatEasy")}</div>
                  {t(
                    "Do you have questions or suggestions? We'd love to hear from you!"
                  )}
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="pb-2 my-5" />
      </Container>
      <FooterShop reference={null} />
    </>
  );
};
