import { useEffect, useState } from "react";
import Header from "components/home/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FooterShop from "components/home/FooterShop";
import { Col, Container, Row } from "react-bootstrap";

export const HomePolicies = () => {
  const { t } = useTranslation();
  const [homeClicked, setHomeClicked] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setHomeClicked(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (homeClicked) {
      navigate("/");
    }
  }, [homeClicked, navigate]);
  return (
    <>
      <Container fluid>
        <Header
          home={{ name: "", ref: null }}
          sections={[]}
          activeSection={""}
          handleClick={handleClick}
        />
        <Row className="text-center mt-md-5 pt-md-5 mx-md-5 px-md-5">
          <h1 className="display-4 fw-bold mt-5 pt-5 mb-3">
            {t("Privacy Policy of")} <br />
            Store
            <small className="h2"> WebComprehensive</small>
          </h1>
          <p className="h5 mb-5">{t("In force from 30 August 2024")}</p>
          <p>
            {t("Welcome to the Privacy Policy of Store - WebComprehensive")}
          </p>
          <Col className="mx-md-4 px-md-4 text-start">
            {/* 1 */}
            <Row>
              <h3 className="fw-bold pb-2">1. {t("Please read carefully")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive is deeply concerned about the privacy of its visitors and users. Therefore, in this Privacy Policy"
                  )}{" "}
                  ("
                  <span className="fw-bold">{t("Política de Privacidad")}</span>
                  "){" "}
                  {t(
                    "describes how WebComprehensive, together with its affiliated companies around the world"
                  )}{" "}
                  (<span className="fw-bold">"WebComprehensive"</span>, "
                  <span className="fw-bold">{t("we")}</span>", "
                  <span className="fw-bold">{t("our(s)")}</span>" {t("or")} "
                  <span className="fw-bold">{t("us")}</span>"),{" "}
                  {t(
                    "collects, uses, and shares your Personal Information and explains the data rights you may have over that Personal Information. This Privacy Policy applies to all users of WebComprehensive, including visitors, registered users, and premium users (collectively"
                  )}{" "}
                  "<span className="fw-bold">{t("User(s)")}</span>", "
                  <span className="fw-bold">{t("you")}</span>", {t("or")} "
                  <span className="fw-bold">{t("your")}</span>"),{" "}
                  {t(
                    "as well as all WebComprehensive services, including our websites (including www.webcomprehensive.com and any of its subdomains, the"
                  )}{" "}
                  "<span className="fw-bold">{t("Web Site")}</span>"),{" "}
                  {t("web applications")} ( "
                  <span className="fw-bold">
                    {t("WebComprehensive Applications")}
                  </span>
                  " ), {t("mobile applications")} ( "
                  <span className="fw-bold">{t("Mobile Applications")}</span>")
                  {t("and related services (collectively, the")} "
                  <span className="fw-bold">{t("Services")}</span>").{" "}
                  {t(
                    "This Privacy Policy is not intended to replace the terms of any contract you have with us, or any rights you may have under other applicable data protection laws."
                  )}
                  <br />
                  {t(
                    "Before accessing or using our Services, please read this policy and make sure you fully understand our practices regarding your Personal Information. If after you have read and fully understood this Privacy Policy, you still do not agree with our practices, you should immediately leave the Website and discontinue use of any of our Services. If you have any questions or concerns about this policy, please contact us"
                  )}{" "}
                  <a
                    href="mailto:business@webcomprehensive.com"
                    className="text-dark"
                    aria-label="Email"
                  >
                    {t("here")}.
                  </a>
                </p>
              </Col>
              <Col className="ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "This policy describes our privacy practices: what Personal Information we collect about our Users, what we do with it, how we share it, and your rights regarding that Personal Information."
                  )}
                  <br />
                  <br />
                  {t(
                    "By accessing or using any of our services, you acknowledge that you have read this Privacy Policy."
                  )}
                </small>
              </Col>
            </Row>
            {/* 2 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                2. {t("What Personal Information do we collect?")}
              </h3>
              <hr />
              <Col md={8}>
                <h5>2.1. {t("Information about Users")}:</h5>
                <p>
                  {t(
                    "In order to provide you with the Services, we must collect Personal Information relating to an identified or identifiable natural person"
                  )}{" "}
                  ("
                  <span className="fw-bold">{t("Personal Information")}</span>
                  ").{" "}
                  {t(
                    "We collect Personal Information that you provide to us while using the Services, as well as from other sources. These are the types of Personal Information we collect about you"
                  )}
                  :
                  <ol>
                    <li>
                      <span className="fw-bold">
                        {t("Information you provide to us")}.
                      </span>{" "}
                      {t(
                        "When you register for our Services, purchase and/or register domain names, use any of our Services, and/or when you contact us directly through any communication channels (e.g., WebComprehensive support tickets, emails), you may provide us with Personal Information, such as your name, email address, phone number, Personal Payment Information (for Users with paid Services), Personal Information included in your communications with us, and Personal Information contained on scanned identification documents (such as a national ID card, driver's license, passport, or official business registration documents)"
                      )}
                      .
                    </li>
                    <li>
                      <span className="fw-bold">
                        {t("Information we collect when you use the Services")}.
                      </span>{" "}
                      {t(
                        "When you visit, download and/or use any of our Services, we may collect aggregated (anonymous) Personal Information about usage, including browsing activity and click-stream activity, heatmaps and scrolling patterns of Visitors and Users; non-personally identifiable information regarding device, operating system, internet browser, screen resolution, language and keyboard settings, internet service provider, referring and exit pages, date and time stamps, etc., regarding Visitors and Users"
                      )}
                      .
                    </li>
                    <li>
                      <span className="fw-bold">
                        {t("Information we collect from other sources")}.
                      </span>{" "}
                      {t(
                        "We may receive Personal Information about you from third party sources, including: i) security providers, fraud detection and prevention providers, for example to help us screen out users associated with fraud offences; ii) social media platforms, where when you log in or register using your social media account, we may receive Personal Information from that service (e.g. your username, basic Personal Information from your profile) and in some cases we may collect Personal Information from prospecting companies, who help us improve our service offering; iii) advertising and marketing partners, in order to monitor, manage and measure our advertising campaigns"
                      )}
                      .
                    </li>
                  </ol>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "In order to provide our services, we collect Personal Information about our Users."
                  )}
                  <br />
                  <br />
                  {t(
                    "Personal Information that comes from you when you visit or use our services, Personal Information that we collect automatically, and Personal Information that we collect from other sources."
                  )}
                </small>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <h5 className="mt-5">
                  2.2. {t("Information about Users of Users")}
                </h5>
                <p>
                  {t(
                    "We may also collect Personal Information regarding visitors and users of our websites or services from Users"
                  )}{" "}
                  (“
                  <span className="fw-bold">{t("Users of Users")}</span>”),
                  {t(
                    "exclusively for and on behalf of our Users (as described below in Section 6)"
                  )}
                  .
                </p>
                {/* <h5 className="mt-5">
                  2.3. Información para solicitantes de empleo en
                  WebComprehensive
                </h5>
                <p>
                  También recopilamos la información que nos proporcionan los
                  candidatos a empleos de WebComprehensive (“
                  <span className="fw-bold">Solicitantes</span>”) cuando se
                  postulan a cualquiera de los puestos de trabajo publicados en
                  https://www.WebComprehensive/jobs, por correo electrónico o de
                  cualquier otra manera (cómo se describe más adelante en la
                  Política de Privacidad de los Solicitantes de Empleo).
                </p> */}
              </Col>
            </Row>
            {/* 3 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                3. {t("How do we use such Personal Information?")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "We use your Personal Information for the following purposes"
                  )}
                  :
                  <ol>
                    <li>{t("to provide and operate the Services")};</li>
                    <li>
                      {t(
                        "to develop, personalize, expand and improve our Services based on the common or personal preferences, experiences and difficulties of Users"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "to provide our Users with ongoing customer support and technical assistance"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "to contact our Users and send them general or personalized service-related notices and promotional messages (as further detailed in Section 8 below)"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "to facilitate the updating, expansion and analysis of our records in order to identify new customers"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "to facilitate, sponsor and offer certain contests, events and promotions, determine the eligibility of participants, monitor performance, contact winners and award prizes and benefits"
                      )}
                      ;
                    </li>
                    <li>
                      {t("to analyse our performance and marketing activities")}
                      ;
                    </li>
                    <li>
                      {t(
                        "to create aggregated (anonymous) statistical data and other types of aggregated and/or inferred Personal Information, which we or our business partners may use to provide and improve our respective services"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "to provide you with professional assistance, only upon your request to strengthen the security of our data and our fraud prevention capabilities"
                      )}
                      ; {t("and")}
                    </li>
                    <li>
                      {t("to comply with applicable laws and regulations")}.
                    </li>
                  </ol>
                  <div className="py-3"></div>
                  {t(
                    "We may use techniques such as “machine learning” (in European law, this is called “automated decision making”) to help us improve our services. When we use machine learning: (i) there is still a human involved in the process (and therefore it is not fully automated); or (ii) we use machine learning in ways that do not have significant privacy implications (for example, reordering how apps appear when you visit the site). We may also use machine learning to help us monitor, identify, and suspend accounts that send unwanted messages or engage in other abusive or fraudulent activities"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "We use your Personal Information for the purposes set out in Section 3"
                  )}
                  :
                  <ol>
                    <li>
                      {t(
                        "where it is necessary for us to use your Personal Information to perform a contract or take steps to enter into a contract with you (e.g. to provide you with a website builder, to provide you with our customer support and technical assistance)"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "when it is necessary for us to use your Personal Information to comply with a specific legal or regulatory obligation we have"
                      )}
                      ; {t("or")}
                    </li>
                    <li>
                      {t(
                        "where it is necessary for us to use your Personal Information to support legitimate interests and business purposes (for example, to maintain and improve our Services and the effectiveness of WebComprehensive by identifying technical issues), provided we do so in a proportionate manner and respect your privacy rights"
                      )}
                      .
                    </li>
                  </ol>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "We use the Personal Information we collect about you to provide, improve, and make our Services more secure. We also collect and use Personal Information to contact Users and comply with laws to which we are subject"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 4 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                4. {t("How do we share your Personal Information?")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "We may share your Personal Information with service providers and others (or allow them to access it) in the following ways and in the following cases"
                  )}
                  :
                  <Row>
                    <Col md={1} className="text-center mx-0 px-0">
                      4.1.
                    </Col>
                    <Col className="mx-0 px-0">
                      <span className="fw-bold">
                        {t("Third Party Service Providers")}
                      </span>
                      :{" "}
                      {t(
                        "WebComprehensive has partnered with a number of selected service providers whose services and solutions complement, facilitate and enhance our own. These include server hosting and collocation services, communications and content delivery networks (CDN), data and cybersecurity services, payment processing and billing services, domain name registrars, fraud prevention and detection services, web analytics, email monitoring and distribution services, session recording and remote access services, performance measurement, data optimization and marketing services, content providers, as well as our legal and financial advisors (collectively,"
                      )}
                      “
                      <span className="fw-bold">
                        {t("Third Party Service Provider(s)")}
                      </span>
                      ”).
                      <br />
                      <br />
                      {t(
                        "WebComprehensive may share the following categories of Personal Information with Third Party Service Providers for business purposes"
                      )}
                      :
                      <br />
                      <ul>
                        <li>
                          {t(
                            "identifiers, namely, name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers"
                          )}
                          .
                        </li>
                        <li>
                          {t(
                            "Commercial Personal Information, for example, Personal Information regarding products or services purchased, obtained or considered"
                          )}
                          .
                        </li>
                      </ul>
                      <br />
                      {t(
                        "WebComprehensive is responsible for Personal Information that it receives under the Safeguards Framework and that it subsequently transfers to a third party, as described in the Safeguards Framework Principles. In particular, WebComprehensive is responsible and liable under the Safeguards Framework Principles if certain Third Party Service Providers process Personal Information on WebComprehensive's behalf in a manner inconsistent with the Safeguards Framework Principles, unless WebComprehensive proves that it is not responsible for the event giving rise to the damage"
                      )}
                      .
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={1} className="text-center mx-0 px-0">
                      4.2.
                    </Col>
                    <Col className="mx-0 px-0">
                      <span className="fw-bold">
                        {t("Application of the law, requests and legal duties")}
                      </span>
                      :{" "}
                      {t(
                        "WebComprehensive may disclose or otherwise permit access to any of the categories of your Personal Information described in this Privacy Policy pursuant to a legal request, such as a subpoena, legal process, search warrant, or court order, or under applicable law if we believe in good faith that the law requires it, with or without prior notice to you"
                      )}
                      .
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={1} className="text-center mx-0 px-0">
                      4.3.
                    </Col>
                    <Col className="mx-0 px-0">
                      <span className="fw-bold">
                        {t("Protection of rights and security")}
                      </span>
                      :{" "}
                      {t(
                        "WebComprehensive may share any category of your Personal Information described in this Privacy Policy if we believe in good faith that it will help protect the rights, property, or personal safety of WebComprehensive or any of our Users, Users of Users, or the general public, with or without notice to you"
                      )}
                      .
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={1} className="text-center mx-0 px-0">
                      4.4.
                    </Col>
                    <Col className="mx-0 px-0">
                      <span className="fw-bold">
                        {t("WebComprehensive Affiliates and Subsidiaries")}
                      </span>
                      :{" "}
                      {t(
                        "We may share your Personal Information internally with our family of companies for the purposes described in this Privacy Policy. For example, we may share your Personal Information with WebComprehensive, our subsidiary, to facilitate and provide Services to you (and your Users of Users). Sharing of Personal Information by WebComprehensive's affiliated companies and subsidiaries in the European Union, the United Kingdom, and Switzerland with WebComprehensive subsidiaries located outside of these regions will only occur pursuant to an approved transfer mechanism, such as the applicable Standard Contractual Clauses"
                      )}
                      .
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={1} className="text-center mx-0 px-0">
                      4.5.
                    </Col>
                    <Col className="mx-0 px-0">
                      <span className="fw-bold">
                        {t("About a change of corporate control")}
                      </span>
                      :{" "}
                      {t(
                        "In addition, in the event of a change in control of WebComprehensive or any of its affiliates, including through a merger, acquisition, or purchase of a majority of its assets, your Personal Information may be shared with the parties involved in such event"
                      )}
                      .
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={1} className="text-center mx-0 px-0">
                      4.6.
                    </Col>
                    <Col className="mx-0 px-0">
                      <span className="fw-bold">
                        {t("According to your instructions")}
                      </span>
                      :{" "}
                      {t(
                        "WebComprehensive Services allow you, through various techniques, to learn about and engage various third-party services, products, and tools to enhance your websites or mobile sites, including, but not limited to, applications and widgets offered to you by third parties through the WebComprehensive Website, e-commerce payment providers, third-party designers to assist you with your website, etc. (collectively, the “Third Party Services”). If you choose to engage such Third Party Services, they may have access to and process your Users' Personal Information that is collected through their websites or mobile sites. For example"
                      )}
                      :
                      <br />
                      <br />
                      a) <span className="fw-bold">{t("Framed Pages")}</span>:
                      {t(
                        "our Services may allow you to embed Third Party Services directly into your websites or mobile sites, such as through page framing techniques, to deliver content to or from Third Party Services or others (“Frames”). In these cases, Third Party Services may collect Personal Information from their Users"
                      )}
                      .
                      <br />
                      <br />
                      {/* b) <span className="fw-bold">Desarrolladores</span>:
                      permitimos que desarrolladores terceros (“
                      <span className="fw-bold">Desarrolladores Terceros</span>
                      ”) desarrollen y ofrezcan sus propias aplicaciones a
                      través del WebComprehensive App Market (“
                      <span className="fw-bold">Aplicaciones de Terceros</span>
                      ”) a los Usuarios, que se pueden integrar en sus sitios
                      web o móviles. Todos los Desarrolladores Terceros están
                      obligados por el Contrato de Partner de WebComprehensive
                      App Market, el cual, entre otras cosas, limita cómo los
                      desarrolladores pueden acceder, almacenar, compartir y
                      usar la Información Personal que usted o sus Usuarios de
                      los Usuarios les entreguen.
                      <br />
                      <br /> */}
                      b){" "}
                      <span className="fw-bold">
                        {t("Characteristics of social networks")}
                      </span>
                      :{" "}
                      {t(
                        "our Services may allow you to integrate certain social networking features, widgets, and single sign-on features, such as “Facebook Connect” or “Google Sign-in”"
                      )}{" "}
                      (“
                      <span className="fw-bold">
                        {t("Characteristics of Social Networks")}
                      </span>
                      ”){" "}
                      {t(
                        "on their websites or mobile devices. These Social Media Features may collect certain Personal Information from Users, such as identifiers (name, alias, unique personal identifier, online identifier, Internet Protocol address, email address, etc.) or other similar information. Social Media Features are either hosted by third parties or hosted directly on our Services"
                      )}
                      .
                      <br />
                      <br />
                      {t(
                        "Please note that in the examples mentioned in Section 4.6, WebComprehensive is only acting as an intermediary platform and allows you to engage such Third Party Services (including, but not limited to, Third Party Developers, Third Party Applications, and Social Media Features) with which you interact directly and at your discretion. In this regard, WebComprehensive acts as a service provider to you and discloses information to the Third Party Services on your behalf. WebComprehensive will share your Users' Personal Information only in accordance with your instructions or with your permission and has and will not have any responsibility or obligation in connection with the processing of such Personal Information by such Third Party Services"
                      )}
                      .
                      <br />
                      <br />
                      {t(
                        "WebComprehensive does not control or have responsibility for the actions or policies of any Third Party Service, and your use of any Third Party Service is at your own risk. We encourage you to review any privacy policies related to a Third Party Service and to ask any Third Party Service for any clarifications you may need before deciding to install or use its services"
                      )}
                      .
                    </Col>
                  </Row>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "We may share the Personal Information of our Users and Users of Users with various third parties, including certain service providers and law enforcement officials. Personal Information may be shared only in accordance with this policy"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 5 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                5.
                {t(
                  "Where do we process and store your “personal information”?"
                )}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  <Row>
                    <Col md={1} className="text-center mx-0 px-0">
                      5.1.
                    </Col>
                    <Col className="mx-0 px-0">
                      {t(
                        "WebComprehensive processes data about individuals from all over the world and may transfer your Personal Information outside of your country. Your security, privacy and confidentiality are our priority and that is why WebComprehensive conducts a verification process that includes assessing the privacy laws in such countries to confirm that they have robust data protection laws"
                      )}
                      .
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={1} className="text-center mx-0 px-0">
                      5.2.
                    </Col>
                    <Col className="mx-0 px-0">
                      {t(
                        "User personal information is managed by WebComprehensive in Argentina, which the European Commission considers to be a country that offers an adequate level of protection of personal information of residents of EU Member States."
                      )}{" "}
                      ({t("see")}{" "}
                      <a
                        className="text-dark"
                        href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32003D0490&qid=1725371096797"
                      >
                        {t("here")}
                      </a>
                      ).
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={1} className="text-center mx-0 px-0">
                      5.3.
                    </Col>
                    <Col className="mx-0 px-0">
                      {t(
                        "If you are located in Europe, the United Kingdom or Switzerland, when we transfer your Personal Information to a location outside Europe, we will ensure that either (i) there is a level of protection that the European Commission considers adequate or (ii) that the relevant Standard Contractual Clauses are in place (i.e. the relevant module of the Standard Contractual Clauses for the transfer of personal data to other countries in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 4 June 2021, available"
                      )}{" "}
                      <a
                        className="text-dark"
                        href="https://commission.europa.eu/publications/standard-contractual-clauses-international-transfers_en"
                      >
                        {t("here")}
                      </a>
                      ,{" "}
                      {t(
                        "and the Information Commissioner's Office (ICO) EU Standard Contractual Clauses Addendum on International Transfer of Data, version B1.0, effective from 21 March 2022, as revised pursuant to Article 18 of the Mandatory Clauses). Where WebComprehensive transfers Personal Information from the EU to another country that, in the European Commission's opinion, does not adequately protect your information, WebComprehensive ensures that additional measures have been taken to comply with European data protection laws"
                      )}
                      .
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={1} className="text-center mx-0 px-0">
                      5.4.
                    </Col>
                    <Col className="mx-0 px-0">
                      {t(
                        "Privacy Shield Certification: WebComprehensive and its affiliates participate in and certify compliance with the EU-US Privacy Shield Framework and the Swiss-US Privacy Shield Framework. For more information about the Privacy Shield Framework, visit the U.S. Department of Commerce's Privacy Shield List, available"
                      )}{" "}
                      <a
                        className="text-dark"
                        href="https://www.privacyshield.gov/welcome"
                      >
                        {t("here")}.
                      </a>
                      <br />
                      <br />
                      {t(
                        "With respect to Personal Information received or transferred under the Privacy Shield Framework, WebComprehensive is subject to the regulatory enforcement authority of the U.S. Federal Trade Commission (FTC). In compliance with the Privacy Shield Principles, WebComprehensive commits to resolve complaints about our collection or use of your personal information. EU and Swiss individuals with inquiries or complaints about our Privacy Shield policy should first contact us at: business@webcomprehensive.com"
                      )}
                      .
                      <br />
                      <br />
                      {t(
                        "WebComprehensive has further committed to refer unresolved Privacy Shield complaints to an alternative dispute resolution provider located in the United States. If you do not receive timely acknowledgment of your complaint from us, or if we do not resolve your complaint to your satisfaction, you may (under certain conditions described on the Privacy Shield website) invoke binding arbitration when other dispute resolution procedures have been exhausted. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our third-party dispute resolution provider in the U.S. at https://feedback-form.truste.com/watchdog/request for more information or to file a complaint. This service is provided at no cost to you"
                      )}
                      .
                      <br />
                      <br />
                      <div className="fw-bold">
                        {t(
                          "For the avoidance of doubt, WebComprehensive does not rely on the Privacy Shield as a mechanism for the transfer of personal data protected by the GDPR. Currently, WebComprehensive only uses other transfer mechanisms, as detailed above, including, but not limited to, standard contractual clauses or adequacy decisions"
                        )}
                        .
                      </div>
                    </Col>
                  </Row>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "We may store and process Personal Information in the United States, Europe, Israel, or other jurisdictions, either ourselves or with the help of our affiliates and our service providers. WebComprehensive's data storage providers are contractually obligated to protect and secure your data"
                  )}
                  .
                </small>
                <div className="my-3 py-3"></div>
                <div className="my-3 py-3"></div>
                <small>
                  {t(
                    "Among other things, WebComprehensive will ensure that there is an adequate level of protection or that the Standard Contractual Clauses apply for the international transfer of our EU users' data"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 6 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                6. {t("Personal Information of Users of Users")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive may collect, store and process certain Personal Information of Users of the Users"
                  )}{" "}
                  (“
                  <span className="fw-bold">
                    {t("User Information of Users")}
                  </span>
                  ”){" "}
                  {t(
                    "solely on behalf of and at the instruction of our Users. For example, each of our Users may import their email contacts from third party services such as Gmail or collect and manage contacts through their User Website. Such contacts are then stored with WebComprehensive, on behalf of the User"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "For such purposes, WebComprehensive shall act and be deemed to be a “Data Processor” and not the “Data Controller” (as such capitalized terms are defined in the European Union General Data Protection Regulation [“GDPR”]) of such User Information"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Users who control and operate such User Websites shall be deemed to be “Data Controllers“ of such User Information and are responsible for complying with all laws and regulations applicable to the collection and control of such User Information, including all privacy and data protection laws of all applicable jurisdictions"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "The Processing of Users' Personal Information will take place within the territory of the European Union, Israel or a third country, territory or one or more specified sectors within such third country, for which the European Commission has decided that it ensures an adequate level of protection and that, therefore, the processing and transfer will be carried out in accordance with the Data Processing Addendum - Users (“DPA“). Any transfer and processing to a country that is outside the European Union and that does not guarantee an adequate level of protection according to the European Commission will be done according to an approved transfer mechanism, as detailed in the data protection agreement"
                  )}
                  .
                  <br />
                  <br />
                  <div className="fw-bold">
                    {t(
                      "You are responsible for the security, integrity, and authorized use of Users' Personal Information, as well as for obtaining consents and permissions, and providing data subjects with notices regarding their rights and fair processing necessary for the collection and use of such Personal Information"
                    )}
                    .
                  </div>
                  <br />
                  {t(
                    "WebComprehensive cannot provide legal advice to Users or Users of Users; however, we recommend that all Users post and maintain clear and comprehensive privacy policies on their User Websites in compliance with applicable laws and regulations, and that all Users of Users carefully read such policies and ensure that they understand them and, to the extent required by applicable law, give their consent thereto"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "For more information about how WebComprehensive may handle Users' Users' Personal Information (which may be relevant to any specific notices you provide to Users' Users) and/or the consent you obtain from them, please see the sections"
                  )}{" "}
                  <span className="fw-bold">4, 12 y 13</span>.
                  <br />
                  <br />
                  <span className="fw-bold">
                    {t(
                      "If you are a visitor, user or customer of any of our Users, please read the following"
                    )}
                  </span>
                  :{" "}
                  {t(
                    "WebComprehensive does not have a direct relationship with the Users of Users whose Personal Information it processes. If you are a visitor, user, or customer of any of our Users and would like to make a request or inquiry regarding your Personal Information, please contact such User(s) directly. For example, if you would like to make a request to access, correct, amend, or delete inaccurate Personal Information that WebComprehensive processes on behalf of its Users, please direct your inquiry to the appropriate User (i.e., whoever is the “Data Controller” of such data). If our Users request WebComprehensive to delete any Users of Users' Personal Information, we will respond to such requests in a timely manner, upon verification, and in accordance with applicable law (for example, thirty (30) days under the GDPR). Unless our Users indicate otherwise, we will retain Users' Personal Information for the period set out below in"
                  )}{" "}
                  <span className="fw-bold">{t("Section")} 12</span>.
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "WebComprehensive may collect and process Personal Information about Users of Users"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "We do this only on behalf of our Users, and at their instructions"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Our Users are solely responsible for the Personal Information of Users of Users, including its legality, security and integrity. WebComprehensive has no direct relationship with any of its Users' Users. If you are a user of a user, please contact that user directly"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 7 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                7. {t("Use of cookies and other third-party technologies")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "We and our Third Party Service Providers use cookies and other similar technologies"
                  )}{" "}
                  (“
                  <span className="fw-bold">Cookies</span>”){" "}
                  {t(
                    "to provide our Service and ensure that it functions correctly, to analyse our performance and marketing activities, as well as to personalize your experience"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "You can find out more about how we use cookies and how you can exercise control over them in our Cookie Policy"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Please note that we do not change our practices in response to a “Do Not Track” signal in the HTTP header from a browser or mobile application; however, most browsers allow you to control cookies, including whether or not to accept them and how to delete them. You can set most browsers to notify you if you receive a cookie, or you can choose to block cookies with your browser"
                  )}
                  .
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "We and certain third-party services may use cookies and similar tracking technologies while providing our services"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "These technologies are primarily used for stability, security, functionality, performance, and advertising purposes"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 8 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                8. {t("WebComprehensive Communications")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  <div className="h5 fw-normal mb-3">
                    8.1. {t("Promotional messages")}
                  </div>
                  {t(
                    "We may use your Personal Information to send you promotional content and messages via email, text messages, notices within our platform, marketing calls, and other similar forms of communication from WebComprehensive or our partners (acting on behalf of WebComprehensive) through these means"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "If you do not wish to receive such promotional messages or calls, you may notify WebComprehensive at any time or follow the instructions contained in the promotional communications you receive to “unsubscribe” or “STOP” them"
                  )}
                  .
                  <br />
                  <br />
                  <br />
                  <div className="h5 fw-normal mb-3">
                    8.2. {t("Service and Billing Messages")}
                  </div>
                  {t(
                    "Similarly, WebComprehensive may contact you to let you know important information about our Services or your use of them. For example, we may send you a notice (by any means available to us) if a particular Service is temporarily suspended for maintenance; respond to a support request or email; send you reminders or notices regarding upcoming or late payments on your current or future subscriptions; forward abuse complaints regarding your User Website; or notify you of material changes to our Services"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "It is important that you are able to receive such messages at all times. For this reason, you will not be able to opt out of receiving these types of Service and Billing messages unless you are no longer a WebComprehensive User (which you can do by deactivating your account)"
                  )}
                  .
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "We may send you promotional messages and content. You can easily opt out of receiving promotional messages by contacting us or by unsubscribing"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "We may also contact you to send you service-related and billing-related messages and content. You may not opt out of receiving these types of messages"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 9 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                9. {t("Your rights in relation to your Personal Information")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive believes it is imperative that all WebComprehensive users have control over their Personal Information. Therefore, depending on how you use the WebComprehensive Services, you may have the right to request access to, receive copies of, update, correct or delete, port certain Personal Information to another service, restrict or reject certain uses of your Personal Information (for example, for direct marketing purposes). In addition, where we ask for your consent to process your Personal Information (for example, for direct marketing), you may withdraw your consent at any time, with such withdrawal taking effect from the date of your withdrawal"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "WebComprehensive will not charge you more if you exercise any of these rights and will continue to provide you with the same level of service"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "If you are a WebComprehensive user, you can access and correct much of your Personal Information directly through your account, or by using the online forms that WebComprehensive makes available to you (as detailed below). You can also exercise your rights by sending your request to business@webcomprehensive, or by contacting our customer service department. When we receive your request to exercise your rights, we may take steps to verify your identity before complying with the request to protect your privacy and security"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Before complying with your request, we may ask you for additional information to confirm your identity and for security purposes. We reserve the right to charge a fee where permitted by law (for example, if your request is unfounded or excessive)"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "You have the right to lodge a complaint with your local data protection supervisory authority (but we recommend that you contact us first)"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "If you are a WebComprehensive User and would like to receive a copy of, access, and/or request corrections to your Personal Information (whether to your own or to the Users of Users) that you have stored with us, or would like to request a list of the Personal Information (if any) relating to you that we have disclosed to third parties for their direct marketing purposes, please follow the instructions in these Help Center articles: “Recovering Your WebComprehensive Account Data” or “Permanently Deleting Your WebComprehensive Account”"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "You may also send your request to business@webcomprehensive. We will take all reasonable steps to comply with your request promptly (except where we require additional information from you in order to comply), subject to applicable legal and other considerations"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Please note that permanently deleting your WebComprehensive account will delete all of your Personal Information from WebComprehensive's databases. After completing this process, you will not be able to use any of your WebComprehensive Services and your account and all of your data will be permanently deleted, so WebComprehensive will not be able to restore your account or retrieve your data in the future. If you wish to contact our support channels in the future, your account will not be recognized by the system and support agents will not be able to locate the deleted account"
                  )}
                  .
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "With respect to your Personal Information that you have stored with us, you have the right to: access your Personal Information, receive a copy, update, modify or delete it, exercise data portability (in certain cases), restrict or limit certain uses of your Personal Information, as well as withdraw the consent you gave us prior to processing"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Simply contact us by email, through our customer service team or, where available, by filling out our dedicated form"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "You may also correct and/or update your Personal Information through your user account, if you have one. You may also delete your Personal Information by deleting your account entirely. We will respond to your requests within a reasonable time"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 10 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                10. {t("Questions and complaints")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "If you have questions or concerns about how we collect, use or disclose Personal Information, or if you believe that we have not complied with this Privacy Policy or applicable data protection laws, please contact us. Our contact information is listed at the end of this Privacy Policy"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Our Data Protection Officer team will investigate the complaint and determine whether there has been a breach and what action to take if applicable. We take every privacy complaint very seriously and will take all reasonable steps to resolve your complaint promptly and in accordance with applicable law"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "You may lodge a complaint with your local data protection supervisory authority at any time; however, we recommend that you first contact us so that we can try to resolve it"
                  )}
                  .
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "You may lodge a complaint with your local data protection supervisory authority at any time. Please contact us first so that we can try to resolve your concerns"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 11 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">11. {t("Data retention")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "We may retain your Personal Information (as well as the Personal Information of Users of Users) for as long as your User Account is active, as set forth in this Privacy Policy or as necessary to provide you with our Services"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "We may continue to retain your Personal Information after you deactivate your User Account and/or cease using any particular Services, as reasonably necessary to comply with our legal obligations, resolve disputes regarding our Users or Users' Users, prevent fraud and abuse, enforce our agreements, and/or protect our legitimate interests"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "To determine the appropriate retention period for Personal Information, we consider the amount, nature, and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure of the data, the purposes for which we process it, and the applicable legal requirements"
                  )}
                  .
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "We may retain your Personal Information for as long as your account is active, and for longer as necessary (for example, if we are legally required to retain it longer, or if it is needed to protect our interests)"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 12 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">12. {t("Security")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "WebComprehensive has implemented security measures designed to protect the Personal Information you share with us, including physical, electronic, and procedural measures. Among other things, we offer secure HTTPS access to most areas of our Services, and the transmission of sensitive payment information (such as a credit card number) through our designated purchase forms is protected by an industry-standard SSL/TLS encrypted connection. We also regularly monitor our systems for potential vulnerabilities and attacks, and we regularly seek out new ways and new Third Party Services to further improve the security of our Services as well as the protection of the privacy of our Visitors and Users"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Regardless of the measures and efforts undertaken by WebComprehensive, we cannot and do not guarantee the absolute protection and security of your Personal Information, the Personal Information of Users of Users, or any other information you upload, post or share with WebComprehensive or anyone else. We encourage you to establish strong passwords for your User Account and the User Website and to avoid providing us or anyone else with any sensitive Personal Information the disclosure of which may, in your judgment, cause you substantial or irreparable harm"
                  )}
                  .
                  <br />
                  {t(
                    "In addition, because certain areas of our Services are less secure than others (for example, if you set your Support forum so that your requests are “public” rather than “private” or if you visit a non-SSL page) and because email and instant messaging are not recognized as secure forms of communication, we request and recommend that you do not share any Personal Information in any of these areas using any of these methods"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "If you have questions about the security of our Services, we invite you to contact us"
                  )}{" "}
                  <a
                    href="mailto:business@webcomprehensive.com"
                    className="text-dark"
                    aria-label="Email"
                  >
                    {t("here")}.
                  </a>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "WebComprehensive values the security of our customers' Personal Information and we do everything in our power to protect it"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "However, since we cannot guarantee absolute protection, we urge you to exercise caution, set a strong password for your account, and avoid submitting any sensitive information that, if exposed, could cause significant harm"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 13 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                13. {t("Third party websites")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "Our Services may contain links to other websites or services"
                  )}
                  .{" "}
                  {t(
                    "We are not responsible for the privacy practices of such websites or services. We encourage you to be aware of when you leave our Services and to read the privacy statements of each website and service you visit before providing your Personal Information. This Privacy Policy does not apply to such linked websites and third-party services"
                  )}
                  .
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "Our Services may contain links to other websites or services"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 14 */}
            {/* <Row>
              <h3 className="fw-bold pb-2 mt-5">
                14. Solicitudes de trabajo en WebComprehensive
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  WebComprehensive invita a todos los Postulantes calificados a
                  postularse para cualquiera de los puestos abiertos publicados
                  en https://www.WebComprehensive/jobs. Para eso, pueden enviar
                  su información de contacto, CV y otra información pertinente a
                  través del formulario de solicitud en nuestro Sitio Web.
                  WebComprehensive procesará esa información de conformidad con
                  nuestra Declaración de privacidad para postulantes.
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  Invitamos a todos los buscadores de empleo calificados a que
                  presenten su solicitud para nuestros puestos vacantes
                  enviándonos sus datos de contacto y su CV.
                  <br />
                  Utilizaremos dicha Información Personal únicamente con fines
                  internos de contratación, empleo y empresariales.
                </small>
              </Col>
            </Row> */}
            {/* 15 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                14. {t("Public Forums and User Content")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "Our Services offer publicly accessible blogs, communities, and support forums. Please be aware that any Personal Information you provide in any of these areas can be read, collected, and used by others who access them. To request removal of your Personal Information from our blogs, communities, or forums, please feel free to contact us"
                  )}{" "}
                  <a
                    href="mailto:business@webcomprehensive.com"
                    className="text-dark"
                    aria-label="Email"
                  >
                    {t("here")}.
                  </a>{" "}
                  {t(
                    "In some cases, we may not be able to remove your Personal Information from those areas. For example, if you use a third-party application to post a comment (e.g., Facebook's social plugin application) while logged in to your profile associated with that third party, you must log in to that application or contact its provider if you wish to remove Personal Information that you have posted on that platform"
                  )}
                  .
                  <br />
                  <br />
                  <div className="fw-bold">
                    {t(
                      "In any case, we recommend that you do not publish any Personal Information (by any means) that you do not wish to make known"
                    )}
                    .
                  </div>
                  <br />
                  {t(
                    "If you upload any User Content to your User Account or post it on your User Website and/or otherwise provide it as part of your use of any Service, you do so at your own risk. We have put in place appropriate security measures to protect your Personal Information. However, we cannot control the actions of other Users or members of the public who may have access to your User Content, and we are not responsible for the circumvention of any privacy settings or security measures you or we have put in place on your User Website (including, for example, password-protected areas of your User Website). You understand and acknowledge that, even after you or we have removed it, copies of User Content may remain viewable in cached and archived pages or if third parties (including any of the Users of the Users) have copied or stored such User Content"
                  )}
                  .{" "}
                  <span className="fw-bold">
                    {t(
                      "For clarity, we recommend that you do not upload or post any information that you do not wish to make public"
                    )}
                    .
                  </span>
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "Please avoid posting any Personal Information in any of the public areas of our Services or on your own website if you do not want it to be publicly available"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 16 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                15. {t("Updates and interpretation")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "We may update this Privacy Policy as required by applicable law, as well as to reflect changes we make in our collection, use, and storage practices of Personal Information. If we make any changes that we consider to be “material” (in our sole, good faith discretion), we will notify you (using one of the notification methods set forth in Section 15.3 of the Terms of Use) prior to the change taking effect. With respect to any updates to this Privacy Policy, we will notify you, seek your consent, and/or take such other actions as may be required by applicable law. We encourage you to periodically review this page for the latest information on our privacy practices. Unless otherwise stated, our most current Privacy Policy applies to all information we have about you and Users of Users, with respect to our Website, WebComprehensive Applications, Mobile Applications, and other Services"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Any heading, legend, or section title appearing in this document, as well as any explanations or summaries included under the column"
                  )}{" "}
                  “{t("#ItsThatEasy")}”{" "}
                  {t(
                    "on the right, are provided for convenience only and do not in any way define or explain any section or provision hereof, nor are they legally binding on any of us in any way"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "This Privacy Policy was written in English and may be translated into other languages for your convenience. To access and view other language versions, please change the language setting of your WebComprehensive Website. If a translated (non-English) version of this Privacy Policy conflicts in any way with your English version, the provisions of the latter will take precedence"
                  )}
                  .
                </p>
              </Col>
              <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  {t(
                    "We may modify this policy at any time. We will notify you of modifications as required by applicable law"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Only the left column is legally binding (as this column is for clarity only). Translated (non-English) versions of these Terms are provided for convenience only"
                  )}
                  .
                </small>
              </Col>
            </Row>
            {/* 17 */}
            <Row>
              <h3 className="fw-bold pb-2 mt-5">16. {t("Contact us")}</h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "If you have any questions about this Privacy Policy or would like to exercise any of your rights described in Sections 9 or 10, please refer to those sections or contact the data protection team at business@webcomprehensive.com. We will attempt to resolve all complaints regarding the use of your Personal Information in accordance with this Privacy Policy"
                  )}
                  .
                  {/* <br />
                  <br />
                  También puede ponerse en contacto con nosotros por correo
                  postal en la siguiente direccion:
                  <ul>
                    <li>WebComprehensive: Argentina.</li>
                  </ul> */}
                  <br />
                  <br />
                  {t(
                    "For the purposes of the GDPR (Article 27), you may contact our EU representative at business@webcomprehensive.com"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "For the purposes of the UK GDPR (Article 27), you may contact our WebComprehensive (UK) representative at business@webcomprehensive.com"
                  )}
                  .
                </p>
              </Col>
              {/* <Col className="text-start ms-5 ps-5">
                <small>
                  <div className="fw-bold">{t("#ItsThatEasy")}</div>
                  Podemos modificar esta política en cualquier momento. Le
                  notificaremos acerca de las modificaciones según lo exija la
                  legislación aplicable.
                  <br />
                  Únicamente la columna de la izquierda es jurídicamente
                  vinculante (ya que esta columna es solo para mayor claridad).
                  <br />
                  Se presentan versiones traducidas (distintas del inglés) de
                  estos Términos solo para mayor comodidad.
                </small>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <hr className="pb-2 mt-5" />
        <Row className="text-center mt-5 pt-5 mx-md-5 px-md-5">
          <Col className="mx-md-4 px-md-4 text-start">
            <Row>
              <h3 className="fw-bold pb-2 mt-5">
                {t("Additional information for residents of the United States")}
              </h3>
              <hr />
              <Col md={8}>
                <p>
                  {t(
                    "This section of our Privacy Policy details what Personal Information we collect about you under applicable U.S. privacy laws and regulations, the sources of the Personal Information, the purposes of use and disclosure, and the categories of third parties that may receive that Personal Information. The information in this section applies to you if you are a U.S. resident in a state with an applicable privacy law or regulation"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "Please read this section in conjunction with the other sections of this Privacy Policy for a complete understanding of how we collect, use and disclose your personal information"
                  )}
                  .
                  <br />
                  <div className="h5 fw-normal mt-5 pt-2">
                    {t("Your rights and how to exercise them")}:
                  </div>
                  {t("Here is a brief summary of your rights")}:
                  <br />
                  <br />
                  <ul>
                    <li>
                      {t(
                        "Right to delete your data: At your request, we will delete the personal information we have collected about you, subject to certain legally permitted exceptions"
                      )}
                      .
                      <ul>
                        <li>
                          {t(
                            "Please follow the instructions provided in this Help Center article, “Permanently Deleting Your WebComprehensive Account,” which explains how to delete your Personal Information. You may also choose to request deletion through this web form (in English) or by contacting us as described in this Privacy Policy"
                          )}
                          .
                        </li>
                      </ul>
                    </li>
                    <li>
                      {t(
                        "Right to know and access your personal information: You have the right to ask us to confirm whether we process your personal information and a copy of the personal information we hold about you"
                      )}
                      .
                      <ul>
                        <li>
                          {t(
                            "Please follow the instructions provided in this Help Center article, “Recovering Your WebComprehensive Account Data,” which explains how you can recover your Personal Information. You may also submit a request to access your Personal Information through this web form (in English) or by contacting us as described in this Privacy Policy"
                          )}
                          .
                        </li>
                      </ul>
                    </li>
                    <li>
                      {t(
                        "Right to correct inaccurate personal information: You have the right to request that we correct inaccurate personal information about you. Some personal information can be modified or corrected directly by you through our applications and websites"
                      )}
                      .
                      <ul>
                        <li>
                          {t(
                            "If you have any questions, you may contact us using the method described in this Privacy Policy"
                          )}
                          .
                        </li>
                      </ul>
                    </li>
                    <li>
                      {t(
                        "Right to Opt Out of the Sale of Your Personal Information: You may tell us not to “sell” your personal information in accordance with applicable law, even though we do not technically sell your information in the traditional sense. We may share certain categories of Personal Information with our Third Party Service Providers. However, current law uses a very broad (and somewhat scary) definition of “sale”"
                      )}
                      .
                    </li>
                    <li>
                      {t(
                        "Right to limit the use of your personal information for targeted advertising (defined in California as “Sharing”): You may ask us not to use or disclose your personal information for targeted advertising. Please visit our Do Not Sell or Share My Personal Information page. You may also use the Global Privacy Control to indicate your opt-out preference. For more information about the GPC, please visit https://globalprivacycontrol.org"
                      )}
                      .
                    </li>
                  </ul>
                  <div className="py-3"></div>
                  {t(
                    "You may exercise any of the rights described above, you may also send a request by email to business@webcomprehensive.com"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "When you submit a request, we will usually ask you to provide an email address to verify your identity and prevent fraud"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "You may also designate an authorized agent to submit a request on your behalf"
                  )}
                  .
                  <br />
                  {t(
                    "Depending on the nature of the request, we may authenticate the request by requesting additional information or documentation, such as account-related information, billing information used to purchase Paid Services, and the submission of specific documents"
                  )}
                  .
                  <br />
                  <br />
                  <span className="fw-bold">{t("No discrimination")}</span>.
                  {t(
                    "WebComprehensive does not discriminate against any person for exercising their rights under this section"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "If we deny your request or are unable to fulfill it, you may appeal our decision by contacting us as described in this Privacy Policy to ask us to review your request again. Your communication must include “Appeal” in the subject line"
                  )}
                  .
                  <br />
                  <div className="h5 fw-normal mt-5 pt-2">
                    {t("Information categories")}:
                  </div>
                  <br />
                  {t(
                    "In the past 12 months, we have collected the following categories of personal information (the same categories we collect from all users globally)"
                  )}
                  :
                  <ul>
                    <li>{t("Personal and online identifiers")}</li>
                    <li>{t("Business and transaction information")}</li>
                    <li>{t("Log information")}</li>
                    <li>
                      {t(
                        "Internet or other electronic network activity information"
                      )}
                    </li>
                    <li>
                      {t("Inaccurate geolocation data (IP address only)")}
                    </li>
                    <li>
                      {t("Audible, electronic, visual or similar information")}
                    </li>
                    <li>{t("Inferences drawn from the above information")}</li>
                  </ul>
                  <br />
                  <div className="h5 fw-normal mt-5 pt-2">
                    {t("Sources of information")}
                  </div>
                  <br />
                  {t("We collect personal information")}:
                  <ul>
                    <li>
                      {t(
                        "You provide it to us when you register for our Services, sign up for any WebComprehensive event, subscribe to our blog(s) or newsletter(s), purchase and/or register domain names, use any of our Services; and/or when you contact us directly through any communication channel"
                      )}
                      .
                    </li>
                    <li>
                      {t(
                        "When you use the Services by visiting, downloading and/or using any of our Services"
                      )}
                      .
                    </li>
                    <li>
                      {t(
                        "From third-party sources, such as security providers, fraud detection services, social media platforms, lead enhancement companies, and advertising and marketing partners"
                      )}
                      .
                    </li>
                  </ul>
                  <br />
                  <div className="h5 fw-normal mt-5 pt-2">
                    {t("Disclosure of information for commercial purposes")}:
                  </div>
                  <br />
                  {t(
                    "In the preceding 12 months, we may have disclosed each of the categories of personal information listed above to our affiliates, third-party service providers, social media platforms, advertising partners, analytics partners, and promotional partners"
                  )}
                  .
                  <br />
                  <div className="h5 fw-normal mt-5 pt-2">
                    {t("Selling or sharing your information")}:
                  </div>
                  <br />
                  {t(
                    "Note: Of course, we are not auctioning off your data to the highest bidder. We may have “sold” (using that rather broad and dramatic term as defined by U.S. privacy laws and regulations) or shared the categories of Personal Information listed above to our affiliates, Third Party Service Providers, promotional partners, advertising partners, analytics partners, and social media platforms in the past 12 months"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "WebComprehensive does not knowingly collect, use, sell or share Personal Information from children under the age of 16"
                  )}
                  .
                  <br />
                  <div className="h5 fw-normal mt-5 pt-2">
                    {t("Sensitive personal information")}:
                  </div>
                  <br />
                  {t(
                    "We may process Sensitive Personal Information in accordance with applicable laws and do not use or disclose Sensitive Personal Information in any manner that would require us to provide an opt-out opportunity under applicable laws"
                  )}
                  .
                  <br />
                  <div className="h5 fw-normal mt-5 pt-2">
                    {t("Information retention")}:
                  </div>
                  <br />
                  {t(
                    "We retain your Personal Information for the period of time reasonably necessary to achieve the purposes outlined in this Privacy Policy, or in any other notice provided at the time of collection"
                  )}
                  .
                  <br />
                  <br />
                  {t(
                    "To determine the appropriate retention period for Personal Information, we consider the amount, nature, and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure, the purposes for which we process it, and the applicable legal requirements"
                  )}
                  .
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="pb-2 my-5" />
      </Container>
      <FooterShop reference={null} />
    </>
  );
};
