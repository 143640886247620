import { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "helpers/cropImage";
import { useTranslation } from "react-i18next";

const ImageCropper = ({ aspect, imageUrl, onChange }) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropContainer = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: "120px",
  };

  const controls = {
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    height: "40px",
    display: "flex",
    alignItems: "center",
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
      onChange(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div style={cropContainer}>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          maxZoom={10}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div style={controls}>
        <input
          type="range"
          value={zoom}
          min={1}
          max={10}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value);
          }}
          className="zoom-range form-range"
        />
        <button onClick={showCroppedImage} className="ms-5 btn btn-primary">
          {t("Cut")}
        </button>
      </div>
    </>
  );
};

export default ImageCropper;
