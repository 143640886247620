import TreeTableNode from './TreeTableNode';
import React from 'react';

const TreeTable = ({ data, action }) => {

  return (
    <>
      { data && data.map(node => (
        <TreeTableNode 
          action={action}
          key={node.id}
          node={node}
        />
      ))}
    </>
  )
}

export default TreeTable;