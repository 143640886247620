import Swal from "sweetalert2";
import { apiRequestUri } from "./helper";

export const showNotStock = (t, closeTime) => {
  Swal.fire({
    icon: "warning",
    title: t("The product has not stock to add."),
    showConfirmButton: false,
    timer: closeTime * 2,
    timerProgressBar: true,
  });
};

export const handleRemoveFromCart = async (itemId) => {
  let uri = "cart/" + itemId;
  return await apiRequestUri(uri, "DELETE")
    .then(() => {
      return true;
    })
    .catch(() => {
      return true;
    });
};

export const handleUpdateCartQty = async (itemId, quantity, t, closeTime) => {
  let uri = "cart/" + itemId;
  return await apiRequestUri(uri, "PUT", { quantity: quantity })
    .then((response) => {
      if (
        response?.response?.data?.error &&
        response.response.data.error === "The product has not stock to add."
      ) {
        showNotStock(t, closeTime);
        return false;
      } else return true;
    })
    .catch((error) => {
      if (
        error?.response?.data?.error &&
        error.response.data.error === "The product has not stock to add."
      ) {
        showNotStock(t, closeTime);
        return false;
      } else return true;
    });
};
