export const TableMapping = {
  // column: data
  ID: {
    property: "id",
    sortable: true,
    transform: false,
  },
  Status: {
    property: "status",
    sortable: true,
    transform: (data, a, b, t) => {
      if (data === "done")
        return (
          <div
            className="bg-success p-1 text-light fw-bold"
            style={{ width: 100, height: 30 }}
          >
            {t(data)}
          </div>
        );
      if (data === "paid")
        return (
          <div
            className="bg-warning p-1 fw-bold"
            style={{ width: 100, height: 30 }}
          >
            {t(data)}
          </div>
        );
      if (!data) data = "-";
      return (
        <div
          className="bg-danger p-1 text-light fw-bold"
          style={{ width: 100, height: 30 }}
        >
          {t(data)}
        </div>
      );
    },
  },
  "Shipment Status": {
    property: "shipment",
    sortable: true,
    transform: (data, a, b, t) => {
      if (data?.status && data.status === "error")
        return (
          <div
            className="bg-danger p-1 text-light fw-bold"
            style={{ width: 100, height: 30 }}
          >
            {t(data.status)}
          </div>
        );
      if (data?.status && data.status === "new")
        return (
          <div
            className="bg-warning p-1 text-dark fw-bold"
            style={{ width: 100, height: 30 }}
          >
            {t(data.status)}
          </div>
        );
      return (
        <div
          className="bg-danger p-1 text-light fw-bold"
          style={{ width: 100, height: 30 }}
        >
          {t(data?.status ? data.status : "-")}
        </div>
      );
    },
  },
  User: {
    property: "user_name",
    sortable: true,
    transform: false,
  },
  Email: {
    property: "user_email",
    sortable: true,
    transform: false,
  },
  Telephone: {
    property: "address_telephone",
    sortable: true,
    transform: false,
  },
  Mobile: {
    property: "address_mobile",
    sortable: true,
    transform: false,
  },
  Verified: {
    property: "user_validated",
    sortable: true,
    transform: "check",
  },
  Total: {
    property: "total",
    sortable: true,
    transform: "price",
  },
  Updated: {
    property: "updated_at",
    sortable: true,
    transform: "date",
  },
};
