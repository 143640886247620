import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Button, Col, Form, Row } from "react-bootstrap";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { sendQuestionsEmail } from "helpers/sendQuestionsEmail";

export const QuestionForm = ({
  productTitle,
  customer,
  productId,
  customerPath,
  className,
  refetch,
}) => {
  const { remove } = UserData();

  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [question, setQuestion] = useState();
  const [questioning, setQuestioning] = useState(false);
  const [goto, setGoto] = useState();

  const answerLogin = () => {
    Swal.fire({
      icon: "warning",
      title: t("To create questions you must log in first."),
      showConfirmButton: true,
      confirmButtonText: t("Go to Login"),
    }).then((result) => {
      if (result.isConfirmed) {
        remove();
        appLogout();
        setGoto("/" + customerPath + "/login");
        return;
      }
    });
  };

  const sendQuestion = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const uri = "product/" + productId + "/question";
    const data = {
      question: question,
    };
    setQuestioning(true);
    apiRequestUri(uri, "POST", data)
      .then((response) => {
        refetch();
        setQuestion("");
        setQuestioning(false);
        sendQuestionsEmail(productTitle, customer, question, t);

        Swal.fire({
          icon: "success",
          title: t("Question has been sent."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        setQuestioning(false);
        answerLogin();
      });
  };

  const onInputChange = ({ target }) => {
    setQuestion(target.value);
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Form onSubmit={sendQuestion} className={className}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Row className="w-100">
          <Col>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={t("Question")}
              required
              value={question ? question : ""}
              onChange={onInputChange}
            />
          </Col>
          <Col md={1} className="my-auto">
            <Button variant="warning" type="submit">
              {questioning ? <LoadingSpinner /> : t("Submit Question")}
            </Button>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};
