import Swal from "sweetalert2";
import { ClientView } from "./Client";
import { useForm } from "hooks/useForm";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequestUri } from "helpers/helper";
import { useNavigate } from "react-router-dom";
import { UserData } from "helpers/ClientHelper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Container, Row, Col, Button } from "react-bootstrap";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ClientReset = ({ customerPath, customer }) => {
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const navigate = useNavigate();
  const { data: user } = UserData();
  const [goto, setGoto] = useState();
  const backgroundColor =
    customer && customer.data ? customer.data.style.cardcolor : "white";

  const initialForm = useMemo(
    () => ({
      password: "",
      password_confirmation: "",
    }),
    []
  );

  const { formState, password, password_confirmation, onInputChange } =
    useForm(initialForm);
  const defaultButton = (
    <Button
      variant="warning"
      as="input"
      type="submit"
      value={t("Change password")}
    />
  );
  const [currentButton, setButton] = useState(defaultButton);

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [navigate, goto]);

  const onSubmit = (event) => {
    event.preventDefault();
    setButton(<LoadingSpinner />);
    let uri = "user/" + user?.data?.id;
    apiRequestUri(uri, "PUT", formState)
      .then((result) => {
        if (result) {
          Swal.fire({
            icon: "success",
            title: t("The password has been changed successfully."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then(() => {
            setGoto("/" + customerPath + "/profile");
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("There was an error."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setButton(defaultButton);
      });
  };
  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Container className="text-center h-100 my-5">
        <Row className="justify-content-center">
          <Col md="6">
            <div
              className="card"
              style={{
                backgroundColor: backgroundColor,
                textShadow: "1px 1px 2px white",
              }}
            >
              <div className="card-body m-3">
                <Row>
                  <Col md={1}>
                    <Link
                      to={"/" + customerPath + "/profile"}
                      className="link-secondary h4"
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="mt-3 ms-3 me-2"
                      />
                    </Link>
                  </Col>
                  <Col>
                    <h4 className="text-center m-3 mb-3">
                      {t("Change password")}
                    </h4>
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <p className="text-center small mt-0 pt-1 mb-4 ms-3">
                  {t("Enter a new password below to change your password")}
                </p>
                <form onSubmit={onSubmit}>
                  <div className="m-3 mb-4">
                    <input
                      minLength="6"
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder={t("Password")}
                      value={password}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="m-3 mb-4">
                    <input
                      minLength="6"
                      type="password"
                      className="form-control"
                      name="password_confirmation"
                      placeholder={t("Confirm Password")}
                      value={password_confirmation}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="text-center m-3">{currentButton}</div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </ClientView>
  );
};
export default ClientReset;
