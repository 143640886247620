import Swal from "sweetalert2";
import { apiRequestUri } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { ClientView } from "views/client/Client";
import { useCallback, useEffect, useState } from "react";
import { CartData, Product } from "helpers/ClientHelper";
import { CardDetail } from "components/client/CardDetail";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ClientQuestions } from "components/client/question/ClientQuestions";
import { Container, Button, Row, ButtonGroup, Col } from "react-bootstrap";

export const ProductDetail = ({ customer, customerPath }) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [content, setContent] = useState(
    <Col className="text-center">
      <LoadingSpinner />
    </Col>
  );
  const [adding, setAdding] = useState(false);
  const [goto, setGoto] = useState();

  const product = Product(id);
  const { isRefetching, isLoading, isFetching, refetch } = CartData(
    customer.id
  );

  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;

  const handleAddToCart = useCallback(() => {
    const answerLogin = () => {
      Swal.fire({
        icon: "warning",
        title: t("To add products to the shopping cart you must log in first."),
        showConfirmButton: true,
        confirmButtonText: t("Go to Login"),
      }).then((result) => {
        if (result.isConfirmed) {
          setGoto("/" + customerPath + "/login");
          return;
        }
      });
    };

    const showNotStock = () => {
      Swal.fire({
        icon: "warning",
        title: t("The product has not stock to add."),
        showConfirmButton: false,
        timer: closeTime * 2,
        timerProgressBar: true,
      });
    };

    let uri = "cart";
    let data = {
      product_id: id,
      quantity: quantity,
      customer_id: customer.id,
    };
    setAdding(true);
    apiRequestUri(uri, "POST", data)
      .then((response) => {
        refetch();
        setAdding(false);
      })
      .catch((error) => {
        if (
          error?.response?.data?.error &&
          error.response.data.error === "The product has not stock to add."
        ) {
          showNotStock();
        } else answerLogin();
        setAdding(false);
      });
  }, [
    id,
    quantity,
    setAdding,
    refetch,
    customerPath,
    t,
    closeTime,
    customer.id,
  ]);

  const incrementQuantity = useCallback(() => {
    if (quantity < product.stock) {
      setQuantity(quantity + 1);
    }
  }, [quantity, product, setQuantity]);

  const decrementQuantity = useCallback(() => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }, [quantity, setQuantity]);

  useEffect(() => {
    if (product) {
      setContent(
        <Row className="text-center w-100">
          <Col>
            <CardDetail
              product={product}
              className="text-start mb-3"
              customerPath={customerPath}
              rightData={
                product.stock && (
                  <div className="shadow h-100 d-flex">
                    <div className="my-1 mx-auto">
                      <ButtonGroup>
                        <Button onClick={decrementQuantity}>-</Button>
                        <div className="border border-primary d-flex">
                          <p className="my-auto mx-1">{quantity}</p>
                        </div>
                        <Button onClick={incrementQuantity}>+</Button>
                      </ButtonGroup>
                      <br />
                      <ButtonGroup className="mt-3">
                        <Button onClick={handleAddToCart} variant="success">
                          {isRefetching || isLoading || isFetching || adding ? (
                            <LoadingSpinner />
                          ) : (
                            t("Add to cart")
                          )}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                )
              }
            />
          </Col>
          <Col className="text-start shadow">
            {product && (
              <ClientQuestions
                className="mx-5"
                productTitle={product.title}
                productId={id}
                customerPath={customerPath}
                customer={customer}
              />
            )}
          </Col>
        </Row>
      );
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    quantity,
    isRefetching,
    isLoading,
    isFetching,
    adding,
    customer,
    customerPath,
    decrementQuantity,
    handleAddToCart,
    id,
    incrementQuantity,
    product,
    goto,
    navigate,
    t,
  ]);

  return (
    <ClientView customer={customer} customerPath={customerPath}>
      <Container fluid className="mt-3 text-center">
        {content}
      </Container>
    </ClientView>
  );
};
