import { Col, Row } from "react-bootstrap";
import { ProductItem } from "./ProductItem";

export default function CategoryList({ customer, customerPath, products }) {
  return (
    <Row>
      {products?.data.map((product) => {
        return (
          <Col key={product.id} className="col-md-4 d-flex align-items-stretch">
            <ProductItem
              product={product}
              customer={customer}
              customerPath={customerPath}
            />
          </Col>
        );
      })}
    </Row>
  );
}
