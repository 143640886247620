import React from "react";
import { Image } from "components/Image";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./carousel.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel as Base } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CatalogeCarousel = ({ images }) => {
  const componentStyle = `
    .carousel-control-prev {
      opacity: 1;
    }
    .carousel-control-next {
      opacity: 1;
    }
  `;
  return (
    <>
      <style>{componentStyle}</style>
      <Base
        className="overflow-hidden"
        prevIcon={
          <FontAwesomeIcon
            className={`${styles.icon}`}
            icon={faChevronLeft}
            size="2x"
          />
        }
        nextIcon={
          <FontAwesomeIcon
            className={`${styles.icon}`}
            icon={faChevronRight}
            size="2x"
          />
        }
      >
        {images.map((item, index) => (
          <Base.Item key={index}>
            <Image
              className="d-block w-100 object-fit-cover"
              src={item.image}
              alt={item.title || `Slide ${index + 1}`}
              height="350px"
            />
            <Base.Caption>
              <h3 className={`fw-bold text-dark display-4 ${styles.title}`}>
                {item.title}
              </h3>
              <p className={`fw-bold text-dark fs-2 ${styles.description}`}>
                {item.description}
              </p>
            </Base.Caption>
          </Base.Item>
        ))}
      </Base>
    </>
  );
};

export default CatalogeCarousel;
