import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import JsonTable from "components/JsonTable";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Link, useNavigate } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { TableMapping } from "./data/TableCarouselMapping";
import { LoadingSpinner } from "components/LoadingSpinner";

export const AdminCarousel = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: customer, refetch: refetchCustomer, remove } = UserData();
  const [loading, setLoading] = useState(false);
  const [goto, setGoto] = useState();

  const createButton = (
    <Link
      key="/admin/config/carousel/create"
      to="/admin/config/carousel/create"
      className="btn btn-primary"
    >
      {t("Create")}
    </Link>
  );

  const deleteItem = (index) => {
    setLoading(true);
    const swalElement = Swal.fire({
      icon: "error",
      title: t("Deleting ..."),
      showConfirmButton: false,
    });
    const uri = "user/" + customer.data.id;
    refetchCustomer().then((response) => {
      let newData = response.data.data.data;
      newData.carousel.splice(index, 1);
      apiRequestUri(uri, "PUT", { data: newData })
        .then(() => {
          swalElement.close();
          refetchCustomer().then(() => {
            setLoading(false);
          });
        })
        .catch((error) => {
          remove();
          appLogout().then(() => {
            setGoto("/login");
          });
        });
    });
  };

  let action = {
    Edit: {
      url: "/admin/config/carousel/edit",
      addId: true,
    },
    Delete: {
      callback: deleteItem,
    },
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Container className={`my-5 text-center mx-0 mw-100 ${className}`}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <JsonTable
          title={t("Carousel")}
          mapping={TableMapping}
          titleElement={createButton}
          action={action}
          initialData={customer?.data?.data?.carousel || []}
        />
      )}
    </Container>
  );
};
