import { Image } from "components/Image";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PurchaseItem = ({ item }) => {
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  const { t } = useTranslation();
  const formattedPrice = item.amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
  let image;
  let name;
  if (item.product) {
    if (
      item.product.images &&
      item.product.images.length > 0 &&
      item.product.images[0]
    ) {
      image = item.product.images[0].url;
    }
    name = item.product.title;
  }
  return (
    <Row className="my-3 align-items-center">
      <Col>
        {image ? (
          <Image src={image} alt={name} width="300px" className="mr-3" />
        ) : (
          ""
        )}
      </Col>
      <Col className="text-start">
        <h5>{name}</h5>
        <p className="my-2">
          <strong>{t("Quantity")}:&nbsp;</strong>
          {item.quantity}
        </p>
        <p className="my-2">
          <strong>{t("Price")}:&nbsp;</strong>
          {unitPrice + formattedPrice}
        </p>
      </Col>
    </Row>
  );
};

export default PurchaseItem;
