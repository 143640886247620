import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Form } from "components/form/Form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Container, Row, Col } from "react-bootstrap";
import { FormInputs } from "./data/FormContactInputs";
import { useCallback, useMemo, useState } from "react";
import { FormMapping } from "./data/FormContactMapping";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";

const AdminContactForm = ({ id }) => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const { data: customer, refetch: refetchCustomer, remove } = UserData();
  let formAction = t("Create  - Contact & Networks");
  let labelPrefix = "";
  let buttonText = t("Store");

  let target = useMemo(
    () => ({
      method: "PUT",
      uri: "user/",
      redirectUrl: "/admin/config/contact",
    }),
    []
  );

  if (id) {
    formAction = t("Edit - Contact & Networks");
    buttonText = t("Update");
  }
  const navigate = useNavigate();
  const [content, setContent] = useState(<LoadingSpinner />);
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  const handleShow = useCallback(() => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then(() => {
      remove();
      appLogout().then(() => {
        setGoto("/login");
      });
    });
  }, [remove, closeTime, t]);

  const onSubmit = useCallback(
    (formState, event) => {
      refetchCustomer().then((response) => {
        if (!response || !response.data) {
          handleShow();
          return;
        }
        let newData = response.data.data.data;
        if (!newData) {
          newData = {};
        }
        if (!Object.hasOwnProperty.call(newData, "contact")) {
          newData["contact"] = [];
        }
        newData.contact = formState;
        refetchCustomer().then(() => {
          apiRequestUri(target.uri, target.method, { data: newData }, false)
            .then(() => {
              refetchCustomer().then(() => {
                setGoto(target.redirectUrl);
                return;
              });
            })
            .catch(() => {
              handleShow();
            });
        });
      });
    },
    [handleShow, refetchCustomer, target.method, target.uri, target.redirectUrl]
  );

  const loadContent = useCallback(
    (values) => {
      setContent(
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3 text-start">
                <h4 className="text-center m-3 mb-4">{formAction}</h4>
                <Form
                  inputs={FormInputs}
                  labelPrefix={labelPrefix}
                  buttonText={buttonText}
                  values={values}
                  target={target}
                  submitCallback={onSubmit}
                  errorCallback={handleShow}
                  update={id ? true : false}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    },
    [buttonText, formAction, handleShow, id, labelPrefix, onSubmit, target]
  );

  useEffect(() => {
    if (!loaded && customer) {
      setLoaded(true);
      if (id) {
        let values = customer.data.data.contact;
        for (const key in FormMapping) {
          const element = FormMapping[key];
          values[key] = customer.data.data.contact[element];
        }
        refetchCustomer();
        loadContent(values);
      } else {
        refetchCustomer();
        loadContent({});
      }
      target.uri = "user/" + customer.data.id;
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [
    loaded,
    customer,
    id,
    loadContent,
    refetchCustomer,
    target,
    goto,
    navigate,
  ]);

  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
export default AdminContactForm;
