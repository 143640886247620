import styled from 'styled-components';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const getPaddingLeft = (level, type) => {
  let paddingLeft = level * 20;
  if (type === 'file') paddingLeft += 20;
  return paddingLeft;
}
  
const StyledTreeNode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  padding-left: ${props => getPaddingLeft(props.level, props.type)}px;

  &:hover {
    background: lightgray;
  }
`;

const NodeIcon = styled.div`
  font-size: 12px;
  margin-right: ${props => props.marginright ? props.marginright : 5}px;
`;

const TreeSelectNode = (props) => {
  const { node, level = 0, className, onClick } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onToggle = (node) => {
    setIsOpen(!isOpen);
    onClick(node);
  }

  return (
    <div className={(className ? className : '') + ' border-top border-bottom'}>
      <StyledTreeNode level={level} type={node.type}>
        <div className="w-100 d-inline my-0 py-2 ms-2" role="button" onClick={() => onToggle(node)}>
          <NodeIcon className='d-inline'>
            { isOpen ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            ) }
          </NodeIcon>
          { node.name }
        </div>
      </StyledTreeNode>

      { isOpen && node.children.map(childNode => (
        <TreeSelectNode 
          key={childNode.id}
          {...props}
          node={childNode}
          level={level + 1}
        />
      ))}
    </div>
  );
}

export default TreeSelectNode;
