import "./translation/i18n";
import ResetView from "views/Reset";
import LoginView from "./views/Login";
import ForgotView from "views/Forgot";
import HomeView from "./views/home/Home";
import RegisterView from "./views/Register";
import "bootstrap/dist/css/bootstrap.min.css";
import ViewCart from "./views/client/ViewCart";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { useTranslation } from "react-i18next";
import { AdminView } from "./views/admin/Admin";
import UserForm from "./views/admin/users/Form";
import AdminReset from "views/admin/AdminReset";
import { OrderView } from "./views/client/Order";
import { apiRequestUri } from "./helpers/helper";
import OrderForm from "./views/admin/orders/Form";
import UserTable from "./views/admin/users/Table";
import { Cataloge } from "./views/client/Cataloge";
import { HomeTerms } from "./views/home/HomeTerms";
import React, { useEffect, useState } from "react";
import ClientReset from "views/client/ClientReset";
import { UserShow } from "./views/admin/users/Show";
import OrderTable from "./views/admin/orders/Table";
import NeedValidation from "./views/NeedValidation";
import AdminVerify from "./views/admin/AdminVerify";
import ProductForm from "./views/admin/products/Form";
import { OrderShow } from "./views/admin/orders/Show";
import PaymentForm from "./views/admin/payments/Form";
import ClientVerify from "./views/client/ClientVerify";
import Purchase from "./views/client/purchase/Purchase";
import ProductTable from "./views/admin/products/Table";
import QuestionForm from "./views/admin/questions/Form";
import AdminComplain from "./views/admin/AdminComplain";
import PaymentTable from "./views/admin/payments/Table";
import { ClientTerms } from "./views/client/ClientTerms";
import { HomePolicies } from "./views/home/HomePolicies";
import { ClientAbout } from "./views/client/ClientAbout";
import CategoryForm from "./views/admin/categories/Form";
import { PaymentShow } from "./views/admin/payments/Show";
import { ProductShow } from "./views/admin/products/Show";
import QuestionTable from "./views/admin/questions/Table";
import { AdminProfile } from "./views/admin/AdminProfile";
import CategoryTable from "./views/admin/categories/Table";
import { ClientRefund } from "./views/client/ClientRefund";
import { QuestionShow } from "./views/admin/questions/Show";
import { ClientProfile } from "./views/client/ClientProfile";
import { CategoryShow } from "./views/admin/categories/Show";
import { ClientPolicies } from "./views/client/ClientPolicies";
import { QueryClientProvider, QueryClient } from "react-query";
import PurchaseShow from "./views/client/purchase/PurchaseShow";
import ProductImageTable from "./views/admin/products/image/Table";
import UserComplainTable from "./views/admin/users/complain/Table";
import { ViewCategory } from "./views/client/category/ViewCategory";
import TemplateEmailOrder from "components/email/TemplateEmailOrder";
import CategoryImageTable from "./views/admin/categories/image/Table";
import { AdminRedirection } from "./components/admin/AdminRedirection";
import TemplateEmailForgot from "components/email/TemplateEmailForgot";
import AdminAboutForm from "./views/admin/configuration/AdminAboutForm";
import AdminStyleForm from "./views/admin/configuration/AdminStyleForm";
import ProductDocumentTable from "./views/admin/products/document/Table";
import PaymentDocumentTable from "./views/admin/payments/document/Table";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ProductDetail } from "./views/client/ProductDetail/ProductDetail";
import TemplateEmailQuestions from "components/email/TemplateEmailQuestions";
import { AdminThemes } from "./views/admin/configuration/themes/AdminThemes";
import AdminOpenGraphForm from "views/admin/configuration/AdminOpenGraphForm";
import { AdminContact } from "./views/admin/configuration/contact/AdminContact";
import { AdminAddress } from "./views/admin/configuration/address/AdminAddress";
import TemplateClientEmailOrder from "components/email/TemplateEmailClientOrder";
import { AdminCarousel } from "./views/admin/configuration/carousel/AdminCarousel";
import AdminContactForm from "./views/admin/configuration/contact/AdminContactForm";
import AdminAddressForm from "./views/admin/configuration/address/AdminAddressForm";
import AdminCarouselForm from "./views/admin/configuration/carousel/AdminCarouselForm";
import { AdminPaymentMethods } from "views/admin/configuration/paymentMethods/AdminPaymentMethods";
import AdminPaymentMethodsForm from "views/admin/configuration/paymentMethods/AdminPaymentMethodForm";
import AdminShipmentMethodsForm from "views/admin/configuration/shipmentMethods/AdminShipmentMethodForm";

const queryClient = new QueryClient();

function App() {
  // https://schema.org/WebSite
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "OnlineStore",
    "@id": "Store by WebComprehensive",
    url: "https://store.webcomprehensive.com/",
    name: "Store - by WebComprehensive",
    alternateName: "Store - WC",
    description: "Your first custom store by WebComprehensive",
    image: "https://webcomprehensive.com/logo.png",
    logo: "https://webcomprehensive.com/logo.png",
    parentOrganization: {
      "@type": "OnlineBusiness",
      name: "Web Comprehensive",
      url: "https://webcomprehensive.com/",
    },
    foundingDate: "2024-08-27",
  };

  let [customers, setData] = useState([]);

  useEffect(() => {
    let uri = "customer";
    apiRequestUri(uri, "GET", {}, false)
      .then((response) => {
        if (response) {
          setData(response.data);
        }
      })
      .catch((error) => {
        console.log("Error getting customer on App.js", error);
      });
  }, []);

  const adminRoutes = [
    <Route
      key="/admin/profile"
      exact
      path="/admin/profile"
      element={
        <AdminView>
          <AdminProfile />
        </AdminView>
      }
    />,
    <Route
      key="/admin/verify"
      exact
      path="/admin/verify"
      element={
        <AdminView>
          <AdminVerify />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/about"
      exact
      path="/admin/config/about"
      element={
        <AdminView>
          <AdminAboutForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/opengraph"
      exact
      path="/admin/config/opengraph"
      element={
        <AdminView>
          <AdminOpenGraphForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/payment_methods"
      exact
      path="/admin/config/payment_methods"
      element={
        <AdminView>
          <AdminPaymentMethods />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/payment_methods/create"
      exact
      path="/admin/config/payment_methods/create"
      element={
        <AdminView>
          <AdminPaymentMethodsForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/payment_methods/edit/:id"
      exact
      path="/admin/config/payment_methods/edit/:id"
      element={
        <AdminView>
          <AdminPaymentMethodsForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/shipment"
      exact
      path="/admin/config/shipment"
      element={
        <AdminView>
          <AdminShipmentMethodsForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/carousel"
      exact
      path="/admin/config/carousel"
      element={
        <AdminView>
          <AdminCarousel />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/carousel/create"
      exact
      path="/admin/config/carousel/create"
      element={
        <AdminView>
          <AdminCarouselForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/carousel/edit/:id"
      exact
      path="/admin/config/carousel/edit/:id"
      element={
        <AdminView>
          <AdminCarouselForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/address"
      exact
      path="/admin/config/address"
      element={
        <AdminView>
          <AdminAddress />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/address/create"
      exact
      path="/admin/config/address/create"
      element={
        <AdminView>
          <AdminAddressForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/contact"
      exact
      path="/admin/config/contact"
      element={
        <AdminView>
          <AdminContact />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/contact/create"
      exact
      path="/admin/config/contact/create"
      element={
        <AdminView>
          <AdminContactForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/contact/edit"
      exact
      path="/admin/config/contact/edit"
      element={
        <AdminView>
          <AdminContactForm id={true} />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/style"
      exact
      path="/admin/config/style"
      element={
        <AdminView>
          <AdminStyleForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/config/themes"
      exact
      path="/admin/config/themes"
      element={
        <AdminView>
          <AdminThemes />
        </AdminView>
      }
    />,
    <Route
      key="/admin/product"
      exact
      path="/admin/product"
      element={
        <AdminView>
          <ProductTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/product/:id"
      exact
      path="/admin/product/:id"
      element={
        <AdminView>
          <ProductShow />
        </AdminView>
      }
    />,
    <Route
      key="/admin/product/create"
      exact
      path="/admin/product/create"
      element={
        <AdminView>
          <ProductForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/product/edit/:id"
      exact
      path="/admin/product/edit/:id"
      element={
        <AdminView>
          <ProductForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/product/image/:id"
      exact
      path="/admin/product/image/:id"
      element={
        <AdminView>
          <ProductImageTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/product/document/:id"
      exact
      path="/admin/product/document/:id"
      element={
        <AdminView>
          <ProductDocumentTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/category"
      exact
      path="/admin/category"
      element={
        <AdminView>
          <CategoryTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/category/:id"
      exact
      path="/admin/category/:id"
      element={
        <AdminView>
          <CategoryShow />
        </AdminView>
      }
    />,
    <Route
      key="/admin/category/create"
      exact
      path="/admin/category/create"
      element={
        <AdminView>
          <CategoryForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/category/edit/:id"
      exact
      path="/admin/category/edit/:id"
      element={
        <AdminView>
          <CategoryForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/category/image/:id"
      exact
      path="/admin/category/image/:id"
      element={
        <AdminView>
          <CategoryImageTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/order"
      exact
      path="/admin/order"
      element={
        <AdminView>
          <OrderTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/order/:id"
      exact
      path="/admin/order/:id"
      element={
        <AdminView>
          <OrderShow />
        </AdminView>
      }
    />,
    <Route
      key="/admin/order/edit/:id"
      exact
      path="/admin/order/edit/:id"
      element={
        <AdminView>
          <OrderForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/question"
      exact
      path="/admin/question"
      element={
        <AdminView>
          <QuestionTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/question/:id"
      exact
      path="/admin/question/:id"
      element={
        <AdminView>
          <QuestionShow />
        </AdminView>
      }
    />,
    <Route
      key="/admin/question/edit/:id"
      exact
      path="/admin/question/edit/:id"
      element={
        <AdminView>
          <QuestionForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/user"
      exact
      path="/admin/user"
      element={
        <AdminView>
          <UserTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/user/:id"
      exact
      path="/admin/user/:id"
      element={
        <AdminView>
          <UserShow />
        </AdminView>
      }
    />,
    <Route
      key="/admin/user/edit/:id"
      exact
      path="/admin/user/edit/:id"
      element={
        <AdminView>
          <UserForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/complain"
      exact
      path="/admin/complain"
      element={
        <AdminView>
          <AdminComplain />
        </AdminView>
      }
    />,
    <Route
      key="/admin/user/complain/:id"
      exact
      path="/admin/user/complain/:id"
      element={
        <AdminView>
          <UserComplainTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/payment"
      exact
      path="/admin/payment"
      element={
        <AdminView>
          <PaymentTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/payment/:id"
      exact
      path="/admin/payment/:id"
      element={
        <AdminView>
          <PaymentShow />
        </AdminView>
      }
    />,
    <Route
      key="/admin/payment/edit/:id"
      exact
      path="/admin/payment/edit/:id"
      element={
        <AdminView>
          <PaymentForm />
        </AdminView>
      }
    />,
    <Route
      key="/admin/payment/document/:id"
      exact
      path="/admin/payment/document/:id"
      element={
        <AdminView>
          <PaymentDocumentTable />
        </AdminView>
      }
    />,
    <Route
      key="/admin/password/change"
      exact
      path="/admin/password/change"
      element={
        <AdminView>
          <AdminReset />
        </AdminView>
      }
    />,
  ];

  let routes = [];
  for (let customer of customers) {
    let customerPath = customer.name.toLowerCase().replace(/ /g, "_");
    let path = "/" + customerPath;
    if (customer.validated !== 1 || customer.deleted === 1) {
      routes.push(
        <Route key={path} path={path} element={<NeedValidation />} />
      );
      path = "/" + customerPath + "/login";
      routes.push(
        <Route
          key={path}
          path={path}
          element={<Navigate to={"/" + customerPath} replace />}
        />
      );
      continue;
    }
    routes.push(
      <Route
        key={path}
        path={path}
        element={<Cataloge customerPath={customerPath} customer={customer} />}
      />
    );
    path = "/" + customerPath + "/about";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ClientAbout customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/terms";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ClientTerms customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/policies";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ClientPolicies customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/refund";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ClientRefund customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/products/:id";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ProductDetail customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/category/:name";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ViewCategory customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/cart";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<ViewCart customer={customer} customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/order";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<OrderView customer={customer} customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/order/:payment_id/notification";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<OrderView customer={customer} customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/purchase";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<Purchase customer={customer} customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/purchase/:id";
    routes.push(
      <Route
        key={path}
        exact
        path={path}
        element={
          <PurchaseShow customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/profile";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ClientProfile customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/password/change";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ClientReset customer={customer} customerPath={customerPath} />
        }
      />
    );
    path = "/" + customerPath + "/login";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<LoginView customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/register";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<RegisterView customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/forgot";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<ForgotView customer={customer} customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/password/reset";
    routes.push(
      <Route
        key={path}
        path={path}
        element={<ResetView customerPath={customerPath} />}
      />
    );
    path = "/" + customerPath + "/verify";
    routes.push(
      <Route
        key={path}
        path={path}
        element={
          <ClientVerify customer={customer} customerPath={customerPath} />
        }
      />
    );
  }

  const testUser = { name: "testuser", email: "test@test.com" };
  const testCustomer = {
    name: "testuser",
    email: "test@test.com",
    data: null,
  };
  const testOrder = { id: 14556, cart: { total: 98765 } };
  const { t } = useTranslation();

  const testRoutes = [
    <Route
      key="/email/client-order"
      path="/email/client-order"
      element={
        <TemplateClientEmailOrder
          user={testUser}
          customer={testCustomer}
          hostUrl={"http://localhost:2000"}
          order={testOrder}
          firstUrlValue={"testuser"}
          t={t}
        />
      }
    />,
    <Route
      key="/email/forgot"
      path="/email/forgot"
      element={
        <TemplateEmailForgot
          token="6fds5g4dfs65g4dfs65g4df6s5g4fd6s5g46sfd"
          hostUrl="http://localhost:2000"
          email={testUser.email}
          t={t}
        />
      }
    />,
    <Route
      key="/email/forgot-client"
      path="/email/forgot-client"
      element={
        <TemplateEmailForgot
          customer={testCustomer}
          customerPath="testuser"
          token="6fds5g4dfs65g4dfs65g4df6s5g4fd6s5g46sfd"
          hostUrl="http://localhost:2000"
          email={testUser.email}
          t={t}
        />
      }
    />,
    <Route
      key="/email/order"
      path="/email/order"
      element={
        <TemplateEmailOrder
          user={testUser}
          customer={testCustomer}
          hostUrl={"http://localhost:2000"}
          order={testOrder}
          t={t}
        />
      }
    />,
    <Route
      key="/email/question"
      path="/email/question"
      element={
        <TemplateEmailQuestions
          productTitle={"Product Test"}
          question={"This is a test question?"}
          name={testUser.name}
          email={testUser.email}
          firstUrlValue={"firstUrlValue"}
          customer={testCustomer}
          t={t}
        />
      }
    />,
    <Route
      key="/test/need-validation"
      path="/test/need-validation"
      element={<NeedValidation />}
    />,
  ];

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeView />} />
            {routes}
            <Route path="/login" element={<LoginView />} />
            <Route path="/register" element={<RegisterView />} />
            <Route path="/forgot" element={<ForgotView />} />
            <Route path="/password/reset" element={<ResetView />} />
            <Route exact path="/admin" element={<AdminRedirection />} />
            <Route exact path="/terms" element={<HomeTerms />} />
            <Route exact path="/policies" element={<HomePolicies />} />
            {testRoutes}
            {adminRoutes}
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
