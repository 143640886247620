import { useEffect, useState } from "react";

export const Image = ({
  className,
  style,
  src,
  alt,
  width,
  height,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [source, setSource] = useState(src);
  const [hasError, sethasError] = useState(false);
  const handleError = () => {
    sethasError(true);
    setSource("/images/no-image-available.webp");
  };
  useEffect(() => {
    if (!hasError && src) {
      setSource(src);
    }
  }, [src, hasError]);
  return (
    <img
      className={className}
      src={source}
      alt={alt}
      style={style}
      width={width}
      height={height}
      onError={handleError}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};
