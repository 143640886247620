import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { useForm } from "../hooks/useForm";
import { useTranslation } from "react-i18next";
import { apiRequestUri } from "../helpers/helper";
import { UserData } from "../helpers/ClientHelper";
import { useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResetView = ({ customerPath }) => {
  const { t } = useTranslation();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const navigate = useNavigate();
  const { data: user } = UserData();
  const [goto, setGoto] = useState();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const initialForm = useMemo(
    () => ({
      token: token,
      email: email,
      password: "",
      password_confirmation: "",
    }),
    [email, token]
  );

  const { formState, password, password_confirmation, onInputChange } =
    useForm(initialForm);
  const defaultButton = (
    <Button as="input" type="submit" value={t("Change password")} />
  );
  const [currentButton, setButton] = useState(defaultButton);

  useEffect(() => {
    if (user && user.data) {
      if (user.data.role_name !== "client") {
        navigate("/admin");
      } else {
        navigate("/" + customerPath);
      }
    }
    if (!token || !email) {
      if (!customerPath) {
        navigate("/login");
      } else {
        navigate("/" + customerPath + "/login");
      }
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [customerPath, navigate, goto, email, token, user]);

  const onSubmit = (event) => {
    event.preventDefault();
    setButton(<LoadingSpinner />);
    let uri = "reset-password";
    apiRequestUri(uri, "POST", formState)
      .then((result) => {
        if (result) {
          Swal.fire({
            icon: "success",
            title: t("The password has been changed successfully."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then(() => {
            if (!customerPath) {
              setGoto("/login");
            } else {
              setGoto("/" + customerPath + "/login");
            }
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("There was an error."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        setButton(defaultButton);
      });
  };
  return (
    <div className="d-flex align-items-center min-vh-100 bg-primary">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="card">
              <div className="card-body m-3">
                <Row>
                  <Col xs={1}>
                    <Link
                      to={customerPath ? "/" + customerPath : "/"}
                      className="link-secondary h4"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="mt-3 ms-3 me-2"
                      />
                    </Link>
                  </Col>
                  <Col>
                    <h4 className="text-center mx-3 my-3">
                      {t("Reset account password")}
                    </h4>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
                <p className="text-center small mt-0 pt-1 mb-4 ms-3">
                  {t("Enter a new password below to change your password")}
                </p>
                <form onSubmit={onSubmit}>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      minLength="6"
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder={t("Password")}
                      value={password}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="m-md-3 mb-md-4 mb-2">
                    <input
                      minLength="6"
                      type="password"
                      className="form-control"
                      name="password_confirmation"
                      placeholder={t("Confirm Password")}
                      value={password_confirmation}
                      onChange={onInputChange}
                      required
                    />
                  </div>
                  <div className="text-center m-3">{currentButton}</div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ResetView;
