import { Accordion, Col, Row } from "react-bootstrap";

export const QuestionItem = ({ item }) => {
  const createdAt = item.created_at.split('T').join(' ').replace('.000000Z', '');
  return (
    <Accordion.Item eventKey={item.id}>
      <Accordion.Header>
        <Row className="w-100">
          <Col>
            <small className="text-danger d-inline">{item.user_name}</small>
            <div className="text-dark d-inline">&nbsp;|&nbsp;</div>
            <small className="text-secondary d-inline">{createdAt}</small>
          </Col>
          <Col className="text-end">
            {item.question}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>{item.answer}</Accordion.Body>
    </Accordion.Item>
  );
};
