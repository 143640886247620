import { apiRequestUri } from "./helper";
import { UserData } from "./ClientHelper";
import { render } from "@react-email/render";
import TemplateEmailQuestions from "components/email/TemplateEmailQuestions";

export const sendQuestionsEmail = async (
  productTitle,
  customer,
  question,
  t
) => {
  const { data: user } = UserData();
  const { name, email: userEmail } = user.data;
  const { email: customerEmail } = customer;

  const urlParts = window.location.pathname.split("/");
  const firstUrlValue = urlParts.length > 1 ? urlParts[1] : "";

  const emailHtml = render(
    <TemplateEmailQuestions
      productTitle={productTitle}
      question={question}
      name={name}
      email={userEmail}
      firstUrlValue={firstUrlValue}
      customer={customer}
      t={t}
    />
  );

  const emailData = {
    email: customerEmail,
    title: "STORE WebComprehensive - " + t("New question in your store"),
    body: emailHtml,
  };

  try {
    await apiRequestUri("mail", "POST", emailData);
  } catch (error) {
    console.error("Error sending email:", error);
  }
};
