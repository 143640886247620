import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import JsonTable from "components/JsonTable";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Link, useNavigate } from "react-router-dom";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TableMapping } from "./data/TablePaymentMethodsMapping";

export const AdminPaymentMethods = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: customer, refetch: refetchCustomer, remove } = UserData();
  const [loading, setLoading] = useState(false);
  const [goto, setGoto] = useState();

  const createButton = (
    <Link
      key="/admin/config/payment_methods/create"
      to="/admin/config/payment_methods/create"
      className="btn btn-primary"
    >
      {t("Add")}
    </Link>
  );

  const deleteItem = (index) => {
    Swal.fire({
      icon: "error",
      title:
        t("Are you sure you want to delete the Payment Method ") + index + "?",
      showConfirmButton: true,
      confirmButtonText: t("Yes, delete it!"),
      showDenyButton: true,
      denyButtonText: t("No"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const uri = "user/" + customer.data.id;
        refetchCustomer().then((response) => {
          let newData = response.data.data.data;
          delete newData.payment_methods[index];
          apiRequestUri(uri, "PUT", { data: newData })
            .then(() => {
              refetchCustomer().then(() => {
                setLoading(false);
              });
            })
            .catch((error) => {
              remove();
              appLogout().then(() => {
                setGoto("/login");
              });
            });
        });
      }
    });
  };

  let action = {
    Edit: {
      url: "/admin/config/payment_methods/edit",
      addId: true,
    },
    Delete: {
      callback: deleteItem,
    },
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <Container className={`my-5 text-center mx-0 mw-100 ${className}`}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <JsonTable
          title={t("Payment Methods")}
          titleElement={createButton}
          mapping={TableMapping}
          action={action}
          initialData={
            (customer?.data?.data?.payment_methods &&
              Object.keys(customer?.data?.data?.payment_methods).map(
                (key) => customer?.data?.data?.payment_methods[key]
              )) ||
            []
          }
        />
      )}
    </Container>
  );
};
