export const inputs = [
  {
    type: "text",
    name: "address_line1",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "address_line2",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "city",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "postal_code",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "state",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "country",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "telephone",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "text",
    name: "mobile",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
];