import Swal from "sweetalert2";
import { Rating } from "@mui/material";
import { useParams } from "react-router-dom";
import { apiRequestUri } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { ClientView } from "views/client/Client";
import { useCallback, useEffect, useState } from "react";
import { Purchase, UserData } from "helpers/ClientHelper";
import { LoadingSpinner } from "components/LoadingSpinner";
import PurchaseItem from "views/client/purchase/PurchaseItem";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

const PurchaseShow = ({ customer, customerPath }) => {
  const { data: user } = UserData();
  const { t } = useTranslation();
  const backgroundColor =
    customer && customer.data ? customer.data.style.cardcolor : "white";
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  let { id } = useParams();
  const { data: purchase, refetch } = Purchase(id);
  const [content, setContent] = useState(<LoadingSpinner />);
  const [showRating, setShowRating] = useState(false);
  const [rating, setRating] = useState();
  const [comment, setComment] = useState();
  const defaultButton = "Rate";
  const [btnContent, setBtnContent] = useState(defaultButton);
  const [hideRate, setHideRate] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const loadContent = useCallback(
    (purchase) => {
      const errorHandler = () => {
        Swal.fire({
          icon: "error",
          title: t("An error has occurred."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        }).then((result) => {
          setBtnContent(defaultButton);
        });
      };

      const successHandler = () => {
        refetch().then((result) => {
          loadContent(result.data);
        });
        Swal.fire({
          icon: "success",
          title: t("Successfully rated."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
      };

      const handleRatingChange = (e) => {
        setRating(e.target.value);
      };

      const handleCommentChange = (e) => {
        setComment(e.target.value);
      };

      const getItem = (itemData) => {
        return (
          <PurchaseItem
            item={itemData}
            key={itemData.product.id}
            unitPrice={unitPrice}
            customerPath={customerPath}
          />
        );
      };

      const sendRate = (e) => {
        setBtnContent(<LoadingSpinner />);
        setLoaded(false);
        e.preventDefault();
        const uri = "user/rating";
        const formState = {
          user_id: customer.id,
          stars: rating,
          comment: comment,
        };
        apiRequestUri(uri, "POST", formState, false)
          .then((response) => {
            const uri = "order/" + id;
            const formState = {
              rating_id: response.id,
            };
            apiRequestUri(uri, "PUT", formState, false)
              .then((response) => {
                setHideRate(true);
                successHandler();
              })
              .catch((error) => {
                errorHandler();
              });
          })
          .catch((error) => {
            errorHandler();
          });
      };

      const showRate = () => {
        if (user && user.data && user.data.validated === 1) {
          setShowRating(true);
          setLoaded(false);
        } else {
          Swal.fire({
            icon: "error",
            title: t(
              "You are not verified, verify yourself in your profile to be able to rate the seller."
            ),
            showConfirmButton: true,
          }).then((result) => {
            setBtnContent(defaultButton);
          });
        }
      };

      const renderItems = () => {
        if (!purchase || !purchase.cart || !purchase.cart.data) {
          return;
        }
        let col1 = [],
          col2 = [];
        let count = 0;
        if (purchase.cart.data.length === 1) {
          return (
            <Row>
              <Col className="h-100">{getItem(purchase.cart.data[0])}</Col>
            </Row>
          );
        } else {
          for (const lineItem of purchase.cart.data) {
            count % 2
              ? col2.push(getItem(lineItem))
              : col1.push(getItem(lineItem));
            count++;
          }
          return (
            <Row>
              <Col className="h-100">{col1}</Col>
              <Col className="h-100">{col2}</Col>
            </Row>
          );
        }
      };

      const formattedPrice = purchase.total.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });

      setContent(
        <Card className="m-5" style={{ backgroundColor: backgroundColor }}>
          <div className="h3 mt-2">{t("Purchase Details")}</div>
          <Card.Body>
            <div className="text-start h4">{t("Products")}:</div>
            {renderItems()}
            <Row className="text-start">
              <Col>
                <span className="h4">{t("Total")}: </span>
                <p className="d-inline">
                  {unitPrice}
                  {formattedPrice}
                </p>
                <br />
                <span className="h4">{t("Payment Status")}: </span>
                <p className="d-inline">
                  {t(purchase.status ? purchase.status : "pending")}
                </p>
              </Col>
              <Col>
                {!showRating && !purchase.rating_id ? (
                  <Button onClick={showRate}>{t("Rate seller")}</Button>
                ) : (
                  !hideRate &&
                  !purchase.rating_id && (
                    <Form onSubmit={sendRate}>
                      <Row className="align-items-center">
                        <Col md={3}>
                          <Rating size="large" onChange={handleRatingChange} />
                        </Col>
                        <Col className="text-start m-2">
                          <Button variant="success" type="submit">
                            {btnContent}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <textarea
                          value=""
                          className="form-control"
                          placeholder={t("Write a review here")}
                          onChange={handleCommentChange}
                        ></textarea>
                      </Row>
                    </Form>
                  )
                )}
                {purchase.rating_id && <>{t("Already rated")}</>}
              </Col>
            </Row>
            {purchase.shipment && (
              <div className="text-start">
                <hr />
                <span className="h4">{t("Shipment Status")}: </span>
                <p className="d-inline">
                  {t(
                    purchase.shipment.status
                      ? purchase.shipment.status
                      : "pending"
                  )}
                </p>
                {purchase.shipment.data?.tracking && (
                  <>
                    <br />
                    <span className="h4">{t("Tracking")}: </span>
                    <a
                      href={purchase.shipment.data?.tracking}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {purchase.shipment.data?.tracking}
                    </a>
                  </>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      );
    },
    [
      backgroundColor,
      btnContent,
      hideRate,
      showRating,
      unitPrice,
      comment,
      customer,
      id,
      rating,
      user,
      closeTime,
      customerPath,
      refetch,
      t,
    ]
  );

  useEffect(() => {
    if (!loaded && purchase) {
      setLoaded(true);
      loadContent(purchase);
    }
  }, [
    loaded,
    showRating,
    rating,
    comment,
    btnContent,
    hideRate,
    loadContent,
    purchase,
  ]);

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Container className="text-center">{content}</Container>
    </ClientView>
  );
};

export default PurchaseShow;
