import { useTranslation } from "react-i18next";

export const AddressItem = ({ item }) => {
  const { t } = useTranslation();
  return (
    <>
      <strong>{t("Address Line One")}:&nbsp;</strong>
      {item.address_line1}
      <br />
      <strong>{t("Address Line Two")}:&nbsp;</strong>
      {item.address_line2}
      <br />
      <strong>{t("City")}:&nbsp;</strong>
      {item.city}
      <br />
      <strong>{t("Postal Code")}:&nbsp;</strong>
      {item.postal_code}
      <br />
      <strong>{t("State")}:&nbsp;</strong>
      {item.state}
      <br />
      <strong>{t("Country")}:&nbsp;</strong>
      {item.country}
      <br />
      <strong>{t("Telephone")}:&nbsp;</strong>
      {item.telephone}
      <br />
      <strong>{t("Mobile")}:&nbsp;</strong>
      {item.mobile}
    </>
  );
};
