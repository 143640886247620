export const TableMapping = {
// column: data
  ID: {
    property: "id",
    sortable: true,
    transform: false,
  },
  Status: {
    property: "status",
    sortable: true,
    transform: {
      'onNull': 'pending'
    },
  },
  Total: {
    property: "total",
    sortable: true,
    transform: "price",
  },
  Updated: {
    property: "updated_at",
    sortable: true,
    transform: "date",
  },
};
