import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function OrderSelect({ handleOrder }) {
  const { t } = useTranslation();
  return (
    <div className="mb-2 mt-4 text-end me-3">
      <strong>{t("Order By:")} </strong>
      <Form.Select
        className="d-inline"
        style={{ width: 200 }}
        aria-label="Default select example"
        onChange={handleOrder}
      >
        <option value="featured">{t("Most Relevant")}</option>
        <option value="lowerPrice">{t("Lower Price")}</option>
        <option value="higherPrice">{t("Higher Price")}</option>
      </Form.Select>
    </div>
  );
}
