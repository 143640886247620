import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ClientView } from "views/client/Client";
import { Col, Container, Row } from "react-bootstrap";

export const ClientPolicies = ({ customer, customerPath }) => {
  const { t } = useTranslation();
  let contact;
  if (customer.data && customer.data.contact) {
    contact = customer.data.contact;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ClientView
      customer={customer}
      customerPath={customerPath}
      className={"bg-light"}
    >
      <Row className="text-center pt-md-5 mx-md-5 px-md-5 mb-5 pb-5">
        <h1 className="display-4 fw-bold mt-5 pt-5 mb-3">
          {t("Privacy Policy of")} <br />
          {customer.name}
        </h1>
        <p className="h5 mb-5">
          {t("In force from ")}
          {customer.created_at.split("T")[0]}
        </p>
        <p>
          {t("Welcome to the Privacy Policy of ")}
          {customer.name}
        </p>
        <Container>
          <h5 className="mb-5">
            {t(
              "This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from "
            )}
            https://store.webcomprehensive.com/{customerPath}
            {t(" (hereinafter referred to as the “Site”).")}
          </h5>
          <Col className="text-start">
            <h3 className="fw-bold pb-2 mt-5 pt-5">
              {t("PERSONAL INFORMATION WE COLLECT")}
            </h3>
            <hr />
            <p>
              {t(
                "When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device."
              )}
              <br />
              <br />
              {t(
                "Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, the web pages or search terms that referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”."
              )}
              <br />
              <br />
              {t(
                "We collect Device Information by using the following technologies:"
              )}
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("Cookies")}</h5>
            <p>
              {t(
                "We and our Third Party Service Providers use cookies and other similar technologies"
              )}{" "}
              (“
              <span className="fw-bold">Cookies</span>”){" "}
              {t(
                "to provide our Service and ensure that it functions correctly, to analyse our performance and marketing activities, as well as to personalize your experience"
              )}
              .
              <br />
              <br />
              {t(
                "You can find out more about how we use cookies and how you can exercise control over them in our Cookie Policy"
              )}
              .
              <br />
              <br />
              {t(
                "Please note that we do not change our practices in response to a “Do Not Track” signal in the HTTP header from a browser or mobile application; however, most browsers allow you to control cookies, including whether or not to accept them and how to delete them. You can set most browsers to notify you if you receive a cookie, or you can choose to block cookies with your browser"
              )}
              .
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("Log files")}</h5>
            <p>
              {t(
                "“Log Files” track actions occurring on the Site and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps."
              )}
            </p>
            <h5 className="fw-bold pb-2 mt-5">
              {t("Web beacons, tags and pixels")}
            </h5>
            <p>
              {t(
                "“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site."
              )}
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("Order Information")}</h5>
            <p>
              {t(
                "Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number."
              )}
              <br />
              <br />
              {t("We refer to this information as “Order Information”.")}
            </p>
            <p className="fw-bold">
              {t(
                "When we talk about “Personal Information” in this Privacy Policy, we mean both Device Information and Order Information."
              )}
            </p>
            <hr />
            <h3 className="fw-bold pb-2 mt-5 pt-5">
              {t("HOW DO WE USE YOUR PERSONAL INFORMATION?")}
            </h3>
            <hr />
            <p>
              {t(
                "We use the Order Information that we collect generally to prepare any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations)."
              )}
              <br />
              <br />
              {t("Additionally, we use this Order Information to:")}
              <br />
              <ul>
                <li>{t("communicate with you;")}</li>
                <li>{t("screen our orders for fraud or potential risk;")}</li>
                <li>
                  {t(
                    "and when, in accordance with the preferences you have shared with us, provide you with information or advertising relating to our products or services."
                  )}
                </li>
              </ul>
              {t(
                "We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating reports and statistics on how our customers browse and interact with the Site and to assess the success of our advertising and marketing campaigns)."
              )}
            </p>
            <h5 className="fw-bold pb-2 mt-5">
              {t("SHARING YOUR PERSONAL INFORMATION")}
            </h5>
            <p>
              {t(
                "We share your Personal Information with third parties to assist us in using your Personal Information, as described above."
              )}
              <br />
              {t(
                "For example, we use WebComprehensive technology in our online store."
              )}
              <br />
              <br />
              {t(
                "You can learn more about how WebComprehensive uses your Personal Information here: https://store.webcomprehensive.com/policies."
              )}
              <br />
              <br />
              {t(
                "We also use Google Analytics to help us understand how our customers use the Site."
              )}
              <br />
              {t(
                "You can learn more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/."
              )}
              <br />
              {t(
                "You can opt out of Google Analytics here: https://tools.google.com/dlpage/gaoptout."
              )}
              <br />
              <br />
              {t(
                "Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights."
              )}
            </p>
            <h3 className="fw-bold pb-2 mt-5 pt-5">
              {t("BEHAVIORAL ADVERTISING")}
            </h3>
            <hr />
            <p>
              {t(
                "As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you."
              )}
              <br />
              {t(
                "For more information about how targeted advertising works, you can visit the Network Advertising Initiative (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work."
              )}
              <br />
              <br />
              {t(
                "You can opt out of targeted advertising by following the links below:"
              )}
              <ul>
                <li>
                  {t("FACEBOOK: https://www.facebook.com/settings/?tab=ads")}
                </li>
                <li>
                  {t(
                    "GOOGLE: https://adssettings.google.com/authenticated?hl=en"
                  )}
                </li>
                <li>
                  {t(
                    "BING: https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads"
                  )}
                </li>
              </ul>
              <br />
              <br />
              {t(
                "Additionally, you may opt out of some of these services by visiting the Digital Advertising Alliance's opt-out portal at: https://optout.aboutads.info/."
              )}
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("DO NOT TRACK")}</h5>
            <p>
              {t(
                "Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track signal from your browser."
              )}
              <br />
              <br />
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("YOUR RIGHTS")}</h5>
            <p>
              {t(
                "If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted."
              )}
              <br />
              {t(
                "If you wish to exercise this right, please contact us using the contact information below."
              )}
              <br />
              {t(
                "Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above."
              )}
              <br />
              {t(
                "Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States."
              )}
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("DATA RETENTION")}</h5>
            <p>
              {t(
                "When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information."
              )}
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("MINORS")}</h5>
            <p>
              {t("The site is not intended for persons under 18 years of age.")}
            </p>
            <h5 className="fw-bold pb-2 mt-5">{t("CHANGES")}</h5>
            <p>
              {t(
                "We may update this privacy policy from time to time to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons."
              )}
            </p>
            {contact.email && (
              <>
                <h3 className="fw-bold pb-2 mt-5 pt-5">{t("CONTACT US")}</h3>
                <p>
                  {t(
                    "For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at "
                  )}
                  {contact.email}.
                </p>
              </>
            )}
          </Col>
        </Container>
      </Row>
    </ClientView>
  );
};
