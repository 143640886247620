import React from "react";
import styles from "./fadeInSection.module.css";

const FadeInSection = ({ children }) => {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <>
      <div
        className={`${styles.fadeIn} ${isVisible ? styles.fadeInVisible : ""}`}
        ref={domRef}
      >
        {children}
      </div>
    </>
  );
};

export default FadeInSection;
