import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiRequestUri } from "helpers/helper";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { ProductItem } from "components/client/ProductItem";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingSpinner } from "components/LoadingSpinner";

export const ProductShow = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [content, setContent] = useState(<LoadingSpinner />);
  const [cardTitle, setCardTitle] = useState(t("Product "));
  const [loaded, setLoaded] = useState(false);
  const { data: customer, isFetched } = UserData();

  useEffect(() => {
    if (isFetched && !loaded) {
      setLoaded(true);
      let uri = "product/" + id;
      apiRequestUri(uri, "GET", {}, false)
        .then((response) => {
          setCardTitle(cardTitle + response.title);
          setContent(
            <Row className="justify-content-center">
              <Col md="6">
                <ProductItem
                  product={response}
                  key={response.id}
                  customer={customer.data}
                  customerPath={customer.data.name
                    .toLowerCase()
                    .replace(/ /g, "_")}
                  target="_blank"
                />
              </Col>
            </Row>
          );
        })
        .catch((error) => {});
    }
  }, [cardTitle, id, isFetched, customer.data, loaded]);
  return (
    <Container fluid className="mt-5 text-center">
      {content}
    </Container>
  );
};
