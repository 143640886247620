export const TableMapping = {
// column: data
  Id: {
    property: "id",
    sortable: true,
    transform: false,
  },
  Text: {
    property: "text",
    sortable: true,
    transform: false,
  },
  Readed: {
    property: "readed",
    sortable: true,
    transform: "check",
  },
  Updated: {
    property: "updated_at",
    sortable: true,
    transform: "date",
  },
};
