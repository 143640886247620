export const FormInputs = [
  {
    type: "text",
    name: "title",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "crop",
    aspect: 2.77 / 1,
    name: "image",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
  {
    type: "textarea",
    name: "description",
    value: "",
    additionalAttrs: {
      required: true,
    },
  },
];
