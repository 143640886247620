import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { Col, Container, Row } from "react-bootstrap";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useCallback, useEffect, useMemo, useState } from "react";

export const AdminContact = ({ className }) => {
  const { data: customer } = UserData();
  const { t } = useTranslation();

  const labels = useMemo(
    () => [
      "Instagram",
      "Twitter",
      "Facebook",
      "Whatsapp",
      "Tiktok",
      "Email",
      "Footer",
    ],
    []
  );

  const [content, setContent] = useState(<LoadingSpinner />);
  const [loaded, setLoaded] = useState(false);

  const loadContent = useCallback(() => {
    let buttonText = t("Add");
    let buttonTo = "/admin/config/contact/create";
    let labelsData = [];
    if (customer.data.data && customer.data.data.contact) {
      buttonText = t("Edit");
      buttonTo = "/admin/config/contact/edit";
      for (const label of labels) {
        labelsData.push(
          <>
            <strong>{label}:&nbsp;</strong>
            {customer.data.data.contact[label.toLowerCase()]}
            <br />
          </>
        );
      }
    }
    setContent(
      <Container className="my-5 text-center">
        <Row>
          <Col className="mb-3">
            <h4>{t("Contact & Networks")}</h4>
          </Col>
          <Col className="text-end">
            <Link className="btn btn-primary" to={buttonTo}>
              {buttonText}
            </Link>
          </Col>
        </Row>
        <hr />
        <Row className="text-start">
          <Container>{labelsData}</Container>
        </Row>
      </Container>
    );
  }, [customer, labels, t]);

  useEffect(() => {
    if (!loaded && customer) {
      setLoaded(true);
      loadContent();
    }
  }, [loaded, customer, loadContent]);

  return (
    <Container className={"my-5 text-center mx-0 mw-100 " + className}>
      {content}
    </Container>
  );
};
