import { appLogout } from "helpers/helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserData } from "helpers/ClientHelper";

export const AdminRedirection = () => {
  const { data: currentUser, remove } = UserData();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      if (currentUser && currentUser.data && currentUser.data.name) {
        if (currentUser.data.role_name === "admin") {
          navigate("/admin/user");
        } else {
          navigate("/admin/product");
        }
        setLoaded(true);
      } else {
        console.log(currentUser);
        remove();
        appLogout().then(() => {
          navigate("/login");
        });
      }
      return;
    }
  }, [loaded, currentUser, navigate, remove]);

  return;
};
