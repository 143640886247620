import React from "react";
import { Link } from "react-router-dom";
import styles from "./FooterShop.module.css";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

const FooterShop = ({ reference }) => {
  const { t } = useTranslation();
  return (
    <footer
      className="bg-secondary text-white text-center py-3 mw-100"
      ref={reference}
    >
      <small>
        <Container fluid>
          <Row className="mx-1">
            <Col>
              <p className="mb-3 w-100 text-start">
                {t(
                  "At WebComprehensive, our purpose is to take your business into the digital world with innovative tools and personalized service. We create tailor-made solutions to help you achieve online success."
                )}
              </p>
            </Col>
            <Col className="text-start">
              <a
                href="https://wa.me/5491136397848"
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.linkButton} d-block`}
                aria-label="WhatsApp"
              >
                <i className="fab fa-whatsapp me-2"></i> +54 9 11 3639 7848
              </a>
              <a
                href="mailto:business@webcomprehensive.com"
                className={`${styles.linkButton} d-block`}
                aria-label="Email"
              >
                <i className="fas fa-envelope me-2"></i>{" "}
                business@webcomprehensive.com
              </a>
              <Link className={`${styles.linkButton} me-3`} to="/policies">
                {t("privacy policy")}
              </Link>
              <Link className={`${styles.linkButton} me-3`} to="/terms">
                {t("terms of use")}
              </Link>
              <div className="h5 my-auto">
                <a
                  href={t("https://www.instagram.com/web__comprehensive/")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.linkButton} me-3`}
                  aria-label="Instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.facebook.com/people/Web-Comprehensive/61558269232454/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.linkButton}`}
                  aria-label="Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </small>
    </footer>
  );
};

export default FooterShop;
