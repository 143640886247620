import Swal from "sweetalert2";
import Cart from "components/client/cart/Cart";
import { useTranslation } from "react-i18next";
import { apiRequestUri } from "helpers/helper";
import { ClientView } from "views/client/Client";
import { useCallback, useEffect, useState } from "react";
import { CartData, UserData } from "helpers/ClientHelper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PaymentShipment } from "components/client/PaymentShipment";
import { useNavigate, useLocation, useParams } from "react-router-dom";

export const OrderView = ({ customerPath, customer }) => {
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const unitPrice = process.env.REACT_APP_UNIT_PRICE
    ? process.env.REACT_APP_UNIT_PRICE
    : "$";
  const [content, setContent] = useState(<LoadingSpinner />);
  const [loaded, setLoaded] = useState(false);
  const { data: cartData, refetch } = CartData(customer.id);
  const { data: user } = UserData();
  const [goto, setGoto] = useState();
  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();
  const { paymentId } = useParams();
  const [shipmentPrice, setShipmentPrice] = useState();

  const handleNotification = useCallback(() => {
    const uri = "payment/" + paymentId + "/notification" + location.search;
    const params = new URLSearchParams(location.search);
    if (
      params.get("collection_status") === "approved" ||
      params.get("collection_status") === "in_process"
    ) {
      const title =
        params.get("collection_status") === "approved"
          ? t("Order payed successfully.")
          : t("Order payment is in process.");
      apiRequestUri(uri, "GET")
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: title,
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then(() => {
            refetch().then(() => {
              setGoto("/" + customerPath);
            });
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: t("An error has occurred."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          }).then((result) => {
            setGoto("/" + customerPath);
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: t("An error has occurred."),
        showConfirmButton: false,
        timer: closeTime,
        timerProgressBar: true,
      }).then((result) => {
        setGoto("/" + customerPath);
      });
    }
  }, [closeTime, customerPath, location.search, paymentId, refetch, t]);

  const loadContent = useCallback(() => {
    const successCallback = (response) => {
      if (response?.payment?.data) {
        if (response.payment.data.checkout_url)
          window.location.href = response.payment.data.checkout_url;
        else if (response?.payment?.payment_provider_id === 1) {
          Swal.fire({
            icon: "success",
            title: t("Order created successfully."),
            showConfirmButton: false,
            timer: closeTime,
            timerProgressBar: true,
          });
          refetch().then(() => {
            setGoto("/" + customerPath);
          });
        } else errorCallback(response);
      } else {
        Swal.fire({
          icon: "success",
          title: t("Order created successfully."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        });
        refetch().then(() => {
          setGoto("/" + customerPath);
        });
      }
    };

    const errorCallback = (response) => {
      console.log(response);
      Swal.fire({
        icon: "error",
        title: t("An error has occurred."),
        showConfirmButton: false,
        timer: closeTime,
        timerProgressBar: true,
      }).then((result) => {
        setContent(<></>);
        setLoaded(false);
      });
    };

    const shipmentPriceCallback = (price) => {
      setShipmentPrice(price);
      setLoaded(false);
    };

    setContent(
      <Row className="mx-md-5">
        <Col md={4} xs={12}>
          <PaymentShipment
            className="my-md-5 my-2"
            customerPath={customerPath}
            customer={customer}
            addresses={user?.data?.addresses}
            successCallback={successCallback}
            errorCallback={errorCallback}
            shipmentPriceCallback={shipmentPriceCallback}
          />
        </Col>
        <Col>
          <Card className="my-md-5">
            <Card.Body>
              <Card.Title>{t("Your shopping cart")}</Card.Title>
              <hr />
              <Cart
                toAddPrice={shipmentPrice}
                className="m-0 p-0 w-100"
                cart={cartData?.data}
                unitPrice={unitPrice}
                customer={customer}
                customerPath={customerPath}
                showButtons={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }, [
    shipmentPrice,
    cartData,
    customer,
    customerPath,
    unitPrice,
    user,
    refetch,
    closeTime,
    t,
  ]);

  useEffect(() => {
    if (!loaded) {
      if (lastUrlSegment === "notification") handleNotification();
      else if (user && user.data) {
        if (
          cartData &&
          cartData.data &&
          cartData.data.cart &&
          !cartData.data.cart.length
        ) {
          setGoto("/" + customerPath);
        } else loadContent();
      } else if (user && !user.data) {
        setGoto("/" + customerPath);
      } else loadContent();
      setLoaded(true);
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
    if (cartData?.data) {
      loadContent();
    }
  }, [
    cartData,
    user,
    loadContent,
    loaded,
    goto,
    navigate,
    customerPath,
    handleNotification,
    lastUrlSegment,
  ]);

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Container fluid className="text-center">
        {content}
      </Container>
    </ClientView>
  );
};
