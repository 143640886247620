export const TableMapping = {
// column: data
  ID: {
    property: "id",
    sortable: true,
    transform: false,
  },
  Name: {
    property: "name",
    sortable: true,
    transform: false,
  },
  Email: {
    property: "email",
    sortable: true,
    transform: false,
  },
  Role: {
    property: "role_name",
    sortable: true,
    transform: false,
  },
  Rating: {
    property: "rating",
    sortable: true,
    transform: "stars",
  },
  Deleted: {
    property: "deleted",
    sortable: true,
    transform: "x",
  },
  Updated: {
    property: "updated_at",
    sortable: true,
    transform: "date",
  },
};
