import styles from "./ViewShop.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const ViewShop = ({ reference }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [goto, setGoto] = useState();

  const handleStartClick = () => {
    setGoto("/register");
  };

  useEffect(() => {
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [goto, navigate]);

  return (
    <div className={`${styles.hero} mw-100`} ref={reference}>
      <Container fluid className="text-center pt-md-5">
        <h1
          className={`display-2 mb-0 fw-bolder`}
          style={{ fontWeight: "1000 !important" }}
        >
          {t("Create your Online Empire")}
          <br />
          {t("E-Commerce Experts at your Service")}
        </h1>
        <h2 className="fs-1 my-5">
          {t("From strategy to launch, we are with you every step")}{" "}
        </h2>

        <Row className="justify-content-center py-md-3 mb-1">
          <Col>
            <Button
              variant=""
              style={{ fontFamily: "Montserrat !important" }}
              className={`${styles.btnPink} mb-4 rounded-pill fs-2 fw-bold`}
              onClick={handleStartClick}
            >
              {t("Start")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ViewShop;
