import Swal from "sweetalert2";
import { Image } from "components/Image";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserData } from "helpers/ClientHelper";
import { ClientView } from "views/client/Client";
import ImageUploader from "components/form/ImageUploader";
import { apiRequestUri, appLogout } from "helpers/helper";
import { LoadingSpinner } from "components/LoadingSpinner";
import { getDocumentNumber } from "helpers/DocumentHelper";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useCallback, useEffect, useMemo, useState } from "react";

const ClientVerify = ({ customer, customerPath }) => {
  const { data: user, refetch, remove } = UserData();
  const navigate = useNavigate();
  const closeTime = process.env.REACT_APP_CLOSE_TIME
    ? process.env.REACT_APP_CLOSE_TIME
    : 1500;
  const { t } = useTranslation();
  const [content, setContent] = useState(<LoadingSpinner />);
  const defaultButton = t("Verify");
  const [btnContent, setBtnContent] = useState(defaultButton);
  const [imageUrl, setImageUrl] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [goto, setGoto] = useState();

  const target = useMemo(
    () => ({
      uri: "user/",
      method: "PUT",
    }),
    []
  );

  const errorCallback = useCallback(() => {
    Swal.fire({
      icon: "error",
      title: t("An error has occurred."),
      showConfirmButton: false,
      timer: closeTime,
      timerProgressBar: true,
    }).then(() => {
      setImageUrl(false);
      setBtnContent(defaultButton);
    });
  }, [closeTime, defaultButton, t]);

  const successCallback = useCallback(() => {
    refetch()
      .then(() => {
        Swal.fire({
          icon: "success",
          title: t("Successfully verified."),
          showConfirmButton: false,
          timer: closeTime,
          timerProgressBar: true,
        }).then(() => {
          setGoto("/" + customerPath + "/profile");
        });
      })
      .catch(() => {
        remove();
        appLogout().then(() => {
          setGoto("/login");
        });
      });
  }, [closeTime, customerPath, refetch, remove, t]);

  const recognizeText = useCallback(async () => {
    if (imageUrl) {
      getDocumentNumber(imageUrl).then((result) => {
        if (result) {
          const documentNumber = Number(result.replace(/\./g, ""));
          if (documentNumber && documentNumber.toString().length === 8) {
            const formState = {
              validated: 1,
              validation_data: documentNumber,
            };
            apiRequestUri(target.uri, target.method, formState, false)
              .then((response) => {
                successCallback();
              })
              .catch(() => {
                errorCallback();
              });
          } else {
            errorCallback();
          }
        } else {
          errorCallback();
        }
      });
    }
  }, [imageUrl, successCallback, errorCallback, target]);

  const selectedImageCallback = (url) => {
    setImageUrl(url);
    setLoaded(false);
  };

  const loadContent = useCallback(() => {
    const handleClick = () => {
      setBtnContent(<LoadingSpinner />);
      recognizeText();
      setLoaded(false);
    };

    setContent(
      <Row className="justify-content-center">
        <Col md="6">
          <div className="card">
            <div className="card-body m-3 text-center">
              <h4 className="text-center m-3 mb-1">{t("Verification")}</h4>
              <p className="mb-1">
                {t("Upload your national identity document.")}
              </p>
              <Row>
                <Col>
                  <ImageUploader
                    className="mt-3"
                    selectedImageCallback={selectedImageCallback}
                  />
                </Col>
                {imageUrl && (
                  <Col md={2}>
                    <Button onClick={handleClick} className="mt-3">
                      {btnContent}
                    </Button>
                  </Col>
                )}
              </Row>
              {imageUrl && <Image src={imageUrl} alt="Selected" width="100%" />}
            </div>
          </div>
        </Col>
      </Row>
    );
  }, [imageUrl, btnContent, recognizeText, t]);

  useEffect(() => {
    if (!loaded && user && user.data) {
      setLoaded(true);
      loadContent();
      target.uri = "user/" + user.data.id;
    }
    if (goto) {
      navigate(goto);
      setGoto(null);
    }
  }, [loaded, user, imageUrl, btnContent, loadContent, target, goto, navigate]);

  return (
    <ClientView customerPath={customerPath} customer={customer}>
      <Container className="text-center my-5">{content}</Container>
    </ClientView>
  );
};

export default ClientVerify;
